import React, { useState, useEffect, useRef } from "react";
import { ItemCollection } from "../dataProvider/itemCollection";
import ItemRow from "./ItemRow";

export default function ItemTable({ itemCollection }) {
  if (!itemCollection) {
    return <></>;
  }

  return (
    <>
      {itemCollection.count}/{itemCollection.totalCount}:
      <br />
      <div>
        {itemCollection.itemList.map((i) => (
          <div key={i.uuid}>
            <ItemRow item={i} />
          </div>
        ))}
      </div>
    </>
  );
}
