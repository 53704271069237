import React, { useEffect, useState } from "react";
import { AllDataDashboard } from "./AllDataType";
import {
  MapContainer,
  TileLayer,
  Polygon,
  Marker,
  Popup,
  Tooltip,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import BatsHeatmap from "./BatsHeatmap";
import BatsAverage from "./BatsAverage";
import "./batsdashboards.css";
import Dropdownlist from "../../components/Dropdownlist";
import Checkbox from "../../components/Checkbox";
import { getTilelayerUrl } from "../../mapHelpers";

const BatsHome: React.FC = () => {
  const [allData, setAllData] = useState<AllDataDashboard | null>(null);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [selectedSpecies, setSelectedSpecies] = useState<string | null>(null);
  const [showMarkers, setShowMarkers] = useState(true);

  function getMarkers(allData: AllDataDashboard) {
    if (!allData.Markers[0]) return [];

    const ret = Object.entries(allData.Markers[0]).map(([name, position]) => ({
      name,
      position: position as [number, number],
    }));
    return ret;
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/dashboards/AllData.json");
      const data: AllDataDashboard = await response.json();
      data.Markers = data.Markers.filter((marker) => marker !== null);
      setAllData(data);
    };
    fetchData();
  }, []);

  if (!allData) return <div>Loading...</div>;

  function handleSelect(key) {
    console.log(key);
    window.location.href = `/BatsEspeces?species=${encodeURIComponent(key)}`;
  }

  function getAllSpecies() {
    const species = Object.keys(
      allData.AllDataDashboardPeriodeEspeceEtAccueil[0]
    );
    return species;
  }
  function getAllSpeciesAsKeyValues() {
    const species = getAllSpecies();
    return species.map((specie) => ({ key: specie, value: specie }));
  }


  const customIcon = L.icon({
    iconUrl: "marker-icon.png",
    iconSize: [28, 28],
    iconAnchor: [0, 16],
    popupAnchor: [0, -32],
  });

  return (
    <div className="mainpage">
      <div
        style={{
          width: "100%",
          textAlign: "right",
          padding: "10px",
          paddingRight: "100px",
        }}
      >
        <Dropdownlist
          title="Espèces"
          onSelect={handleSelect}
          children={getAllSpeciesAsKeyValues()}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gridGap: "20px",
          paddingTop: "20px",
          margin: "0 auto",
          maxWidth: "1800px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gridGap: "20px",
            width: "100%",
          }}
        >
          <div className="batsdashboards-card">
            <BatsHeatmap allData={allData} />
          </div>
          <div className="batsdashboards-card">
            <BatsAverage allData={allData} />
          </div>
        </div>
        <div>
          <MapContainer
            center={[44.716765, -0.379938]}
            zoom={16}
            style={{ height: "1010px", width: "700px", borderRadius: "10px" }}
          >
            <TileLayer url={getTilelayerUrl("esri")} />
            <Polygon
              positions={allData.ContourLaPeyruche}
              pathOptions={{
                fillColor: "none",
                color: "white",
                opacity: 0.5,
              }}
            />
            {getMarkers(allData).length > 0 &&
              getMarkers(allData).map((marker) => (
                <Marker key={marker.name} position={marker.position} icon={customIcon}>
                  {!showMarkers && <Popup>{marker.name}</Popup>}
                  {showMarkers && (
                    <Tooltip permanent direction="top" offset={[15, -10]}>
                      {marker.name}
                    </Tooltip>
                  )}
                </Marker>
              ))}
          </MapContainer>
          <div>
            <ul className="switch">
              <Checkbox
                label="Afficher les libellés"
                checked={showMarkers}
                onChange={(name, checked) => setShowMarkers(checked)}
                name="showMarkers"
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatsHome;
