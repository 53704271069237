import React, { useEffect } from "react";
import Plotly from "plotly.js-dist";
import { AllDataDashboard } from "./AllDataType";

const BatsAverage = ({ allData }: { allData: AllDataDashboard }) => {
  const { AllDataDashboardPeriodeEspeceEtAccueil, ColorscaleInventaires } =
    allData;

  function getAllSpecies() {
    const species = Object.keys(AllDataDashboardPeriodeEspeceEtAccueil[0]);
    return species;
  }
  function getLieuxForSpecie(specie: string) {
    const lieux = [];
    if (
      AllDataDashboardPeriodeEspeceEtAccueil[0][specie] &&
      AllDataDashboardPeriodeEspeceEtAccueil[0][specie].Inventaire &&
      AllDataDashboardPeriodeEspeceEtAccueil[0][specie].Inventaire.Lieu
    ) {
      lieux.push(
        AllDataDashboardPeriodeEspeceEtAccueil[0][specie].Inventaire.Lieu
      );
    }
    return lieux[0];
  }

  useEffect(() => {
    const createBarChart = () => {
      const getValuesI = () => {
        var zValuesI = [];
        getAllSpecies().forEach((specie) => {
          let speciesData = [];

          const lieux = getLieuxForSpecie(specie);
          if (lieux) {
            for (const lieu in lieux) {
              if (lieux.hasOwnProperty(lieu)) {
                let total = 0;
                let compteurJours = 0;
                const dates = lieux[lieu];

                for (const date in dates) {
                  if (dates.hasOwnProperty(date)) {
                    total += dates[date];
                    compteurJours += 1;
                  }
                }

                if (compteurJours > 0) {
                  const moyenne = total / compteurJours;
                  const moyenneArrondie = Math.round(moyenne);
                  speciesData.push(moyenneArrondie);
                }
              }
            }
          }

          if (speciesData.length > 0) {
            zValuesI.push(...speciesData);
          }
        });
        return zValuesI;
      };

      const Couleur =
        ColorscaleInventaires[ColorscaleInventaires.length - 1][1];

      const DataInv = [
        {
          x: getAllSpecies(),
          y: getValuesI(),
          type: "bar",
          marker: { color: "#234C3D" },
        },
      ];

      const divInventairesAccueilId = "DivInventairesAccueil";
      const divInventairesAccueil = document.getElementById(
        divInventairesAccueilId
      );
      if (divInventairesAccueil) {
        divInventairesAccueil.innerHTML = "";
      }

      const layoutInv = {
        xaxis: {
          title: {
            text: "<b>Espèce</b>",
            font: { size: 16 },
          },
          automargin: true,
        },
        yaxis: {
          title: {
            text: "<b>Moyenne</b>",
            font: { size: 16 },
          },
        },
        margin: { t: 30 },
        font: {
          size: 12,
        },
      };

      Plotly.newPlot(divInventairesAccueilId, DataInv, layoutInv, {
        displayModeBar: false,
      });
    };

    createBarChart();
  }, [AllDataDashboardPeriodeEspeceEtAccueil, ColorscaleInventaires]);

  return (
    <>
      <div className="batsdashboards-card-title">
        Moyenne des recensements hivernaux
      </div>
      <div id="DivInventairesAccueil"></div>
    </>
  );
};

export default BatsAverage;
