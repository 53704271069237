import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Papa from "papaparse";

// TODO IMPORTER IMPORTLANGOIRAN TROUVE sur le bureau et choppé ici : https://openobs.mnhn.fr/

export default function ImportINPN() {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const processFolderItemCollectionMode = () => {
    if (selectedFile) {
      processCSVItemCollectionMode(selectedFile);
    } else {
      console.log("No file selected");
    }
  };

  function getItem(line) {
    console.log(line);
    let startdate = line.dateObservation;
    let enddate = startdate;

    let title = "";
    if (line.nomVernaculaire) {
      title = line.nomVernaculaire.split(", ")[0].trim();
    } else {
      return null;
    }

    const e1cItem = {
      wkt: `POINT(${line.longitude} ${line.latitude})`,
      medias: [],
      startdate: startdate,
      enddate: enddate,
      title: title,
      text: `<a href="https://inpn.mnhn.fr/espece/cd_nom/${line.cdRef}" target="_blank">Voir sur INPN</a>`,
      datatype: "inpn",
    };
    console.log(e1cItem);

    return e1cItem;
  }

  function getItemCollection(lines) {
    const data = lines
      .filter((line) => line !== null)
      .map((line) => getItem(line))
      .filter((item) => item !== null); // This will remove any null items resulting from getItem

    console.log(data);

    return data;
  }

  function launchDownloadJson(json) {
    // Convert the featureCollection to a JSON string
    const jsonStr = JSON.stringify(json, null, 2);

    // Create a Blob from the JSON String
    const blob = new Blob([jsonStr], { type: "application/json" });

    // Generate a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary link and trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.json"; // Name of the file to download
    document.body.appendChild(a); // Append the link to the document
    a.click(); // Trigger the download

    // Clean up by revoking the Blob URL and removing the temporary link
    URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  const processCSVItemCollectionMode = (csvFile) => {
    let itemCol = null;

    Papa.parse(csvFile, {
      complete: (result) => {
        console.log(result);
        itemCol = getItemCollection(result.data);
        launchDownloadJson(itemCol);
        console.log(
          `Processed ${result.data.length} / ${result.data.length} items`,
        );
      },
      header: true,
    });
  };

  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <input type="file" onChange={handleFileChange} />
      <button onClick={processFolderItemCollectionMode}>
        Download itemCollection json
      </button>
    </div>
  );
}
