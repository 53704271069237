import React, { useState, useEffect } from "react";

const Checkbox = ({ label, checked, onChange, name }) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const toggleCheckbox = function () {
    let newState = !isChecked;
    setIsChecked(newState);
    if (onChange) {
      onChange(name, newState);
    }
  };

  return (
    <li onClick={toggleCheckbox} className={isChecked ? "active" : ""}>
      <span></span>
      {label}
    </li>
  );
};

export default Checkbox;
