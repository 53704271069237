class ItemCollection {
  #ItemList = [];
  #totalCount = 0;
  #qs = "";

  constructor(itemList, totalCount, qs) {
    this.#ItemList = Array.isArray(itemList) ? itemList : [];
    this.#totalCount = parseInt(totalCount);
    this.#qs = qs ? qs : "";
  }

  get itemList() {
    return this.#ItemList;
  }

  get totalCount() {
    return this.#totalCount;
  }

  get qs() {
    return this.#qs;
  }
  set qs(qs) {
    this.#qs = qs;
  }

  get count() {
    return this.#ItemList.length;
  }

  addItems(items) {
    this.#ItemList.push(...items);
  }

  get datesDistinct() {
    const dates = this.#ItemList.map((item) => item.getDate());
    const uniqueDates = [...new Set(dates)];
    return uniqueDates;
  }

  getItemsForDate(date) {
    const ret = this.#ItemList.filter((item) => item.getDate() === date);
    return ret;
  }

  getItemsForPosition(latitude, longitude) {
    const ret = this.#ItemList.filter(
      (item) => item.latitude === latitude && item.longitude === longitude,
    );
    return ret;
  }

  getItemCollectionForPosition(latitude, longitude) {
    const items = this.getItemsForPosition(latitude, longitude);
    const ret = new ItemCollection(items, items.length);
    return ret;
  }

  doesContainsEvent(e) {
    return this.#ItemList.some(
      (selectedEvent) => selectedEvent.uuid === e.uuid,
    );
  }

  hasPointItem() {
    return this.#ItemList.some((item) => item.isPoint());
  }

  toJSON() {
    let ret = {}
    ret.count = this.count;
    ret.items = this.itemList;
    return ret;
  }

  toE1cGeojson() {
    let ret = {}
    ret.type = 'FeatureCollection'
    ret.features = this.itemList.map(i => i.toE1cGeojson());
    return ret;
  }

  toStrictGeojson() {
    let ret = {}
    ret.type = 'FeatureCollection'
    ret.features = this.itemList.map(i => i.toStrictGeojson());
    return ret;
  }

  toStrictFlatGeojson() {
    let ret = {}
    ret.type = 'FeatureCollection'
    ret.features = this.itemList.map(i => i.toStrictFlatGeojson());
    return ret;
  }

  toCSV() {
    if (this.itemList.length === 0) return '';

    // Step 1: Collect all keys
    const allKeys = new Set<string>();
    this.itemList.forEach(item => {
      const flatItem = item.toKvpJsonWithCentroid();
      Object.keys(flatItem).forEach(key => allKeys.add(key));
    });

    const allKeysArray = Array.from(allKeys);

    // Step 2: Generate CSV header
    const headers = allKeysArray.join(',');

    // Step 3: Generate CSV rows
    const rows = this.itemList.map(item => {
      const flat = item.toKvpJsonWithCentroid();

      const completeFlat = allKeysArray.map(key =>
        flat[key] !== undefined ?
          (typeof flat[key] === 'string' && flat[key].includes(',') ? `"${flat[key]}"` : flat[key])
          : ''
      ).join(',');

      return completeFlat;
    }).join('\n');

    return `${headers}\n${rows}`;
  }

}

export { ItemCollection };
