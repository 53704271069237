import React from 'react';

const VideoPlayer = ({ url }) => {
  return (
    <div className="media-wrap">
      <video
        src={url}
        autoPlay
        controls
        onError={(e) => console.error("Video error:", e)}
      />
    </div>
  );
};

export default VideoPlayer;
