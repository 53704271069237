import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { areGeojsonEqual, getCentroidOfGeoJSON } from "../geoHelper";
import * as h from "../mapHelpers";
import { getTilelayerUrl } from "../mapHelpers";

import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster";
import {
  getPropertyBoundary,
  getPropertyCoordinates,
  getGeologyGeoJsonUrl,
  getNatureSolsGeoJsonUrl,
} from "../ConfigProvider";

const Map3 = ({
  markers,
  options,
  coordBoxUpdated,
  selectMarkerCallBack,
  selectedItemsCollection,
  unselectCallback,
  playCallBack,
}) => {
  const mapRef = useRef(null);
  const [propertyBoundary, setPropertyBoundary] = useState(null);
  const [geologyLayer, setGeologyLayer] = useState(null);
  const [natureSolsLayer, setNatureSolsLayer] = useState(null);

  const [markerClusterGroup, setMarkerClusterGroup] = useState(null);
  const [geoShapes, setGeoShapes] = useState([]);

  const defaultZoom = 16;
  const [zoomLevel, setZoomLevel] = useState(defaultZoom);
  const zoomLevelRef = useRef(zoomLevel);

  useEffect(() => {
    zoomLevelRef.current = zoomLevel;
  }, [zoomLevel]);

  const updateBoundingBox = () => {
    if (!mapRef.current) return;

    const bounds = mapRef.current.getBounds();
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();

    const box = {
      north: northEast.lat,
      east: northEast.lng,
      south: southWest.lat,
      west: southWest.lng,
    };

    coordBoxUpdated(box);
  };

  const ignLayer = L.tileLayer(
    getTilelayerUrl("esri"),
    {
      maxZoom: 30,
    },
  );

  // Create the OpenStreetMap layer
  const osmLayer = L.tileLayer(
    getTilelayerUrl("openstreetmap"),
    {
      maxZoom: 19,
    },
  );

  async function loadNatureSols() {
    const url = await getNatureSolsGeoJsonUrl();
    if (!url) {
      return;
    }
    const response = await fetch(url);
    const data = await response.json();

    const layer = L.geoJSON(data, {
      style: function (feature) {
        const color = feature.properties.color;
        return {
          color: color,
          fillColor: color,
          weight: 2,
          opacity: 0.8,
          fillOpacity: 0.2,
        };
      },
      onEachFeature: function (feature, layer) {
        // Check if the feature has properties to display
        if (feature.properties && Object.keys(feature.properties).length > 0) {
          let popupContent = `<p><h2 style='font-size:16px'>${feature.properties.name}</h2></p>`;
          popupContent += `(${feature.properties.Confidence} % de confiance)`;
          layer.bindPopup(popupContent);
        }
      },
    });
    setNatureSolsLayer(layer);
  }

  useEffect(() => {
    function initMap() {
      mapRef.current = L.map("map", { maxZoom: 19 }).setView(
        [44.7163158285053, -0.3834068326026641],
        zoomLevel,
      ); // dummy data, waiting for the data to be fetched
      if (options.mapMode === "satellite") {
        ignLayer.addTo(mapRef.current);
      } else {
        osmLayer.addTo(mapRef.current);
      }

      loadNatureSols();

      getGeologyGeoJsonUrl()
        .then((geojsonUrl) => fetch(geojsonUrl))
        .then((response) => response.json())
        .then((data) => {
          const geoJsonLayer = L.geoJSON(data, {
            style: function (feature) {
              // Generate a random color
              const color =
                "#" + Math.floor(Math.random() * 16777215).toString(16);
              return {
                color: color, // Use the random color for the feature's outline
                fillColor: color, // Also use it for the fill color
                weight: 2,
                opacity: 0.8,
                fillOpacity: 0.2,
              };
            },
            onEachFeature: function (feature, layer) {
              // Check if the feature has properties to display
              if (
                feature.properties &&
                Object.keys(feature.properties).length > 0
              ) {
                let popupContent =
                  "<p><h2 style='font-size:16px'>Informations géologiques:</h2></p>";
                popupContent += feature.properties["DESCR"];
                layer.bindPopup(popupContent);
              }
            },
          });
          setGeologyLayer(geoJsonLayer);
        })
        .catch((error) =>
          console.error("Error loading the GeoJSON data:", error),
        );
    }

    async function fetchData() {
      const coordinates = await getPropertyCoordinates();
      let zoom = defaultZoom;
      if (coordinates.zoomLevel) {
        zoom = coordinates.zoomLevel;
        setZoomLevel(zoom);
      }
      mapRef.current.setView(
        [coordinates.latitude, coordinates.longitude],
        zoom,
      );

      try {
        const propertyBoundaries = await getPropertyBoundary();
        let propertyBoundariesGeoJSON = JSON.parse(propertyBoundaries);
        console.log("propertyBoundariesGeoJSON: ", propertyBoundariesGeoJSON);

        const propertyBoundaryLayer = L.geoJSON(propertyBoundariesGeoJSON, {
          style: {
            color: "#A3B75E",
            fillColor: "lightblue",
            fillOpacity: 0,
            interactive: false,
            weight: 5,
          },
        });
        setPropertyBoundary(propertyBoundaryLayer);
      } catch (error) {
        console.error("Error fetching property boundaries: ", error);
      }

      // Add the home button control after fetching the coordinates
      const homeControl = L.control({ position: "topright" });
      homeControl.onAdd = function () {
        const div = L.DomUtil.create("div", "home-button");
        div.style.padding = "3px 5px";
        div.style.borderRadius = "8px";
        div.style.cursor = "pointer";
        div.innerHTML = `<button style=''><img src="home-icon.png" style="width: 20px; height: 20px;"></button>`;

        L.DomEvent.on(div, "click", function () {
          console.log("zoomLevelRef.current: ", zoomLevelRef.current);
          mapRef.current.setView(
            [coordinates.latitude, coordinates.longitude],
            zoomLevelRef.current,
          );
        });
        return div;
      };
      homeControl.addTo(mapRef.current);
    }

    if (!mapRef.current) {
      fetchData().then(initMap());
    }
  }, []); // Dapendencies array

  useEffect(() => {
    const map = mapRef.current;

    if (!map) return; // Ensure the map reference is available

    const updateListeners = () => {
      map.on("moveend", updateBoundingBox);
    };

    updateListeners();

    if (!markerClusterGroup) {
      // Initialize the marker cluster group if it doesn't exist
      const clusterGroup = L.markerClusterGroup({
        maxClusterRadius: 50,
        disableClusteringAtZoom: 18,
        spiderfyOnMaxZoom: false, // Disable spiderfying
        zoomToBoundsOnClick: true,
        iconCreateFunction: function (cluster) {
          let size = 40 + cluster.getChildCount() * 1.2;
          if (size > 80) size = 80;

          return L.divIcon({
            html: "<b>" + cluster.getChildCount() + "</b>",
            className: "my-custom-cluster-icon", // Add your custom class
            iconSize: new L.Point(size, size), // Dynamically change size
          });
        },
      });
      setMarkerClusterGroup(clusterGroup);
      clusterGroup.addTo(mapRef.current);
    } else {
      markerClusterGroup.clearLayers();
    }

    markers.forEach((marker) => {
      if (marker.ispoint) {
        const icon = L.icon({
          iconUrl: h.getMapIconFromitemType(marker.type),
          iconSize: [32, 32],
          iconAnchor: [12, 12],
          popupAnchor: [1, -12],
          shadowSize: [25, 25],
        });

        const markerObj = L.marker([marker.latitude, marker.longitude], {
          icon,
        });
        markerObj.on("click", function (e) {
          selectMarkerCallBack({
            latLng: {
              lat: marker.latitude,
              lng: marker.longitude,
            },
            ispoint: true,
          });
        });

        if (markerClusterGroup) {
          markerClusterGroup.addLayer(markerObj);
        }
      } else {
        //not a point
        const randomColor = h.getRandomColorFromGeojson(marker.geojson);
        const markerObj = L.geoJSON(marker.geojson, {
          style: function () {
            return {
              color: randomColor,
              weight: 2,
              fillColor: randomColor,
              fillOpacity: 0.1,
            };
          },
        }).addTo(mapRef.current);

        let geos = geoShapes;
        geos.push(markerObj);
        setGeoShapes(geos);

        markerObj.on("click", function (e) {
          /*
          console.log("polygon click")
          console.log(e)
          console.log("lat:", marker.latitude, "lng:", marker.longitude)
          */
          selectMarkerCallBack({
            latLng: e.latlng,
            ispoint: false,
          });
        });
      }
    });
    if (selectedItemsCollection && selectedItemsCollection.itemList[0]) {
      const i = selectedItemsCollection.itemList[0];
      highlightMarker(i.geojson);
    }

    // Cleanup function
    return () => {
      map.off("moveend", updateBoundingBox);
      if (markerClusterGroup) {
        markerClusterGroup.clearLayers(); // Clear markers from the cluster group
      }

      geoShapes.forEach((m) => {
        m.remove();
      });
      setGeoShapes([]);
    };
  }, [markers, mapRef, markerClusterGroup]);

  useEffect(() => {
    if (propertyBoundary) {
      if (options.displayPropertyBoundary) {
        if (!mapRef.current.hasLayer(propertyBoundary)) {
          propertyBoundary.addTo(mapRef.current);
        }
      } else {
        mapRef.current.removeLayer(propertyBoundary);
      }
    }
  }, [options.displayPropertyBoundary, propertyBoundary]);

  useEffect(() => {
    if (options.displayGeology && geologyLayer && mapRef.current) {
      // Check if geologyLayer is not already added to the map
      if (!mapRef.current.hasLayer(geologyLayer)) {
        geologyLayer.addTo(mapRef.current);
      }
    } else if (!options.displayGeology && geologyLayer && mapRef.current) {
      // If displayGeology is false and geologyLayer exists, remove it from the map
      if (mapRef.current.hasLayer(geologyLayer)) {
        mapRef.current.removeLayer(geologyLayer);
      }
    }
  }, [options.displayGeology]);

  useEffect(() => {
    if (options.displayNatureSols && natureSolsLayer && mapRef.current) {
      // Check if geologyLayer is not already added to the map
      if (!mapRef.current.hasLayer(natureSolsLayer)) {
        natureSolsLayer.addTo(mapRef.current);
      }
    } else if (
      !options.displayNatureSols &&
      natureSolsLayer &&
      mapRef.current
    ) {
      // If displayGeology is false and geologyLayer exists, remove it from the map
      if (mapRef.current.hasLayer(natureSolsLayer)) {
        mapRef.current.removeLayer(natureSolsLayer);
      }
    }
  }, [options.displayNatureSols]);

  useEffect(() => {
    if (options.mapMode === "satellite") {
      if (mapRef.current.hasLayer(osmLayer)) {
        mapRef.current.removeLayer(osmLayer);
      }
      if (!mapRef.current.hasLayer(ignLayer)) {
        ignLayer.addTo(mapRef.current);
      }
    } else {
      if (mapRef.current.hasLayer(ignLayer)) {
        mapRef.current.removeLayer(ignLayer);
      }
      if (!mapRef.current.hasLayer(osmLayer)) {
        osmLayer.addTo(mapRef.current);
      }
    }
  }, [options.mapMode]);

  function highlightMarker(geojson) {
    geoShapes.forEach((s) => {
      const geos = s.toGeoJSON().features[0].geometry;

      const highlightStyle = {
        color: "#ff7800", // Highlighted border color
        weight: 5, // Increased border thickness
        fillColor: "#ff7800", // Highlighted fill color
        fillOpacity: 0.5, // Increased fill opacity
      };
      const randomColor = h.getRandomColorFromGeojson(s.toGeoJSON());
      const normalStyle = {
        color: randomColor,
        weight: 2, // Thickness of the border
        fillColor: randomColor,
        fillOpacity: 0.1, // Opacity of the fill
      };
      if (areGeojsonEqual(geojson, geos)) {
        s.setStyle(highlightStyle);
      } else {
        s.setStyle(normalStyle);
      }
    });
  }

  useEffect(() => {
    mapRef.current.closePopup();

    if (
      !selectedItemsCollection ||
      selectedItemsCollection.totalCount === 0 ||
      !selectedItemsCollection.itemList[0]
    ) {
      return;
    }

    const firstItem = selectedItemsCollection.itemList[0];

    highlightMarker(firstItem.geojson);

    const centroid = getCentroidOfGeoJSON(firstItem.geojson);
    const lat = centroid[1];
    const lng = centroid[0];
    let popupContent = "";

    if (selectedItemsCollection.totalCount === 1) {
      const item = selectedItemsCollection.itemList[0];

      popupContent = `<h3>${item.title}</h3>
                <p>Le ${item.getDate()} à ${item.getTime()} </p>
                <div style="text-align:center;width:100%;">
                    <a id="btnPlayEvent_${item.uuid}" class="btn btn-icon bg-pale-green" style="width:33px;height:35px;display:inline-block;">
                        <i class="ei ei-play"></i>
                    </a>
                </div>`;
    } else {
      popupContent = `<h3>${selectedItemsCollection.totalCount} éléments</h3>`;
    }

    setTimeout(() => {
      L.popup()
        .setLatLng([lat, lng])
        .setContent(popupContent)
        .openOn(mapRef.current);
    }, 0); // Adjust the delay as needed

    const onPopupClick = (event) => {
      // Use event delegation to handle the click on the dynamically added button
      if (event.target.closest(`#btnPlayEvent_${firstItem.uuid}`)) {
        playCallBack();
      }
    };

    // Add click listener to the map container once the popup is open
    mapRef.current.once("popupopen", () => {
      setTimeout(() => {
        mapRef.current.getContainer().addEventListener("click", onPopupClick);
      }, 0);
    });

    const onPopupClose = () => {
      unselectCallback();
      mapRef.current.getContainer().removeEventListener("click", onPopupClick);
    };

    mapRef.current.on("popupclose", onPopupClose);

    return () => {
      mapRef.current.off("popupclose", onPopupClose);
      mapRef.current.getContainer().removeEventListener("click", onPopupClick);
    };
  }, [selectedItemsCollection]);

  return (
    <div className="mapcontainer" style={{ display: "flex", height: "100%" }}>
      <div id="map" style={{ flex: "1" }}></div>
    </div>
  );
};

export default Map3;
