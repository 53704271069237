import React, { useState, useEffect, useRef } from "react";

export default function ListItem({ item }) {

  return <>
    <div className="event-name" style={{ padding: '5px', whiteSpace: 'normal' }}>
      {item.title}
      <div style={{ color: 'grey' }}>
        {item.text}
      </div >
    </div >
  </>

}
