import React, { useState, useEffect, useRef } from "react";
import Checkbox from "../components/Checkbox";

const DataTypeFilter = ({ options, filterChangedCallBack }) => {
  const [checkAll, setCheckAll] = useState(true); // New state to track check/uncheck all

  useEffect(() => {}, []);

  const toggleCheckAll = () => {
    const optionsCopy = { ...options };
    // Toggle the checkAll state and update all options based on the new checkAll state
    setCheckAll(!checkAll);
    Object.keys(optionsCopy).forEach((key) => {
      optionsCopy[key] = !checkAll;
    });

    filterChangedCallBack(optionsCopy);
  };

  const newCheckboxChanged = (name, newValue) => {
    const optionsCopy = { ...options };
    optionsCopy[name] = newValue;
    filterChangedCallBack(optionsCopy);
  };

  return (
    <div>
      <ul className="switch">
        <a className="btn btn-primary" onClick={toggleCheckAll}>
          {checkAll ? "Tout décocher" : "Tout cocher"}
        </a>

        <Checkbox
          name="displayCamera"
          label="Camera"
          checked={options.displayCamera}
          onChange={newCheckboxChanged}
        />

        <Checkbox
          name="displaySound"
          label="Sons"
          checked={options.displaySound}
          onChange={newCheckboxChanged}
        />
        <Checkbox
          name="displaySol"
          label="Analyses de sol"
          checked={options.displaySol}
          onChange={newCheckboxChanged}
        />
        <Checkbox
          name="displayUltrason"
          label="Ultrasons"
          checked={options.displayUltrason}
          onChange={newCheckboxChanged}
        />
        <Checkbox
          name="displayPratique"
          label="Pratiques"
          checked={options.displayPratique}
          onChange={newCheckboxChanged}
        />

        <Checkbox
          name="displayGbif"
          label="GBIF"
          checked={options.displayGbif}
          onChange={newCheckboxChanged}
        />
        <Checkbox
          name="displayInpn"
          label="INPN"
          checked={options.displayInpn}
          onChange={newCheckboxChanged}
        />
        <Checkbox
          name="displayParcellaire"
          label="Parcellaire"
          checked={options.displayParcellaire}
          onChange={newCheckboxChanged}
        />
      </ul>
    </div>
  );
};

export default DataTypeFilter;
