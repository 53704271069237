import { nanoid } from "nanoid";

class Sensor {
  id: string;
  name: string;
  type: string;
  lat: number;
  lng: number;
  ref: string;
  startdate: string;
  enddate: string;
  isnew: boolean;
  comment: string;

  constructor(obj) {
    this.name = this.mustGetField(obj, "name");
    this.type = this.mustGetField(obj, "type");
    this.lat = this.mustGetField(obj, "lat");
    this.lng = this.mustGetField(obj, "lng");
    this.ref = this.mustGetField(obj, "ref");
    this.startdate = this.mustGetField(obj, "startdate");
    this.enddate = this.mustGetField(obj, "enddate");
    this.comment = this.mustGetField(obj, "comment");

    if (!obj.id || obj.id == "" || obj.id == 0) {
      this.id = nanoid(8);
    } else {
      this.id = obj.id;
    }
  }

  private mustGetField(obj, field: string) {
    if (!(field in obj)) {
      return "";
    }
    return obj[field];
  }

  isArchived() {
    if (this.isFuture()) {
      return false;
    }
    if (!this.hasEndDate()) {
      return false;
    }

    const endDate = this.convertStringToDate(this.enddate);
    return endDate < new Date();
  }

  isPhysical() {
    if(this.type==="analyse_eau" || this.type==="analyse_sol"){
      return false;
    }
    return true;
  }

  isActif() {
    if (this.isFuture() || this.isArchived()) {
      return false;
    }
    return true;
  }

  isFuture() {
    if (!this.hasStartDate()) {
      return true;
    }
    const startDate = this.convertStringToDate(this.startdate);
    const ret = startDate > new Date();
    return ret;
  }

  convertStringToDate(dateString: string): Date {
    const [day, month, year] = dateString.split("/");
    return new Date(`${month}/${day}/${year}`);
  }
  hasEndDate() {
    if (!this.enddate || this.enddate == null || this.enddate == "") {
      return false;
    }
    return true;
  }

  hasStartDate() {
    if (!this.startdate || this.startdate == null || this.startdate == "") {
      return false;
    }
    return true;
  }
}

export default Sensor;
