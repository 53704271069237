import React from "react";
import useAdmin from "../hooks/useAdmin";

const IsAdminPlaceHolder = ({ children }) => {
  const { isAdmin } = useAdmin();

  return <>{isAdmin && <>{children}</>}</>;
};

export default IsAdminPlaceHolder;
