import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Papa from 'papaparse';
import { postOccurrence } from '../OccurrencesApi';
import { postFile } from '../FilesApi.js';

export default function ImportBiophonia() {
    const [selectedFolder, setSelectedFolder] = useState(null);

    const handleFolderSelect = async () => {
        if (!window.showDirectoryPicker) {
            alert('Your browser does not support this feature');
            return;
        }

        try {
            const directoryHandle = await window.showDirectoryPicker();
            setSelectedFolder(directoryHandle);
        } catch (error) {
            console.error(error);
            alert('Error accessing folder');
        }
    };

    const putOccurence = async (e, audioFilePath, audioFileData) => {
        const myUUID = uuidv4();

        // First, upload the audio file
        await postFile(myUUID + '.wav', audioFileData);

        // Then, send the occurrence data
        try {
            let dataToPost = {
                uuid: myUUID,
                epoch: parseInt(e.epoch, 10),
                latitude: parseFloat(e.latitude),
                longitude: parseFloat(e.longitude),
                type: 'sound',
                title: e.espèce,
                text: '',
                thumbnailUrl : e.thumbnailUrl
            };

            await postOccurrence(dataToPost);

            console.log('PUT Succeeded: ');
        } catch (error) {
            console.log('PUT call failed: ', error);
        }
    };

    const processFolder = async () => {
        if (!selectedFolder) {
            alert('Please select a folder first!');
            return;
        }

        try {
            // Process CSV file
            const csvFileHandle = await selectedFolder.getFileHandle('tableau_observations_with latlong and epoch_sans_doublon_with_thumbnails.csv');
            const csvFile = await csvFileHandle.getFile();
            processCSV(csvFile);
            console.log('done!')
        } catch (error) {
            console.error(error);
            alert('CSV file not found in the selected folder');
        }
    };

    const processCSV = async (csvFile) => {
        console.log(csvFile)
        Papa.parse(csvFile, {
            complete: async (result) => {
                for (const line of result.data) {
                    console.log(line)
                    // Split the path and sanitize each part
                    const fileName = line.file
                    const filePath = line.folder + '/audio/' + fileName
                    const pathParts = filePath.split('/');
                    console.log(pathParts)
                    const sanitizedPathParts = pathParts.map(part => part.trim().replace(/[<>:"/\\|?*]+/g, '')); // Replace forbidden characters
    
                    try {
                        let currentHandle = selectedFolder;
    
                        // Access each part of the path step by step
                        for (const part of sanitizedPathParts) {
                            // Check if it's a file or a directory
                            if (part.endsWith('.wav')) {
                                // It's a file, get the file handle
                                currentHandle = await currentHandle.getFileHandle(part);
                            } else {
                                // It's a directory, get the directory handle
                                currentHandle = await currentHandle.getDirectoryHandle(part);
                            }
                        }
    
                        const audioFileData = await currentHandle.getFile();
                        await putOccurence(line, sanitizedPathParts.join('/'), audioFileData);
                    } catch (error) {
                        console.error('Error reading audio file:', error);
                    }
                }
            },
            header: true
        });
    };
    

    return (
        <div>
            <button onClick={handleFolderSelect}>Select Folder</button>
            <button onClick={processFolder}>Process Folder</button>
        </div>
    );
};
