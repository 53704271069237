import React, { useRef, useEffect, useState, useCallback } from "react";

const Testts = () => {
  const [files, setFiles] = useState([]);

  const handleFolderSelect = (event) => {
    const selectedFiles = Array.from(event.target.files);

    setFiles(selectedFiles);
  };

  return (
    <div className="mainpage">
      <label htmlFor="folder-picker">Choose a folder:</label>
      <input
        type="file"
        id="folder-picker"
        webkitdirectory="true"
        directory=""
        multiple
        onChange={handleFolderSelect}
      />
      <ul>
        {files.map((file, index) => (
          <li key={index}>{file.webkitRelativePath || file.name}</li>
        ))}
      </ul>
    </div>
  );
};

export default Testts;
