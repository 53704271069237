import { nanoid } from "nanoid";

const dataprovider = {
  getMockData: function () {
    const mock = [
      {
        date: "2022-07-02",
        items: [
          {
            title: "title",
            text: "description",
            id: 1,
          },
          {
            title: "title2",
            text: "description2",
            id: 2,
          },
        ],
      },
      {
        date: "2022-07-03",
        items: [
          {
            title: "title4",
            text: "description4",
            id: 3,
          },
          {
            title: "title4",
            text: "il se passe quoi si jai une super mega longue descrition ?",
            id: 4,
          },
        ],
      },
    ];
    return mock;
  },

  getSampleData: async function () {
    function getFileNames() {
      const fileList = [
        "2022-07-02.geojson?a=1",
        "2022-07-09.geojson?a=1",
        "2022-07-19.geojson?a=1",
        "2022-07-24.geojson?a=1",
        "2022-07-04.geojson?a=1",
        "2022-07-12.geojson?a=1",
        "2022-07-20.geojson?a=1",
        "2022-07-27.geojson?a=1",
        "2022-07-07.geojson?a=1",
        "2022-07-14.geojson?a=1",
        "2022-07-21.geojson?a=1",
        "2022-07-29.geojson?a=1",
        "2022-07-08.geojson?a=1",
        "2022-07-17.geojson?a=1",
        "2022-07-22.geojson?a=1",
      ];
      return fileList;
    }
    function getRootDir() {
      return "samplesatdata/";
    }

    function getFilesPath(filenames) {
      let filesPath = [];
      const datadir = getRootDir() + "data/";
      filenames.forEach((file) => {
        filesPath.push(datadir + file);
      });
      return filesPath;
    }

    async function loadjson(path) {
      return fetch(path)
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
          return data;
        });
    }

    function featureToItem(f) {
      let i = {
        title: f.title,
        text: f.properties.description,
        file: getRootDir() + "medias/" + f.medias[0].file,
        date: getDateFromUtcDate(f.datetime),
        dateUTC: new Date(f.datetime),
      };
      return i;
    }

    function featureCollectionToItemCollection(fc) {
      const icol = [];
      fc.features.forEach((f) => {
        icol.push(featureToItem(f));
      });
      return icol;
    }

    function getDateFromUtcDate(utcDateText: string) {
      const d = new Date(utcDateText);
      return d.getDate() + "/" + d.getMonth() + "/" + d.getFullYear();
    }

    function organiseItemsByDate(ilist) {
      let organized = [];
      ilist.forEach((i) => {
        let dateobj = organized.find((d) => d.date == i.date);
        if (!dateobj) {
          dateobj = {
            date: i.date,
            dateUTC: i.dateUTC,
            items: [],
          };
          organized.push(dateobj);
        }
        dateobj.items.push(i);
      });

      organized.sort((i1, i2) => {
        return i1.dateUTC - i2.dateUTC;
      });

      return organized;
    }

    function addIdToItem(item) {
      let newItem = { ...item };
      newItem.id = nanoid(8);
      return newItem;
    }

    function addIdToItemList(ilist) {
      let nilist = [];
      for (let i of ilist) {
        nilist.push(addIdToItem(i));
      }
      return nilist;
    }

    async function getAllItems() {
      const filesPath = getFilesPath(getFileNames());

      let ilist = [];
      for (const file of filesPath) {
        let json = await loadjson(file);
        ilist.push(...featureCollectionToItemCollection(json));
      }
      ilist = addIdToItemList(ilist);
      return ilist;
    }
    ///////////////////////////////////
    const allItems = await getAllItems();
    const org = organiseItemsByDate(allItems);

    return org;
  },
};

export default dataprovider;
