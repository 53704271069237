import React, { useState, useEffect } from "react";
function ButtonsEdit({
  isEdit,
  editCallback,
  saveCallback,
  allowSave,
  allowDelete,
  deleteCallback,
}) {
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setIsSaving(false);
  }, [isEdit]);

  function deleteClicked() {
    if (window.confirm("Sur?")) {
      deleteCallback();
    }
  }

  async function saveClicked() {
    setIsSaving(true);
    const saveSucess = await saveCallback();
    if (!saveSucess) {
      setIsSaving(false);
    }
  }

  if (!isEdit) {
    return <button onClick={editCallback}>Modifier</button>;
  }

  return (
    <>
      {!isSaving && (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <>
              <button onClick={editCallback}>Annuler</button>
              <button
                onClick={saveClicked}
                disabled={!allowSave}
                style={{ backgroundColor: "limegreen" }}
              >
                Enregistrer
              </button>
            </>
          </div>
          <br />
          {allowDelete && (
            <div style={{ width: "100%", textAlign: "left" }}>
              <button
                onClick={deleteClicked}
                style={{ backgroundColor: "#FF6666" }}
              >
                Supprimer
              </button>
            </div>
          )}
        </div>
      )}
      {isSaving && <span>enregistrement...</span>}
    </>
  );
}

export default ButtonsEdit;
