import React, { useEffect, useState } from "react";
import { AllDataDashboard } from "./AllDataType";
import Plotly from "plotly.js-dist";

const BatsPerHour = ({ currentSpecie }) => {
  const [allData, setAllData] = useState<AllDataDashboard | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/dashboards/AllData.json");
      const data: AllDataDashboard = await response.json();
      setAllData(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!allData) {
      return;
    }
    const formatedData = formatData(allData);
    DisplayGraph(formatedData);
  }, [allData]);

  //Construction du graphique
  function DisplayGraph(formatedData) {
    const {
      tableauLieuxCapteurs,
      tableauHeuresCapteurs,
      speciesDataCapteur: speciesData,
      InfoSpecies,
      ImgURL,
      Colorway,
    } = formatedData;

    //Calcul des moyennes
    var trace = [];

    var compteurPourLieux = 0;
    for (const k in speciesData) {
      var YData = [];
      YData.push(...Object.values(speciesData[k]));
      trace.push({
        x: tableauHeuresCapteurs,
        y: YData,
        name: tableauLieuxCapteurs[compteurPourLieux],
        type: "bar",
      });
      compteurPourLieux += 1;
    }

    var layout = {
      title: {
        text: "Nombre de passages moyens par heure (activité ultrasonore)",
        font: {
          size: 19,
          weight: "bold",
         },
      },
      yaxis: {
        title: {
          text: "Moyenne du nombre de passages",
          font: { size: 16 },
        },
      },
      xaxis: {
        title: {
          text: "Heure",
          font: { size: 16 },
        },
      },
      barmode: "stack",
      colorway: Colorway,
      margin: { t: 70 },
    };

    Plotly.newPlot("divSpeciesPerHour", trace, layout);
  }

  function formatData(alldata: AllDataDashboard) {
    var ImgURL = "";

    var speciesDataCapteur = [];
    var LieuxCapteurs = [];
    var HeuresCapteurs = new Set();

    for (const species in alldata.AllDataDashboardEspece[0]) {
      if (species === currentSpecie) {
        speciesDataCapteur.push(
          alldata.AllDataDashboardEspece[0][species].Lieu
        );
        LieuxCapteurs.push(
          ...Object.keys(alldata.AllDataDashboardEspece[0][species].Lieu)
        );

        var suiteCapteurs = alldata.AllDataDashboardEspece[0][species].Lieu;

        for (const i in suiteCapteurs) {
          Object.keys(suiteCapteurs[i]).forEach((date) =>
            HeuresCapteurs.add(date)
          );
        }
      }
    }

    var InfoSpecies = [];
    var Activation = 0;
    for (const species in alldata.AllDataDashboardEspece[0]) {
      if (species === currentSpecie) {
        InfoSpecies.push(alldata.AllDataDashboardEspece[0][species]);
        ImgURL = alldata.AllDataDashboardEspece[0][species].LienImage;
        Activation += 1;
      }
    }
    ///Capteurs
    var tableauLieuxCapteurs = [];
    for (const lieu of LieuxCapteurs) {
      tableauLieuxCapteurs.push(lieu);
    }
    var tableauHeuresCapteurs = [];
    for (const date of HeuresCapteurs) {
      tableauHeuresCapteurs.push(date);
    }

    speciesDataCapteur = speciesDataCapteur[0];
    InfoSpecies = InfoSpecies[0].Lieu;
    return {
      tableauLieuxCapteurs,
      tableauHeuresCapteurs,
      speciesDataCapteur,
      InfoSpecies,
      ImgURL,
    };
  }

  return <div style={{ width: "100%", height: "580px" }} id="divSpeciesPerHour"></div>;
};

export default BatsPerHour;
