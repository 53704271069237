import React from "react";
import useAdmin from "../hooks/useAdmin";
import { Link } from "react-router-dom";

const ToggleAdmin = () => {
  const { isAdmin, toggleAdmin, canBeAdmin } = useAdmin();

  function toggleAdminAndReload() {
    toggleAdmin();
    window.location.reload();
  }

  function AdminText() {
    if (!isAdmin) {
      return <span>(activer le mode admin)</span>;
    }
    return (
      <span style={{ color: "red" }}>
        Mode admin activé! Cliquer pour désactiver.
      </span>
    );
  }

  if (!canBeAdmin) {
    return <></>;
  }

  return (
    <a
      onClick={toggleAdminAndReload}
      className="btn btn-icon btn-noborder mr-1"
    >
      <i className="ei "></i>
      <AdminText />
    </a>
  );
};

export default ToggleAdmin;
