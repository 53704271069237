import React, { useState, useEffect, useRef } from "react";
import { patchItem } from "../dataProvider/itemProvider";

export default function Favorite({ item, allowClick = false }) {
  const [isFavorite, setIsFavorite] = useState(item?.isFavorite);

  function clicked() {
    if (!allowClick) {
      return;
    }
    const newFavorite = !isFavorite;
    setIsFavorite(newFavorite);
    const focuslevel = newFavorite ? 3 : 0;

    let changelist = {};
    changelist["properties.focuslevel"] = focuslevel;
    patchItem(item, changelist);
  }

  function displayStarOrNot() {
    if (!allowClick && !isFavorite) {
      return "";
    }

    let star = "☆";
    if (isFavorite) {
      star = "⭐";
    }
    return <span style={{ fontSize: "25px" }}>{star}</span>;
  }

  return (
    <>
      <div
        onClick={clicked}
        style={{ cursor: allowClick ? "pointer" : "default" }}
      >
        {displayStarOrNot()}
      </div>
    </>
  );
}
