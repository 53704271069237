import { fetchAuthSession } from 'aws-amplify/auth';
import { getApiUrl } from "./ConfigProvider";

const getAuthToken = async () => {
    const session = await fetchAuthSession();
    return session.tokens?.idToken?.toString();
}

const getFileUrl = async (fileName, setData = () => { }, setIsLoading = () => { }, setError = () => { }) => {
    console.log('getFileUrl');
    try {
        const authToken = await getAuthToken();

        // Append fileName to the query string
        const url = new URL(`${getApiUrl()}files`);
        url.searchParams.append('fileName', fileName);

        const response = await fetch(url, {
            headers: {
                'Authorization': authToken
            },
        });

        if (!response.ok) {
            throw new Error('getFileUrl : Network response was not ok');
        }

        const data = await response.json();
        setData(data);
        return data.url;
    } catch (error) {
        console.log('getFileUrl error', error)
        setError(error.message);
    } finally {
        setIsLoading(false);
    }
};

const getFileUrlNewVersion = async (dataset,fileName) => {
    try {    
        const authToken = await getAuthToken();

        // Append fileName to the query string
        const url = new URL(`${getApiUrl()}files`);
        url.searchParams.append('dataset', dataset);
        url.searchParams.append('fileName', fileName);

        const response = await fetch(url, {
            headers: {
                'Authorization': authToken
            },
        });

        if (!response.ok) {
            throw new Error('getFileUrlNewVersion : Network response was not ok');
        }

        const data = await response.json();
        return data.url;
    } catch (error) {
        console.log('getFileUrl error', error)
    } finally {
    }
};


const postFile = async (fileName, fileData, setIsLoading = () => { }, setError = () => { }) => {
    try {
        setIsLoading(true);
        const authToken = await getAuthToken();

        // Read the fileData as ArrayBuffer and then convert to base64
        const arrayBuffer = await fileData.arrayBuffer();
        const base64Data = arrayBufferToBase64(arrayBuffer);

        const bodyPayload = {
            fileName: fileName,
            data: base64Data
        };

        let response = null;

        // If direct upload fails, get a presigned URL for the file upload
        console.log('direct upload fails, get a presigned URL for the file upload');
        response = await fetch(`${getApiUrl()}files`, {
            method: 'POST',
            headers: {
                'Authorization': authToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ fileName: fileName })
        });

        if (!response.ok) {
            throw new Error('postFile : Unable to get presigned URL');
        }

        const presignedUrlData = await response.json();
        console.log('presignedUrlData',presignedUrlData);

        // Upload the file using the presigned URL
        response = await fetch(presignedUrlData.url, {
            method: 'PUT',
            body: fileData,
        });

        console.log('postFile response',response);
        if (!response.ok) {
            throw new Error('postFile fetch(presignedUrlData.url: Error uploading file to presigned URL');
        }

        console.log('File uploaded successfully using presigned URL.');
        return { message: 'File uploaded successfully using presigned URL.' };

    }
    catch (error) {
        console.log('postFile error',error)
        setError(error.message);
    } finally {
        setIsLoading(false);
    }
};


// Helper function to convert ArrayBuffer to Base64
function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export { postFile, getFileUrl,getFileUrlNewVersion };
