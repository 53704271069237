import React, { useEffect, useState, useRef } from "react";
import Favorite from "../components/Favorite";
import Item from "../dataProvider/item";
import { TinyItemThumbnail } from "../components/ItemThumbnail";
import { getPictoClassNameForType } from "../dataProvider/itemHelper";

const EventListEvent = ({ e, play, selectEventCallBack, isSelected }) => {
  function getIsNightIconSrc(e: Item) {
    if (!e.hasNightProperty()) {
      return null;
    }

    if (e.isNight()) {
      return "night.png";
    } else {
      return "day.png";
    }
  }

  return (
    <li
      onClick={() => selectEventCallBack(e)}
      className={!isSelected ? "disabled" : ""}
      style={{
        position: "relative",
      }}
    >
      {/* Image cell */}
      <div style={{ position: "absolute", top: "-7px", left: "-15px" }}>
        <Favorite item={e} />
      </div>
      <TinyItemThumbnail item={e} />
      {/* Text cell */}
      <div className="event-name">
        <span
          className="date"
          style={{
            position: "relative",
            top: e.hasNightProperty() ? "-8px" : "-3px",
          }}
        >
          {e.getTime()}
        </span>
        {e.hasNightProperty() && (
          <>
            <img
              style={{
                width: "20px",
                height: "auto",
                position: "relative",
                top: "-2px",
              }}
              src={getIsNightIconSrc(e)}
            />

            {e.properties.temp && (
              <span
                className="date"
                style={{
                  position: "relative",
                  top: "-8px",
                  left: "-15px",
                }}
              >
                {e.properties.temp}°C
              </span>
            )}
          </>
        )}

        <br />
        <span style={{ position: "relative", top: "-4px" }}>{e.title}</span>
      </div>
      {/* Type icon cell */}
      <div className="control" onClick={() => play(e)}>
        <a className="btn btn-icon bg-sea-green">
          <i className={getPictoClassNameForType(e.type)}></i>
        </a>
        <a className="btn btn-icon bg-pale-green">
          <i className="ei ei-play"></i>
        </a>
      </div>
    </li>
  );
};

export default EventListEvent;
