import React, { useState, useEffect } from "react";
import { getImportedDatasets, getHumanReadableDatasetName } from "../dataProvider/datasetProvider";
import CheckboxList from "../components/CheckboxList";
import Soufflet from "../components/Soufflet";
import DateFilter from "../routes/DateFilter";
import DataFilter from "./DataFilter";
import DataTypeFilter from "./DataTypeFilter";

export default function Filters({ filtersChangedCallback }) {
  const [checkallDatasets, setCheckAllDatasets] = useState(true); // New state to track check/uncheck all

  const [filters, setFilters] = useState({
    datasets: [],
    dateBox: {},
    titlecontains: "",
    favorite: false,
    options: {
      displayImage: true,
      displayVideo: true,
      displayCamera: true,
      displaySound: true,
      displayUltrason: true,
      displayPratique: true,
      displayGbif: true,
      displayInpn: true,
      displayParcellaire: true,
      displaySol: true,
    },
  });

  //first load: fetch
  useEffect(() => {
    getImportedDatasets().then((dsList) =>
      setFilters((prev) => ({
        ...prev,
        datasets: datasetlistToDatasetFilter(dsList),
      })),
    );
  }, []);

  //updated filters
  useEffect(() => {
    const apiFilters = buildApiFiltersFromLocalFilters(filters);
    filtersChangedCallback(apiFilters);
  }, [filters]);

  /////////////////////////////////////////////////////
  const toggleCheckAllDatadets = () => {
    const newVal = !checkallDatasets;
    setCheckAllDatasets(newVal);
    applySelectedToAllDatasets(newVal);
  };

  function titleFilterChanged(value: string) {
    setFilters((prev) => ({
      ...prev,
      titlecontains: value,
    }));
  }

  function favoriteFilterChanged(value: boolean) {
    setFilters((prev) => ({
      ...prev,
      favorite: value,
    }));
  }

  function updateDatesetsinFilters(updated) {
    setFilters((prev) => ({
      ...prev,
      datasets: updated,
    }));
  }

  function dateFilterChangedCallBack(dateBox) {
    setFilters((prev) => ({
      ...prev,
      dateBox: dateBox,
    }));
  }

  function buildApiFiltersFromLocalFilters(localFilters) {
    let retfilters = {};
    retfilters.datasets = localFilters.datasets
      .filter((ds) => ds.selected)
      .map((ds) => ds.key);
    retfilters.dateBox = localFilters.dateBox;
    retfilters.titlecontains = localFilters.titlecontains;
    retfilters.options = localFilters.options;
    retfilters.favorite = localFilters.favorite;
    return retfilters;
  }

  const datasetlistToDatasetFilter = (dsList) => {
    return dsList.map((ds) => ({
      key: ds,
      value: ds,
      selected: true,
    }));
  };

  function datasetChangedCallback(key) {
    let updated = [...filters.datasets];
    const i = updated.findIndex((ds) => ds.key === key);
    updated[i].selected = !updated[i].selected;
    updateDatesetsinFilters(updated);
  }
  function applySelectedToAllDatasets(selected: boolean) {
    let updated = [...filters.datasets];
    updated.forEach((ds) => (ds.selected = selected));
    updateDatesetsinFilters(updated);
  }

  function DataTypeFilterChanged(newoptions) {
    setFilters((prev) => ({
      ...prev,
      options: newoptions,
    }));
  }

  return (
    <div>
      <Soufflet title="Jeux de données">
        <a className="btn btn-primary" onClick={toggleCheckAllDatadets}>
          {checkallDatasets ? "Tout décocher" : "Tout cocher"}
        </a>
        <CheckboxList
          list={filters.datasets.map(ds => ({
            key: ds.key,
            value: getHumanReadableDatasetName(ds.value),
            selected: ds.selected,
          }))}
          onChange={datasetChangedCallback}
        />
      </Soufflet>
      <Soufflet title="Type de données">
        <DataTypeFilter
          options={filters.options}
          filterChangedCallBack={DataTypeFilterChanged}
        />
      </Soufflet>
      <Soufflet title="Dates">
        <DateFilter
          filterChangedCallBack={dateFilterChangedCallBack}
          displayMode="flat"
        />
      </Soufflet>
      <Soufflet title="Données">
        <DataFilter
          titleChanged={titleFilterChanged}
          favoriteChanged={favoriteFilterChanged}
        />
      </Soufflet>
    </div>
  );
}
