import React, { useState, useEffect } from "react";
import { getSensorsAsAdminOnlyActives } from "../../dataProvider/sensorProviders";
import { getClientList } from "../ClientUtils";

const StatusSelectorClient = function ({client,sensorRef, changedCallback }) {

    const choisir_string = '--choisir--';
    const [sensors, setSensors] = useState([]);
    const [selectedClient, setSelectedClient] = useState(client ? client : choisir_string);
    const [selectedSensorRef, setSelectedSensorRef] = useState(sensorRef ? sensorRef : choisir_string);
    const [isLoading, setIsLoading] = useState(false);

    async function getSensorsForClient(client) {
        const sensors = await getSensorsAsAdminOnlyActives(client);
        //sort by ref
        sensors.sort((a, b) => a.ref.localeCompare(b.ref));
        if(!sensors) {
            return [];
        }
        sensors.unshift({ref: choisir_string});
        return sensors;
    }

    function getSelectedClientValueToSave() {
        return selectedClient === choisir_string ? '' : selectedClient;
    }

    function getSelectedSensorRefValueToSave() {
        return selectedSensorRef === choisir_string ? '' : selectedSensorRef;
    }

    useEffect(() => {
        console.log('selectedClient', selectedClient);
        loadSensorsForCurrentClient();
        changedCallback(getSelectedClientValueToSave(), getSelectedSensorRefValueToSave());
    }, [selectedClient]);

    useEffect(() => {
        console.log('selectedSensorRef', selectedSensorRef);
        changedCallback(getSelectedClientValueToSave(), getSelectedSensorRefValueToSave());
    }, [selectedSensorRef]);

    async function loadSensorsForCurrentClient() {
        if(selectedClient === choisir_string) {
            setSensors([]);
            setSelectedSensorRef('');
            return;
        }
        setIsLoading(true);
        const sensors = await getSensorsForClient(selectedClient);
        if(!sensors.find(s => s.ref === selectedSensorRef)) {
            setSelectedSensorRef(choisir_string);
        }

        setSensors(sensors);
        setIsLoading(false);
    }

    function listClients() {
        let clients = getClientList();
        clients.unshift(choisir_string);
        return clients;
    }

    return (
        <>
            <div>
                Client:
                <select onChange={(e) => setSelectedClient(e.target.value)} value={selectedClient}>
                {listClients().map((client) => (
                        <option key={client} value={client}>{client}</option>
                    ))}
                </select>
            </div>
            <>
                Référence capteur:
                {isLoading && <div>
                    <i className="fa-solid fa-spinner fa-spin" ></i>
                </div>}
                {!isLoading && (
                    <>
                        {(sensors && sensors.length > 0) && (
                            <select onChange={(e) => setSelectedSensorRef(e.target.value)} value={selectedSensorRef}>
                                {sensors.map((sensor) => (
                                    <option key={sensor.ref} value={sensor.ref}>{sensor.ref}</option>
                                ))}
                            </select>
                        )}
                        {(!sensors || sensors.length === 0) && (
                            selectedClient !== choisir_string && (
                                <div style={{color: "red"}}>Erreur: Aucun capteur trouvé pour ce client</div>
                            )
                        )}
                    </>
                )}
            </>
        </>
    );
}

const StatusSelectorPerduOrHorsService = function ({ changedCallback,detailValue }) {
    return (
        <div>
            <span>Commentaire eventuel:</span><br />
            <input type="text" onChange={(e) => changedCallback(e.target.value)} value={detailValue} />
        </div>
    );
}

const StatusSelectorEnStock = function ({ changedCallback,detailValue }) {
    return (
        <div>
            <span>Emplacement</span><br />
            <input type="text" onChange={(e) => changedCallback(e.target.value)} value={detailValue} />
        </div>
    );
}

const StatusSelector = function ({ statusKey, changedCallback, client, sensorRef, detailValue }) {

    function changedForStatusDetails(value) {
        const details = {
            statusDetails: value,
            client: "",
            sensorRef: "",

        };
        console.log('details', details);
        changedCallback(details);
    }
    function changedForClientAndSensorRef(client, sensorRef) {
        const details = {
            statusDetails: "",
            client: client,
            sensorRef: sensorRef,
        };
        console.log('details', details);
        changedCallback(details);
    }

    switch (statusKey) {
        case "installé":
            return <StatusSelectorClient client={client} sensorRef={sensorRef} changedCallback={changedForClientAndSensorRef} />;
        case "hors_service":
        case "perdu":
        case "autre":
            return <StatusSelectorPerduOrHorsService changedCallback={changedForStatusDetails} detailValue={detailValue} />;
        case "en_stock":
            return <StatusSelectorEnStock changedCallback={changedForStatusDetails} detailValue={detailValue} />;
        default:
            throw new Error("StatusSelector: statusKey not found");
    }
}

export default StatusSelector;