import React, { useEffect, useState } from "react";
import { AllDataDashboard } from "./AllDataType";
import BatsPerHour from "./BatsPerHour";
import BatsSpecieCard from "./BatsSpecieCard";
import { MapContainer, TileLayer, Polygon, Marker, Popup, Tooltip } from "react-leaflet";
import L from "leaflet";
import Checkbox from "../../components/Checkbox";
import { getTilelayerUrl } from "../../mapHelpers";

const BatsEspeces = () => {
  const [allData, setAllData] = useState<AllDataDashboard | null>(null);
  const [showMarkers, setShowMarkers] = useState(true);

  function getCurrentSpecie() {
    const urlParams = new URLSearchParams(window.location.search);
    const currentSpecies = urlParams.get("species");
    return currentSpecies;
  }

  function getMarkers(allData: AllDataDashboard) {
    if (!allData.Markers[0]) return [];

    const ret = Object.entries(allData.Markers[0]).map(([name, position]) => ({
      name,
      position: position as [number, number],
    }));
    return ret;
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/dashboards/AllData.json");
      const data: AllDataDashboard = await response.json();
      setAllData(data);
    };
    fetchData();
  }, []);


  const customIcon = L.icon({
    iconUrl: "marker-icon.png",
    iconSize: [28, 28],
    iconAnchor: [0, 16],
    popupAnchor: [0, -32],
  });

  if (!allData) return <div>Loading...</div>;
  return (
    <div className="mainpage">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          marginLeft: "0 auto",
          maxWidth: "1600px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "70%",
          }}
        >
          <div className="batsdashboards-card" style={{ width: "100%" }}>
            <BatsPerHour currentSpecie={getCurrentSpecie()} />
          </div>
          <div style={{ width: "100%", height: "100%" }}>
            <MapContainer
              center={[44.716765, -0.379938]}
              zoom={16}
              style={{ height: "400px", width: "100%", borderRadius: "10px" }}
            >
              <TileLayer url={getTilelayerUrl("esri")} />
              <Polygon
                positions={allData.ContourLaPeyruche}
                pathOptions={{
                  fillColor: "none",
                  color: "white",
                  opacity: 0.5,
                }}
              />
              {getMarkers(allData).length > 0 &&
                getMarkers(allData).map((marker) => (
                  <Marker key={marker.name} position={marker.position} icon={customIcon}>
                  {!showMarkers && <Popup>{marker.name}</Popup>}
                  {showMarkers && (
                    <Tooltip permanent direction="top" offset={[15, -10]}>
                      {marker.name}
                    </Tooltip>
                  )}
                  </Marker>
                ))}
            </MapContainer>

          <div>
            <ul className="switch">
              <Checkbox
                label="Afficher les libellés"
                checked={showMarkers}
                onChange={(name, checked) => setShowMarkers(checked)}
                name="showMarkers"
              />
            </ul>
          </div>
          </div>
        </div>
        <div
          className="batsdashboards-card"
          style={{ fontSize: "1em", fontWeight: "normal", width: "30%" }}
        >
          <BatsSpecieCard currentSpecie={getCurrentSpecie()} />
        </div>
      </div>
    </div>
  );
};

export default BatsEspeces;
