// Import the centroid function from turf
import { centroid } from "@turf/turf";

function getCentroidOfGeoJSON(geoJSON) {
  if (!geoJSON) {
    throw new Error("Invalid GeoJSON data");
  }

  try {
    // Calculate the centroid of the GeoJSON object
    const centroidPoint = centroid(geoJSON);
    // Return the coordinates of the centroid
    return centroidPoint.geometry.coordinates;
  } catch (error) {
    console.error("Error calculating centroid:", error);
    return null;
  }
}

const getFirstPointOfGeo = function (geojson) {
  //returns  [lat,lng]
  if (!geojson || typeof geojson !== "object" || !geojson.type) {
    throw new Error("Invalid GeoJSON data");
  }

  let lat, lng;

  switch (geojson.type) {
    case "Point":
      // Set the coordinates as [latitude, longitude]
      lat = geojson.coordinates[1];
      lng = geojson.coordinates[0];
      break;
    case "LineString":
    case "MultiPoint":
      // Set the first point
      lat = geojson.coordinates[0][1];
      lng = geojson.coordinates[0][0];
      break;
    case "Polygon":
    case "MultiLineString":
      // Set the first point of the first LineString or Polygon
      lat = geojson.coordinates[0][0][1];
      lng = geojson.coordinates[0][0][0];
      break;
    case "MultiPolygon":
      // Set the first point of the first Polygon
      lat = geojson.coordinates[0][0][0][1];
      lng = geojson.coordinates[0][0][0][0];
      break;
    default:
      throw new Error("Unsupported GeoJSON type");
  }

  // Round the latitude and longitude values to 6 decimal places
  lat = parseFloat(lat.toFixed(6));
  lng = parseFloat(lng.toFixed(6));

  return [lat, lng];
};

const areGeojsonEqual = function (geo1, geo2) {
  // Check if both geoJSON objects have the same type
  if (geo1.type !== geo2.type) {
    return false;
  }

  // Recursive function to compare arrays deeply
  const compareArrays = function (arr1, arr2) {
    if (arr1.length !== arr2.length) return false; // Different lengths mean they are not the same
    /*
    console.log("arrs:", arr1, arr2)
    console.log(arr1.length, arr2.length)
    */
    for (let i = 0; i < arr1.length; i++) {
      if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
        // If elements are arrays, compare them recursively
        if (!compareArrays(arr1[i], arr2[i])) return false;
      } else {
        // Compare non-array items
        const num1 = arr1[i].toFixed(5);
        const num2 = arr2[i].toFixed(5);

        if (num1 !== num2) {
          const num1 = arr1[i].toFixed(3);
          const num2 = arr2[i].toFixed(3);
          if (num1 !== num2) return false;
        }
      }
    }
    return true; // All elements matched
  };

  // Use the recursive function to compare coordinates
  return compareArrays(geo1.coordinates, geo2.coordinates);
};

const isGeojsonPoint = function (geojson) {
  if (!geojson) {
    throw new Error("geojson is empty");
  }

  return geojson.type == "Point";
};
export {
  getFirstPointOfGeo,
  isGeojsonPoint,
  areGeojsonEqual,
  getCentroidOfGeoJSON,
};
