import { EventType } from "../api";
import Item from "../dataProvider/item";

export function getPictoClassNameForType(type) {
  let ret = "ei ";
  let className = "";

  switch (type) {
    case EventType.SOUND:
    case EventType.ULTRASON:
      className = "ei-sound";
      break;
    case EventType.INPN:
    case EventType.GBIF:
    case EventType.FAUNA:
      className = "ei-cloud-check";
      break;
    case EventType.PRATIQUE:
      className = "ei-soil";
      break;
    case EventType.SOL:
      className = "ei-edna";
      break;
    case EventType.CAMERA:
    case EventType.IMAGE:
    case EventType.VIDEO:
      className = "ei-camera";
      break;
  }
  ret = ret + className;
  return ret;
}

export function getThumbnailUrlFromTaxref(e: Item) {
  let taxref = e.taxref;
  if (!taxref) return null;

  taxref = taxref.replace(/ /g, "_");

  const dir = "thumbnail/";
  return dir + taxref + ".jpg?a=4";
}

export function getThumbnailUrlFromTitle(e: Item) {
  let dir = "thumbnail/";

  const found = e.properties?.thumbnailUrl;
  if (found) {
    return dir + found;
  }

  if (!e.title) return null;

  if (e.type == EventType.GBIF) {
    return dir + "gbif.png";
  }

  if (e.type == EventType.INPN) {
    return dir + "inpn.png";
  }

  if (e.type == EventType.PRATIQUE) {
    return dir + "pratique.png";
  }

  if (e.type == EventType.SOL) {
    return dir + "adn.png";
  }

  if (e.type == EventType.FAUNA) {
    return dir + "fauna.png";
  }

  let title = e.title;
  let startsWithNumber = /^\d/.test(title);
  if (startsWithNumber) {
    //remove the number
    title = title.replace(/^\d+/, "");
    //remove the 's' at the end
    title = title.replace(/s$/, "");
    //remove starting spaces
    title = title.replace(/^\s+/, "");
  }

  let imageName =
    title
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[ ]/g, "_")
      .replace(/[’']/g, "") + ".jpg?a=4";

  return dir + imageName;
}
