import React, { useState, useEffect } from "react";
import { getDepositState } from "../ConfigProvider";

function DepositState() {
  const [depositState, setDepositState] = useState<{ status: string }>({
    status: "",
    message: "",
  });

  useEffect(() => {
    getDepositState().then((state) => {
      console.log(state);
      const statusObj = JSON.parse(state);
      setDepositState(statusObj);
    });
  }, []);

  function isDepositStateOk() {
    return depositState.status === "ok";
  }

  function isDepositStateError() {
    return depositState.status === "error";
  }

  

  function DepositStateMessage() {
    const msg = depositState.message;
    const msgLines = msg.split("\n");
    return (
      <>
        <div style={{ fontWeight: "bold", textDecoration: "underline" }}>
          Details de(s) erreur(s):
        </div>
        {msgLines.map((line) => {
          let color = "black";
          console.log(line);
          if (line.includes("[91m")) {
            color = "red";
            line = line.substring(line.indexOf("[91m") + 4);
            line = line.substring(0, line.indexOf("[0m"));
          }
          return <div style={{ color: color }}>{line}</div>;
        })}
        <hr />
      </>
    );
  }

  return (
    <div>
      <h2 style={{ textAlign: "center", margin: "1em 0" }}>
        Etat du dépôt:
        {isDepositStateOk() ? (
          <span style={{ color: "green" }}>OK</span>
        ) : (
          <span style={{ color: "red" }}>Erreur</span>
        )}
      </h2>
      {isDepositStateError() && <DepositStateMessage />}
    </div>
  );
}

export default DepositState;
