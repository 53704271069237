import React, { useState, useEffect } from "react";
import useAdmin from "../hooks/useAdmin";
import { getCampaignsStatus } from "../ConfigProvider";
import CopyButton from "../components/CopyButton";
import DepositState from "./DepositState";

enum CampaignState {
  DEPOSITED = "deposited",
  DEPOSITED_READY = "deposited_ready",
  DEPOSITED_READY_ERROR = "deposited_ready_error",
  DEPOSITED_IN_TREATMENT = "deposited_in_treatment",
  A_TAGGER = "a_tagger",
  A_TAGGER_READY = "a_tagger_ready",
  A_TAGGER_IN_TREATMENT = "a_tagger_in_treatment",
  IN_E1C = "in_e1c",
  IN_POSTGIS = "in_postgis",
}

interface Campagne {
  id: string;
  state: CampaignState;
}

function getCampainClientName(campaignId: string): string {
  return campaignId.split("/")[0];
}

function getCampainDatatype(campaignId: string): string {
  return campaignId.split("/")[1];
}
function getDateFields(campaignId: string): string {
  return campaignId.split("/")[2];
}

function getCampainStartDate(campaignId: string): string {
  return getDateFields(campaignId).split("_")[0];
}

function getCampainEndDate(campaignId: string): string {
  return getDateFields(campaignId).split("_")[1];
}

function dateToHumanReadable(date: string): string {
  const day = date.substring(0, 2);
  const month = date.substring(2, 4);
  const year = date.substring(4);
  return `${day}/${month}/${year}`;
}

function getDistinctClients(campagnes: Campagne[]): string[] {
  return Array.from(new Set(campagnes.map((c) => getCampainClientName(c.id))));
}
function getDistinctDataTypes(campagnes: Campagne[]): string[] {
  return Array.from(new Set(campagnes.map((c) => getCampainDatatype(c.id))));
}

function getPossibleStates(): CampaignState[] {
  const states: CampaignState[] = [];
  for (const state in CampaignState) {
    if (isNaN(Number(state))) {
      states.push(CampaignState[state as keyof typeof CampaignState]);
    }
  }
  return states;
}

function getHumanReadableCampaignState(state: CampaignState): string {
  switch (state) {
    case CampaignState.DEPOSITED:
      return "En cours de dépôt";
    case CampaignState.DEPOSITED_READY:
      return "Dépôt terminé, prêt à être traité par le robot";
    case CampaignState.DEPOSITED_READY_ERROR:
      return "Erreur du dépôt";
    case CampaignState.DEPOSITED_IN_TREATMENT:
      return "Dépot en cours de traitement par le robot";
    case CampaignState.A_TAGGER:
      return "En attente de tag par l'humain";
    case CampaignState.A_TAGGER_READY:
      return "Taguée, prête à être traité par le robot";
    case CampaignState.A_TAGGER_IN_TREATMENT:
      return "Tag en cours de traitement par le robot";
    case CampaignState.IN_E1C:
      return "Dans la plateforme (non importé)";
    case CampaignState.IN_POSTGIS:
      return "Dans la plateforme (importé)";
  }
}

function SuiviCampagnes() {
  const [clients, setClients] = useState<string[]>([]);
  const [dataTypes, setDataTypes] = useState<string[]>([]);
  const [states, setStates] = useState<CampaignState[]>([]);
  const [campagnes, setCampagnes] = useState<Campagne[]>(null);

  function campagnesJsonToCampagnes(campagnes: object): Campagne[] {
    const campagnesArray: Campagne[] = [];
    for (const campaign of Object.values(campagnes)) {
      campagnesArray.push(campaign);
    }
    return campagnesArray;
  }

  function cleanStrFromJson(str: string): string {
    const cleanedStr = str.replace(/'/g, '"');
    const cleanedWithoutNewlines = cleanedStr.replace(/\n/g, "");
    return cleanedWithoutNewlines;
  }

  function invertClientsSelection() {
    const clientsDistincts = getDistinctClients(campagnes);
    const newClients = [];
    for (const client of clientsDistincts) {
      if (!clients.includes(client)) {
        newClients.push(client);
      }
    }
    setClients(newClients);
  }

  function invertDataTypesSelection() {
    const dataTypesDistincts = getDistinctDataTypes(campagnes);
    const newDataTypes = [];
    for (const dataType of dataTypesDistincts) {
      if (!dataTypes.includes(dataType)) {
        newDataTypes.push(dataType);
      }
    }
    setDataTypes(newDataTypes);
  }

  function invertStatesSelection() {
    const statesDistincts = getPossibleStates();
    const newStates = [];
    for (const state of statesDistincts) {
      if (!states.includes(state)) {
        newStates.push(state);
      }
    }
    setStates(newStates);
  }

  async function fetchCampagnes() {
    let campagnes = await getCampaignsStatus();
    if (!campagnes) {
      campagnes = [];
    } else {
      const campagnesJson = JSON.parse(cleanStrFromJson(campagnes));
      campagnes = campagnesJsonToCampagnes(campagnesJson);
    }
    setCampagnes(campagnes);
  }

  useEffect(() => {
    fetchCampagnes();
  }, []);

  useEffect(() => {
    if (!campagnes) return;
    setClients(getDistinctClients(campagnes));
    setDataTypes(getDistinctDataTypes(campagnes));
    setStates(Object.values(CampaignState));
  }, [campagnes]);

  const { canBeAdmin } = useAdmin();
  if (!canBeAdmin) {
    return (
      <div className="mainpage">
        <p>Vous n'êtes pas autorisé à accéder à cette page.</p>
      </div>
    );
  }

  if (!campagnes) {
    return (
      <div className="mainpage">
        <p>
          Chargement des campagnes...
          <i className="fa-solid fa-spinner fa-spin"></i>
        </p>
      </div>
    );
  }

  return (
    <div className="mainpage">
      <DepositState />
      <h2 style={{ textAlign: "center", margin: "1em 0" }}>
        Suivi des campagnes
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div>
          Clients: &nbsp;
          <i
            className="fa-solid fa-arrow-right-arrow-left"
            style={{ cursor: "pointer" }}
            onClick={() => invertClientsSelection()}
          ></i>
          {getDistinctClients(campagnes).map((client) => (
            <label key={client} style={{ marginRight: "10px" }}>
              <br />
              <input
                type="checkbox"
                checked={clients.includes(client)}
                onChange={(e) =>
                  setClients(
                    e.target.checked
                      ? [...clients, client]
                      : clients.filter((c) => c !== client)
                  )
                }
              />
              {client}
            </label>
          ))}
          <br />
          <br />
          Types de données: &nbsp;
          <i
            className="fa-solid fa-arrow-right-arrow-left"
            style={{ cursor: "pointer" }}
            onClick={() => invertDataTypesSelection()}
          ></i>
          {getDistinctDataTypes(campagnes).map((dataType) => (
            <label key={dataType} style={{ marginRight: "10px" }}>
              <br />
              <input
                type="checkbox"
                checked={dataTypes.includes(dataType)}
                onChange={(e) =>
                  setDataTypes(
                    e.target.checked
                      ? [...dataTypes, dataType]
                      : dataTypes.filter((dt) => dt !== dataType)
                  )
                }
              />{" "}
              {dataType}
            </label>
          ))}
          <br />
          <br />
          État: &nbsp;
          <i
            className="fa-solid fa-arrow-right-arrow-left"
            style={{ cursor: "pointer" }}
            onClick={() => invertStatesSelection()}
          ></i>
          {getPossibleStates().map((state) => (
            <label key={state} style={{ marginRight: "10px" }}>
              <br />
              <input
                type="checkbox"
                checked={states.includes(state)}
                onChange={(e) =>
                  setStates(
                    e.target.checked
                      ? [...states, state]
                      : states.filter((s) => s !== state)
                  )
                }
              />{" "}
              {getHumanReadableCampaignState(state)}
            </label>
          ))}
        </div>
        <table className="sensorDetailTable"
          style={{
            width: "100%",
            maxWidth: "1100px",
            margin: "0 auto",
          }}
                  >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  fontWeight: "bold",
                }}
              >
                Client
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  fontWeight: "bold",
                }}
              >
                Type de données
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  fontWeight: "bold",
                }}
              >
                Date de début
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  fontWeight: "bold",
                }}
              >
                Date de fin
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  fontWeight: "bold",
                }}
              >
                État
              </th>

              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  fontWeight: "bold",
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {campagnes
              .filter(
                (campagne) =>
                  clients.includes(getCampainClientName(campagne.id)) &&
                  dataTypes.includes(getCampainDatatype(campagne.id)) &&
                  states.includes(campagne.state)
              )
              .map((campagne) => (
                <tr
                  key={campagne.id}
                  style={{
                    backgroundColor:
                      campagne.state === CampaignState.A_TAGGER
                        ? "lightgreen"
                        : campagne.state === CampaignState.DEPOSITED_READY ||
                          campagne.state === CampaignState.A_TAGGER_READY ||
                          campagne.state === CampaignState.IN_E1C
                        ? "lightblue"
                        : "white",
                  }}
                >
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {getCampainClientName(campagne.id)}
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {getCampainDatatype(campagne.id)}
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {dateToHumanReadable(getCampainStartDate(campagne.id))}
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {dateToHumanReadable(getCampainEndDate(campagne.id))}
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {(campagne.state === CampaignState.DEPOSITED_IN_TREATMENT ||
                      campagne.state ===
                        CampaignState.A_TAGGER_IN_TREATMENT) && (
                      <i className="fa-solid fa-robot">&nbsp;</i>
                    )}
                    {getHumanReadableCampaignState(campagne.state)}
                  </td>

                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <CopyButton val={campagne.id} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SuiviCampagnes;
