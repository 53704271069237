import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import Papa from 'papaparse';

import moment from 'moment';
import 'moment-timezone'; // If you are using timezones
import { postOccurrence } from '../OccurrencesApi';
import { postFile } from '../FilesApi';



export default function ImportCameratrap() {
  const [selectedFolder, setSelectedFolder] = useState(null);

  const handleFolderSelect = async () => {
    if (!window.showDirectoryPicker) {
      alert('Your browser does not support this feature');
      return;
    }

    try {
      const directoryHandle = await window.showDirectoryPicker();
      setSelectedFolder(directoryHandle);
    } catch (error) {
      console.error(error);
      alert('Error accessing folder');
    }
  };

  //function to uppercase the first char of a string
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const putOccurrenceVideo = async (e, videoPath, thumbnailDataUrl) => {
    const myUUID = uuidv4();

    try {
      const file = await getFileFromPath(selectedFolder, videoPath);
      const fileName = myUUID + '.mp4';
      await postFile(fileName, file);

      const response = await postOccurrence({
        uuid: myUUID,
        epoch: parseInt(e.epoch, 10) + 3, // la video est capturée 3s après l'image
        latitude: parseFloat(44.71652028),
        longitude: parseFloat(-0.38575731),
        type: 'video',
        title: capitalizeFirstLetter(e.prediction),
        data: fileName,
        thumbnail: thumbnailDataUrl,
      });
      console.log('PUT Succeeded: ', response);
    } catch (error) {
      console.error('PUT call failed: ', error);
    }
  };

  const putOccurrenceImage = async (e, imagePath) => {
    const myUUID = uuidv4();

    try {
      //data
      let file = await getFileFromPath(selectedFolder, imagePath);
      let fileNameData = myUUID + '.jpg';
      await postFile(fileNameData, file);

      //thumbnail
      file = await getFileFromPath(selectedFolder, imagePath.replace('.JPG', '_thumbnail.JPG'));
      let fileNameThumbnail = myUUID + '_thumbnail.jpg';
      //await uploadFile(fileNameThumbnail, file);

      console.log('fileNameData', fileNameData, 'epoch', parseInt(e.epoch, 10),);

      const response = await postOccurrence({
        uuid: myUUID,
        data: fileNameData,
        thumbnail: fileNameThumbnail,
        epoch: parseInt(e.epoch, 10),
        latitude: parseFloat(44.71652028),
        longitude: parseFloat(-0.38575731),
        type: 'image',
        title: capitalizeFirstLetter(e.prediction),
      });
      console.log('PUT Succeeded: ', response);
      return fileNameThumbnail; // for thumbnail
    } catch (error) {
      console.error('PUT call failed: ', error);
    }
  };

  const getFileFromPath = async (directoryHandle, imagePath) => {
    try {
      // Access the 'data' subdirectory
      const subdirectoryHandle = await directoryHandle.getDirectoryHandle('data', { create: false });
      // Now get the file handle from the 'data' subdirectory
      const fileHandle = await subdirectoryHandle.getFileHandle(imagePath, { create: false });
      return await fileHandle.getFile();
    } catch (error) {
      console.error('Error getting file:', error);
      throw error;
    }
  };


  const processFolder = async () => {
    if (!selectedFolder) {
      alert('Please select a folder first!');
      return;
    }

    try {
      const fileHandle = await selectedFolder.getFileHandle('deepfaune.csv');
      const file = await fileHandle.getFile();
      await processCSV(file); // Ensure this is awaited
      console.log('done!');
    } catch (error) {
      console.error(error);
      alert('CSV file not found in the selected folder');
    }
  };

  function calculateVideoFileName(imageFileName) {
    // Extract the base name (without extension) and the number
    let baseName = imageFileName.split('.')[0];
    let number = parseInt(baseName.match(/\d+/)[0], 10);

    // Increment the number
    number++;

    // Pad the number with leading zeros to maintain the same length
    let paddedNumber = number.toString().padStart(baseName.match(/\d+/)[0].length, '0');

    // Replace the old number with the new number and change the extension to .mp4
    let newFileName = baseName.replace(/\d+/, paddedNumber) + '.MP4';

    return newFileName;
  }

  function extractFilename(path) {
    const parts = path.split('/');
    return parts.pop(); // Returns the last element of the array (the filename)
  }

  const processCSV = async (file, processOnlyFirstItem = false) => {
    Papa.parse(file, {
      complete: async (result) => {
        const csvData = result.data; // Renamed to csvData to avoid scope conflict

        console.log('nombre de lignes dans le fichier csv:', csvData.length)

        for (let index = 1; index < csvData.length; index++) {

          console.log('handling line ', index)

          if (processOnlyFirstItem && index > 1) {
            break;
          }

          const row = csvData[index];
          let prediction = row[3];

          if (prediction === 'vide' || prediction === 'indéfini') {
            prediction = '';
            console.log('ligne' + index + ' ignorée car non reconnue par DeepFaune');
            continue; // on n'importe pas !
          }

          const date = moment.tz(row[1], "YYYY:MM:DD HH:mm:ss", "Europe/Paris").unix();

          // Extract just the filename from the path
          const filename = extractFilename(row[0]);

          const rowData = {
            filename: filename,
            prediction: prediction,
            epoch: date
          };
          console.log('Processing file:', rowData.filename);
          let thumbnailDataUrl = await putOccurrenceImage(rowData, rowData.filename);

          const videoFileName = calculateVideoFileName(rowData.filename);

          console.log('Processing video:', videoFileName);
          await putOccurrenceVideo(rowData, videoFileName, thumbnailDataUrl);
        }
      },
      header: false
    });
  };

  async function processCSVgetE1cItemFormat() {
    if (!selectedFolder) {
      alert('Please select a folder first!');
      return;
    }

    var itemCol = null;

    try {
      const fileHandle = await selectedFolder.getFileHandle('deepfaune.csv');
      const file = await fileHandle.getFile();
      itemCol = await getE1cItemFormat(file); // Ensure this is awaited
      console.log('done!');
    } catch (error) {
      console.error(error);
      alert('CSV file not found in the selected folder');
    }

    console.log('itemcol', itemCol);
    launchDownloadJson(itemCol);

  }

  function launchDownloadJson(json) {
    const jsonStr = JSON.stringify(json, null, 2);

    const blob = new Blob([jsonStr], { type: "application/json" });

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.json"; // Name of the file to download
    document.body.appendChild(a); // Append the link to the document
    a.click(); // Trigger the download

    URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  async function getE1cItemFormat(file, processOnlyFirstItem = false) {
    let itemCol = [];

    // Wrap Papa.parse in a new Promise
    await new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: async (result) => {
          const csvData = result.data; // Use csvData to avoid scope conflict

          console.log('nombre de lignes dans le fichier csv:', csvData.length)

          // Processing each row
          for (let index = 1; index < csvData.length; index++) {
            if (processOnlyFirstItem && index > 1) {
              break;
            }

            const row = csvData[index];
            let prediction = row[3];

            if (prediction === 'vide' || prediction === 'indéfini') {
              prediction = '';
              console.log('ligne ' + index + ' ignorée car non reconnue par DeepFaune');
              continue; // Skip importing this row
            }

            const epoch = moment.tz(row[1], "YYYY:MM:DD HH:mm:ss", "Europe/Paris").unix();
            const dateInUTC = moment.unix(epoch).utc().format();

            const filename = extractFilename(row[0]);
            if (!filename || filename.length === 0) {
              console.log('filename is empty');
              continue;
            }

            const lat = parseFloat(44.71652028);
            const long = parseFloat(-0.38575731);

            const e1cItem = {
              wkt: `POINT(${long} ${lat})`,
              startdate: dateInUTC,
              enddate: dateInUTC,
              title: prediction,
              text: "",
              datatype: "camera",
              medias: []
            };

            let thumbnailDataUrl = '';
            // For demonstration, assuming putOccurrenceImage and putOccurrenceVideo are async and return Promises
            //thumbnailDataUrl = await putOccurrenceImage(rowData, rowData.filename);

            const videoFileName = calculateVideoFileName(filename);

            console.log('Processing video:', videoFileName);
            //await putOccurrenceVideo(rowData, videoFileName, thumbnailDataUrl);

            console.log(e1cItem)
            console.log(filename)
            console.log(videoFileName)

            e1cItem.medias = [
              {
                label: 'image',
                file: filename
              },
              {
                label: 'video',
                file: videoFileName
              }
            ];
            itemCol.push(e1cItem);
          }
          resolve(); // Resolve the Promise after all rows have been processed
        },
        header: false
      });
    });

    return itemCol; // Return the collected items
  }



  return (
    <div>
      <br /><br /><br /><br /><br /><br /><br /><br /><br />
      <button onClick={handleFolderSelect}>Select Folder</button>
      <button onClick={processFolder}>Process Folder</button>
      <button onClick={processCSVgetE1cItemFormat}>getE1cItemFormat</button>
    </div>
  );
};
