import React, { useState, useEffect, useRef } from "react";
import { EventType } from "../api";
import { getPropertyBoundary, getPropertyCoordinates } from "../ConfigProvider";
import { getItemFromId } from "../dataProvider/itemProvider";
import Item from "../dataProvider/item";
import Favorite from "../components/Favorite";
import ItemPageMediaPlayer from "./ItemPageMediaPlayer";
import EditButton from "./EditButton";
import L from "leaflet";
import useAdmin from "../hooks/useAdmin";
import { getTilelayerUrl } from "../mapHelpers";
import { BigItemThumbnail, SmallItemThumbnail } from "../components/ItemThumbnail";


import {
  getPictoClassNameForType,
} from "../dataProvider/itemHelper";

function MapContainer({ item }) {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    if (item && !mapRef.current) {
      initMap();
    }
  }, [item]);

  function initMap() {
    if (!mapContainerRef.current) {
      return;
    }

    const defaultZoom = 15;
    const ignLayer = L.tileLayer(getTilelayerUrl("esri"), {
      maxZoom: 30,
      attribution: "&copy; IGN contributors",
    });

    const map = L.map(mapContainerRef.current, { maxZoom: 19 });
    mapRef.current = map;
    displayPropertyBoundaries(map);

    ignLayer.addTo(map);
    if (item.geojson) {
      const customIcon = L.icon({
        iconUrl: "marker-icon.png",
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32],
      });

      const geojsonLayer = L.geoJSON(item.geojson, {
        style: {
          color: "blue",
          fillColor: "lightblue",
          fillOpacity: 0.5,
          interactive: false,
        },
        pointToLayer: function (feature, latlng) {
          return L.marker(latlng, { icon: customIcon });
        },
      });

      geojsonLayer.addTo(map);

      map.fitBounds(geojsonLayer.getBounds(), { maxZoom: map.getZoom() });
      map.setZoom(defaultZoom);
    }
  }

  async function displayPropertyBoundaries(map) {
    try {
      const propertyBoundaries = await getPropertyBoundary();
      let propertyBoundariesGeoJSON = JSON.parse(propertyBoundaries);

      const propertyBoundaryLayer = L.geoJSON(propertyBoundariesGeoJSON, {
        style: {
          color: "#A3B75E",
          fillColor: "lightblue",
          fillOpacity: 0,
          interactive: false,
          weight: 5,
        },
      });
      propertyBoundaryLayer.addTo(map);
    } catch (error) {
      console.error("Error fetching property boundaries: ", error);
    }
  }

  return (
    <div
      ref={mapContainerRef}
      id="map"
      style={{ width: "500px", height: "400px" }}
    ></div>
  );
}

export default function ItemPage() {
  const [item, setItem] = useState(null);
  const [itemNotFound, setItemNotFound] = useState(false);
  const [displayData, setDisplayData] = useState({
    title: "",
  });

  const { isAdmin } = useAdmin();

  useEffect(() => {
    const itemId = getItemId();
    if (!itemId) {
      setItemNotFound(true);
      return;
    }

    getItemFromId(itemId).then((i) => {
      if (!i) {
        setItemNotFound(true);
        return;
      }
      setItem(i);
    });
  }, []);

  useEffect(() => {
    if (item) {
      const newDisplayData = { ...displayData };
      newDisplayData.title = item.title;
      setDisplayData(newDisplayData);
    }
  }, [item]);

  //////////////////////

  function itemHasBeenEdited(newItem: Item) {
    let newDisplayData = { ...displayData };
    newDisplayData.title = newItem.title;
    setDisplayData(newDisplayData);
  }

  function getItemId() {
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get("id");
    return paramValue;
  }

  function getErrorPage() {
    return <>Erreur: élément non trouvé</>;
  }

  function getLoading() {
    return <>Chargement... </>;
  }

  function getTitle() {
    return (
      <div
        style={{
          paddingTop: "30px",
        }}
      >
        <br />
        <h2 style={{ fontSize: "40px" }}>{displayData.title}</h2>
        <Favorite allowClick={isAdmin} item={item} />
        <EditButton item={item} onsave={itemHasBeenEdited} />

        <br />
      </div>
    );
  }

  function getBigThumbnail() {
    return (
      <div>
        <BigItemThumbnail item={item} />
      </div>
    );
  }

  function getSmallThumbnail() {
    if (!shouldDisplaySmallThumbnail()) {
      return null;
    }
    return (
      <div>
        <SmallItemThumbnail item={item} />
      </div>
    );
  }

  function getPicto() {
    return (
      <i
        style={{
          width: "30px",
          height: "30px",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          marginRight: "10px",
        }}
        className={getPictoClassNameForType(item.type)}
      ></i>
    );
  }

  function getIsNightIconSrc(e: Item) {
    if (!e.hasNightProperty()) {
      return null;
    }

    if (e.isNight()) {
      return "night.png";
    } else {
      return "day.png";
    }
  }

  function MetadataCameraType() {
    const temp = item.properties?.temp;
    return (
      <>
        {item.hasNightProperty() && (
          <>
            {item.isNight() ? <>Image de nuit</> : <>Image de jour</>}
            <img
              style={{
                width: "20px",
                height: "auto",
                position: "relative",
              }}
              src={getIsNightIconSrc(item)}
            />
            <br />
          </>
        )}
        {temp && <>Température: {temp} °C</>}
      </>
    );
  }

  function MetadataSoundType() {
    if (item.properties?.birdnetref) {
      return (
        <>
          <p>Fichier traité avec birdnet</p>
          <p>Identification Birdnet: {item.properties.birdnetref}</p>
          <p>% de confiance: {item.properties.confidence}</p>
        </>
      );
    }
    return <></>;
  }

  function CommonMetadata() {
    return <></>;
  }

  function Metadata() {
    function MetadataFromType() {
      if (item.datatype == EventType.CAMERA) {
        return <MetadataCameraType />;
      }
      if (item.datatype == EventType.SOUND) {
        return <MetadataSoundType />;
      }
    }
    return (
      <div
        style={{
          padding: "10px",
          boxShadow: " 0 0 30px rgba(0, 0, 0, 0.2)",
          borderRadius: "1rem",
        }}
      >
        <h2>Metadata:</h2>
        <CommonMetadata />
        <br />
        <MetadataFromType />
      </div>
    );
  }

  function Dates() {
    const diffText = item.getDurationString();
    return (
      <>
        Le {item.getDate()} à {item.getTime()}
        {diffText && <> pendant {diffText}</>}
      </>
    );
  }

  function ItemText() {
    return (
      <div>
        <br />
        <br />
        <div
          style={{ width: "300px" }}
          dangerouslySetInnerHTML={{ __html: item.text }}
        ></div>
      </div>
    );
  }

  function shouldDisplaySmallThumbnail(): boolean {
    return hasTextToDisplay();
  }
  function shouldDisplayTextOrBigThumbnail() {
    return !shouldDisplaySmallThumbnail();
  }
  function hasTextToDisplay() {
    return item.text && item.text.length > 0;
  }
  function getItemTextOrBigThumbnail() {
    if (hasTextToDisplay()) {
      return <ItemText />;
    }
    return getBigThumbnail();
  }

  function PageContent() {
    if (itemNotFound) {
      return getErrorPage();
    }

    if (!item) {
      return getLoading();
    }

    return (
      <>
        <div style={{ display: "flex" }}>
          {getSmallThumbnail()}

          <div style={{ flexGrow: "1", textAlign: "center" }}>
            {getTitle()}
            <Dates />
          </div>
          <div style={{ marginLeft: "auto" }}>{getPicto()}</div>
        </div>
        <hr />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 50px 0",
          }}
        >
          <Metadata />
          {getItemTextOrBigThumbnail()}
          <MapContainer item={item} />
        </div>
        <hr />
        <ItemPageMediaPlayer item={item} />
      </>
    );
  }

  return (
    <>
      <div className="mainpage">
        <PageContent />
      </div>
    </>
  );
}
