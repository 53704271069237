import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Root from "./routes/root";
import MapPage from "./routes/MapPage";
import Home from "./routes/Home";
import ImportGbif from "./import/ImportGbif.jsx";
import ImportBiophonia from "./import/ImportBiophonia.jsx";
import ImportCameratrap from "./import/ImportCameratrap.jsx";
import ImportINPN from "./import/ImportINPN.jsx";
import Test from "./routes/test.tsx";
import { I18n } from "aws-amplify/utils";
import { Authenticator } from "@aws-amplify/ui-react";
import Datasets from "./routes/Datasets.jsx";
import "@aws-amplify/ui-react/styles.css";
import SensorPage from "./sensorPage/SensorPage.tsx";
import Demodsepage from "./demodse/Demodsepage.tsx";
import TablePage from "./tablepage/TablePage.tsx";
import ItemPage from "./itempage/ItemPage.tsx";
import AllSitesDashboard from "./dashboards/AllSitesDashboard.tsx";
import DashboardHome from "./dashboards/DashboardHome.tsx";
import BatsHome from "./dashboards/chauvesouris/BatsHome.tsx";
import BatsEspeces from "./dashboards/chauvesouris/BatsEspeces.tsx";
import SiteDashboard from "./dashboards/SiteDashboard.tsx";
import AdminHome from "./admin/adminhome.tsx";
import SuiviCampagnes from "./admin/suiviCampagnes.tsx";
import InventaireCapteurs from "./admin/inventaireCapteurs/inventaireCapteurs.tsx";
import SitePage from "./sitePage/sitePage.tsx";

I18n.putVocabulariesForLanguage("fr-FR", {
  "Sign in": "Se connecter",
  "Forgot your password?": "Mot de passe oublié?",
  "username is required to signIn": "Nom d'utilisateur requis",
  "Incorrect username or password.":
    "Nom d'utilisateur ou mot de passe incorrect.",
  "User does not exist.": "Utilisateur inexistant.",
});
I18n.setLanguage("fr-FR");

const components = {
  Header() {
    return (
      <div>
        <img src="logo_E1C_blanc.png" alt="logo" />

        <br />
      </div>
    );
  },

  Footer() {
    return (
      <div>
        <br />
        Every1Counts
      </div>
    );
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: "Nom d'utilisateur",
      label: "Nom d'utilisateur",
    },
    password: {
      placeholder: "Mot de passe",
      label: "Mot de passe",
    },
  },
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "map/",
        element: <MapPage />,
      },
      {
        path: "importGbif/",
        element: <ImportGbif />,
      },
      {
        path: "importBiophonia/",
        element: <ImportBiophonia />,
      },
      {
        path: "importCameraTrap/",
        element: <ImportCameratrap />,
      },
      {
        path: "ImportINPN/",
        element: <ImportINPN />,
      },
      {
        path: "test/",
        element: <Test />,
      },
      {
        path: "datasets/",
        element: <Datasets />,
      },
      {
        path: "sensors/",
        element: <SensorPage />,
      },
      {
        path: "satellite/",
        element: <Demodsepage />,
      },
      {
        path: "table/",
        element: <TablePage />,
      },
      {
        path: "i/",
        element: <ItemPage />,
      },
      {
        path: "siteDashboard/",
        element: <SiteDashboard />,
      },
      {
        path: "allSitesDashboard/",
        element: <AllSitesDashboard />,
      },
      {
        path: "dashboardsHome",
        element: <DashboardHome />,
      },
      {
        path: "batsHome",
        element: <BatsHome />,
      },
      {
        path: "batsEspeces",
        element: <BatsEspeces />,
      },
      {
        path: "admin",
        element: <AdminHome />,
      },
      {
        path: "admin/campagnes",
        element: <SuiviCampagnes />,
      },
      {
        path: "admin/inventaireCapteurs",
        element: <InventaireCapteurs />,
      },
      {
        path: "sitePage",
        element: <SitePage />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <Authenticator
    components={components}
    formFields={formFields}
    hideSignUp="true"
  >
    {({ signOut, user }) => <RouterProvider router={router} />}
  </Authenticator>
);
