import React, { useEffect, useState } from "react";

const BatsSpecieCard = ({ currentSpecie }) => {
  interface InfoSpeciesType {
    [key: string]: string;
  }

  const [titresDesParties, setTitresDesParties] = useState<string[]>([]);
  const [contenuDesParties, setContenuDesParties] = useState<string[]>([]);
  const [InfoSpecies, setInfoSpecies] = useState<InfoSpeciesType[]>([]);
  const [ImgURL, setImgURL] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fichesEspeceResponse = await fetch(
          "/dashboards/FichesEspeces.json"
        );
        const loaded = await fichesEspeceResponse.json();
        const speciesData = loaded.Espece[0];
        const InfoSpecies = [];
        let ImgURL = "";
        let Activation = 0;
        for (const species in speciesData) {
          if (species === currentSpecie) {
            InfoSpecies.push(speciesData[species]);
            ImgURL = speciesData[species].LienImage;
            ImgURL = "dashboards/" + ImgURL;
            Activation += 1;
          }
        }
        setInfoSpecies(InfoSpecies);
        setImgURL(ImgURL);
        console.log("ImgURL", ImgURL);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (InfoSpecies && InfoSpecies[0]) {
      setTitresDesParties(Object.keys(InfoSpecies[0]));
      setContenuDesParties(Object.values(InfoSpecies[0]));
    }
  }, [InfoSpecies]);

  if (!InfoSpecies || !InfoSpecies[0]) {
    return <div>Pas de fiche espèce disponible</div>;
  }

  return (
    <div style={{ height: "1000px", display: "flex", flexDirection: "column" }}>
      <div
        style={{
          fontSize: "1.5em",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "10px",
          width: "100%",
        }}
      >
        {currentSpecie}
      </div>
      <img src={ImgURL} alt={currentSpecie} style={{ width: "100%" }} />
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <div
          style={{
            fontSize: "1.2em",
            fontWeight: "bold",
            textAlign: "left",
            marginTop: "10px",
          }}
        >
          <strong>Taille :</strong>
        </div>
        <p>
          {titresDesParties[1]}: {contenuDesParties[1]}
          <br />
          {titresDesParties[2]}: {contenuDesParties[2]}
          <br />
          {titresDesParties[3]}: {contenuDesParties[3]}
        </p>
        <div
          style={{
            fontSize: "1.2em",
            fontWeight: "bold",
            textAlign: "left",
            marginTop: "10px",
          }}
        >
          <strong>Conditions de vie :</strong>
        </div>
        <p>
          {titresDesParties[0]}: {contenuDesParties[0]}
          <br />
          {titresDesParties[4]}: {contenuDesParties[4]}
          <br />
          {titresDesParties[5]}: {contenuDesParties[5]}
        </p>
        <div
          style={{
            fontSize: "0.8em",
            fontWeight: "normal",
            textAlign: "left",
            color: "gray",
            marginTop: "auto",
          }}
        >
          <hr style={{
            border: 'none',
            height: '1px',
            backgroundColor: '#ccc',
            margin: '10px 0',
            borderRadius: '5px'
          }} />
          <p>
            {titresDesParties[6]}: {contenuDesParties[6]}
            <br />
            {titresDesParties[7]}: {contenuDesParties[7]}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BatsSpecieCard;
