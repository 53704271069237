import React, { useState, useEffect } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import { getApiUrl } from "../ConfigProvider";
import useSuperadmin from "../hooks/useSuperadmin";
import { getClientName } from "../ConfigProvider";
import useAdmin from "../hooks/useAdmin";

const Datasets = () => {
  const [availableDatasets, setAvailableDatasets] = useState([]);
  const [importedDatasets, setImportedDatasets] = useState([]);
  const [importingDatasets, setImportingDatasets] = useState({});
  const [newMode, setNewMode] = useState(true);
  const [clientName, setClientName] = useState(null);

  const { isAdmin } = useAdmin();

  useEffect(() => {
    getDatasets().then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          console.log("data", data);
          console.log("data.availableDatasets", data.availableDatasets);

          // Assuming 'CreateTime' is in a format that can be directly used in Date constructor
          const sortedDatasets = data.availableDatasets.sort(
            (a, b) => new Date(a.CreateTime) - new Date(b.CreateTime)
          );

          setAvailableDatasets(sortedDatasets);
          setImportedDatasets(data.importedDatasetsInMap);
        });
      }
    });
    getClientName().then((name) => {
      console.log("clientName", name);
      setClientName(name);
    });
    console.log("availableDatasets", availableDatasets);
    return () => {
      setAvailableDatasets([]);
    };
  }, []);

  const getAuthToken = async () => {
    const session = await fetchAuthSession();
    return session.tokens?.idToken?.toString();
  };

  async function getDatasets() {
    const authToken = await getAuthToken();
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(`${getApiUrl()}datasets`, requestOptions);
    console.log("response", response);
    return response;
  }

  async function importDataset(datasetName) {
    console.log("importDataset", datasetName);
    setImportingDatasets((prev) => ({ ...prev, [datasetName]: true }));
    if (!datasetName) {
      alert("Please enter a value");
      return;
    }
    // Your import logic here
    console.log(`Importing dataset with argument: ${datasetName}`);

    try {
      const authToken = await getAuthToken();

      // Setup the request options for a POST request
      const requestOptions = {
        method: "POST", // Specify the method as POST
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json", // Include this if you're sending a JSON payload in the body
        },
      };

      const response = await fetch(
        `${getApiUrl()}datasets/${datasetName}/onMap`,
        requestOptions
      );
      console.log("response", response);

      if (response.ok) {
      } else {
        alert("Error importing dataset");
      }

      return response;
    } catch (error) {
      throw new Error("error", error);
    } finally {
      // Any cleanup actions if necessary
    }
  }

  async function removeDatasetFromMap(datasetName) {
    const alertMessage = 
      "Attention, il faut rappeler que si le dataset a ete modifie, " +
      "par exemple en changeant le taxref via l'interface, " + 
      "les donnnes dans S3 ne sont peut etre pas a jour. " +
      "Les patchs appliques sont bien enregistres en theorie dans la zone patch du dataset (repertoire patch du dataset, dans le deposit), " +
      "mais cette fonctionalite de suivre et d'appliquer les patchs n'est pas encore implante." +
      "en resume, il est recommande de supprimer ce dataset uniquement is aucun patch n'est passe. (consulter S3 pour s'en assurer)";

    alert(alertMessage);
    if (
      !confirm(
        "Merci de confirmer apres avoir lu l'alerte ci-dessus, en cas d'hesitation, annuler."
      )
    ) {
      return;
    }

    console.log("removeDatasetFromMap", datasetName);
    const authToken = await getAuthToken();

    // Setup the request options for a POST request
    const requestOptions = {
      method: "DELETE", // Specify the method as DELETE
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json", // Include this if you're sending a JSON payload in the body
      },
    };

    const response = await fetch(
      `${getApiUrl()}datasets/${datasetName}/onMap`,
      requestOptions
    );
    console.log("response", response);

    if (response.ok) {
      window.location.reload();
    } else {
      alert("Error removing dataset");
    }

    return response;
  }

  function isDatasetAlreadyImported(datasetName) {
    return importedDatasets.includes(datasetName);
  }

  function convertUtcToParisTime(utcDate) {
    return new Date(utcDate).toLocaleString("en-GB", {
      timeZone: "Europe/Paris",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  function isDatasetNewModeFormatted(datasetName) {
    const newFormatRegex =
      /^[a-zA-Z0-9]+_[a-zA-Z0-9]+_\d{8}_\d{8}__version__\d+$/;
    return newFormatRegex.test(datasetName);
  }

  function isNewFormatDatasetForCurrentClient(datasetName) {
    const datasetClientName = datasetName.split("_")[0];
    console.log("clientName", clientName);
    console.log("datasetClientName", datasetClientName);
    return clientName === datasetClientName;
  }

  function shouldDisplayDataset(datasetName) {
    if (newMode) {
      return (
        isDatasetNewModeFormatted(datasetName) &&
        isNewFormatDatasetForCurrentClient(datasetName)
      );
    }
    return true;
  }

  //////////////////////////////////////////////
  const isSuperadmin = useSuperadmin();
  if (!isSuperadmin) {
    return (
      <div className="mainpage">
        <p>Vous n'êtes pas autorisé à accéder à cette page.</p>
      </div>
    );
  } else {
    return (
      <div className="mainpage">
        <div style={{ marginBottom: "20px" }}>
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              checked={newMode}
              onChange={() => setNewMode(!newMode)}
              style={{ marginRight: "10px" }}
            />
            New mode
          </label>
        </div>

        {availableDatasets.length === 0 ? (
          <p>No datasets available</p>
        ) : (
          <>
            <style>
              {`
                .list-item:hover {
                    background-color: #f0f0f0; 
                }
            `}
            </style>
            <b>Available datasets:</b>
            <ul>
              {availableDatasets.map((dataset) => (
                <li
                  key={dataset.Name}
                  className="list-item"
                  style={{
                    margin: "10px",
                    display: shouldDisplayDataset(dataset.Name)
                      ? "block"
                      : "none",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row", // Align children vertically
                      gap: "50px", // Add space between each child
                    }}
                  >
                    {isDatasetAlreadyImported(dataset.Name) ? (
                      <span style={{ fontWeight: "bolder" }}>
                        {dataset.Name}
                      </span>
                    ) : (
                      dataset.Name
                    )}
                    <span>{convertUtcToParisTime(dataset.CreateTime)}</span>
                    {isDatasetAlreadyImported(dataset.Name) && isAdmin ? (
                      <button
                        onClick={() => removeDatasetFromMap(dataset.Name)}
                      >
                        Supprimer de la carte
                      </button>
                    ) : null}
                    {!isDatasetAlreadyImported(dataset.Name) && (
                      <button
                        onClick={() => importDataset(dataset.Name)}
                        disabled={importingDatasets[dataset.Name]}
                      >
                        {importingDatasets[dataset.Name]
                          ? "en cours d'import..."
                          : "Importer"}
                      </button>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    );
  }
};

export default Datasets;
