import React, { useState, useEffect, useRef } from "react";
import ListItem from "./ListItem";

export default function List({ itemList, selectedCallback }) {

  return <div>

    {itemList.map((dateobj, i: number) => (
      <div key={dateobj.date}>
        <span >
          {dateobj.date}
        </span>
        <ul className="eventlist" >
          {dateobj.items.map((item, i: number) => (
            <li onClick={() => selectedCallback(item)} key={item.id} className={item.selected ? "" : "disabled"}>
              <ListItem item={item} />
            </li>
          ))
          }

        </ul>
        <hr />
      </div>
    ))
    }

  </div>
}


