import React, { useState, useEffect, useRef } from "react";
import ItemTable from "./ItemTable";
import Filters from "./Filters";
import {
  getItemCollection,
  getItemCollectionFetchMore,
} from "../dataProvider/itemProvider";
import Export from "./Export";

export default function TablePage() {
  const [itemCollection, setItemCollection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function filtersChangedCallback(filters) {
    console.log("filtersChangedCallback", filters);
    if (!filters) return;
    setIsLoading(true);
    getItemCollection(filters).then((itemCol) => {
      setItemCollection(itemCol);
      setIsLoading(false);
    });
  }

  async function loadMore() {
    setIsLoading(true);
    const newItemCol = await getItemCollectionFetchMore(itemCollection, 100);
    setItemCollection(newItemCol);
    setIsLoading(false);
  }

  function canDisplayMore() {
    if (!itemCollection) {
      return false;
    }
    return itemCollection.totalCount > itemCollection.count;
  }

  function isItemCollectionEmpty() {
    return !itemCollection || itemCollection.count === 0;
  }

  return (
    <>
      <div className="mainpage">
        <h2>Filtres:</h2>
        <Filters filtersChangedCallback={filtersChangedCallback} />

        <hr />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {itemCollection && <h2>{itemCollection.totalCount} Données:</h2>}
          <Export itemCollection={itemCollection} />
        </div>
        {!isItemCollectionEmpty() && (
          <ItemTable itemCollection={itemCollection} />
        )}

        <div style={{ display: "flex", justifyContent: "center" }}>
          {isLoading && <div className="loading-spinner"></div>}
          {canDisplayMore() && !isLoading && (
            <a className="btn btn-primary" onClick={loadMore}>
              Charger plus
            </a>
          )}
        </div>
        <br />
        <br />
      </div>
    </>
  );
}
