import React, { useState, useEffect, useRef } from "react";
import Item from "../dataProvider/item";
import Favorite from "../components/Favorite";
import {
  getPictoClassNameForType,
} from "../dataProvider/itemHelper";
import MediaPlayer from "../routes/MediaPlayer";
import { TinyItemThumbnail } from "../components/ItemThumbnail";

export default function ItemRow({ item }) {
  const popinRef = useRef(null);
  const [isPlayerVisible, setIsPlayerVisible] = useState(false);

  function closePlayer() {
    setIsPlayerVisible(false);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (popinRef.current && !popinRef.current.contains(event.target)) {
        closePlayer();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popinRef]);

  function goToDetail() {
    let url = "i?id=" + item.id;
    window.open(url, "_blank");
  }

  return (
    <>
      <div className="tablepagerow-container">
        <i className={getPictoClassNameForType(item.type)}></i>

        <TinyItemThumbnail item={item} />

        <span style={{ color: "var(--grey-dark)" }}>{item.getDate()}</span>

        <span>{item.title}</span>

        <Favorite item={item} />

        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            gap: "5px",
            paddingRight: "5px",
          }}
        >
          <a
            onClick={() => setIsPlayerVisible(true)}
            className="btn btn-icon bg-moccasin"
          >
            <i className="ei ei-play"></i>
          </a>
          <button onClick={goToDetail}>Details</button>
        </div>
      </div>

      {isPlayerVisible && (
        <div id="popin" ref={popinRef}>
          <MediaPlayer event={item} closePlayer={closePlayer} />
        </div>
      )}
    </>
  );
}
