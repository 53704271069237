import { useState, useEffect } from "react";
import { getUserName } from "../ConfigProvider";

const useAdmin = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [canBeAdmin, setCanBeAdmin] = useState(false);

  useEffect(() => {
    // Retrieve isAdmin value from sessionStorage on initial load
    const storedIsAdmin = sessionStorage.getItem("isAdmin");
    if (storedIsAdmin !== null) {
      setIsAdmin(storedIsAdmin === "true");
    }

    getUserName().then((userName) => {
      setCanBeAdmin(userName.startsWith("e1c_"));
    });
  }, []);

  const toggleAdmin = async () => {
    if (!canBeAdmin) {
      throw new Error("You are not allowed to be an admin");
    }

    setIsAdmin((prevIsAdmin) => {
      const newIsAdmin = !prevIsAdmin;
      sessionStorage.setItem("isAdmin", newIsAdmin.toString());
      return newIsAdmin;
    });
  };

  return { isAdmin, toggleAdmin, canBeAdmin };
};

export default useAdmin;
