import React, { useState, useEffect } from "react";
import Editable from "./Editable";
import { cloneDeep, isEqual } from "lodash-es";
import useAdmin from "../hooks/useAdmin";
import ButtonsEdit from "../components/ButtonsEdit";

function SensorDetail({
  sensor,
  saveCallback,
  selectOnMapCallback,
  selectedCoordsOnMap,
  deleteCallback,
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [editedSensor, setEditedSensor] = useState({});
  const [areInputValid, setareInputValid] = useState({});

  const { isAdmin } = useAdmin();

  function areAllInputValid() {
    const hasFalse = Object.values(areInputValid).some((v) => v === false);
    const allAreValid = !hasFalse;
    return allAreValid;
  }

  useEffect(() => {
    let copiedSensor = cloneDeep(sensor);
    setEditedSensor(copiedSensor);

    if (sensor.isnew) {
      setIsEdit(true);
    }
  }, [sensor]);

  useEffect(() => {
    if (!selectedCoordsOnMap) {
      return;
    }
    let copiedSensor = cloneDeep(editedSensor);
    copiedSensor.lat = selectedCoordsOnMap.lat;
    copiedSensor.lng = selectedCoordsOnMap.lng;

    setEditedSensor(copiedSensor);

    setIsEdit(true);
  }, [selectedCoordsOnMap]);

  function selectCoordsOnMap() {
    selectOnMapCallback();
  }

  const switchEdit = function () {
    const newModeIsEdit = !isEdit;

    if (newModeIsEdit) {
    } else {
      setEditedSensor(sensor);
    }

    setIsEdit(newModeIsEdit);
  };

  const editableChanged = function (
    key: string,
    value: string,
    isInputValid: boolean,
  ) {
    let areInputValidNew = areInputValid;
    areInputValidNew[key] = isInputValid;
    setareInputValid(areInputValidNew);


    const updatedSensor = { ...editedSensor, [key]: value };
    setEditedSensor(updatedSensor);
  };

  async function deleteSensor() {
    await deleteCallback(editedSensor);
  }

  async function save() {
    const haschanged = !isEqual(sensor, editedSensor);
    if (!haschanged) {
      setIsEdit(false);
      return;
    }
    await saveCallback(editedSensor);
  }

  return (
    <div className="card" style={{ textAlign: "center", minWidth: "500px" }}>
      <h2>
        <Editable
          value={editedSensor.name}
          isEdit={isEdit}
          type="text"
          keyy="name"
          changedCallback={editableChanged}
          allowSpace={true}
        />
      </h2>
      <br />
      <table className="sensorDetailTable">
        <tbody>
          <tr>
            <td>Type:</td>
            <td>
              <Editable
                value={editedSensor.type}
                isEdit={isEdit}
                type="dropdown"
                keyy="type"
                changedCallback={editableChanged}
              />
            </td>
          </tr>
          <tr>
            <td>Reference:</td>
            <td>
              <Editable
                value={editedSensor.ref}
                isEdit={isEdit}
                type="text"
                keyy="ref"
                changedCallback={editableChanged}
                allowCopy={true}
                onlyAlphanumeric={true}
              />
            </td>
          </tr>
          <tr>
            <td>
              <br />
              Latitude:
            </td>
            <td>
              <br />
              <Editable
                value={editedSensor.lat}
                isEdit={isEdit}
                type="text"
                keyy="lat"
                changedCallback={editableChanged}
                allowCopy={true}
                onlyNumbers={true}
              />
            </td>
          </tr>
          <tr>
            <td>Longitude:</td>
            <td>
              <Editable
                value={editedSensor.lng}
                isEdit={isEdit}
                type="text"
                keyy="lng"
                changedCallback={editableChanged}
                allowCopy={true}
                onlyNumbers={true}
              />
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              {isEdit && (
                <a href="#" onClick={selectCoordsOnMap}>
                  selectionner sur la carte
                </a>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <br />
              Date debut:
            </td>
            <td>
              <br />
              <Editable
                value={editedSensor.startdate}
                isEdit={isEdit}
                type="date"
                keyy="startdate"
                changedCallback={editableChanged}
              />
            </td>
          </tr>
          <tr>
            <td>Date fin:</td>
            <td>
              <Editable
                value={editedSensor.enddate}
                isEdit={isEdit}
                type="date"
                keyy="enddate"
                changedCallback={editableChanged}
              />
            </td>
          </tr>

          <tr>
            <td style={{verticalAlign: "top"}}>Commentaire:</td>
            <td>
              <Editable
                value={editedSensor.comment}
                isEdit={isEdit}
                type="multiline"
                keyy="comment"
                changedCallback={editableChanged}
                allowSpace={true}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      {isAdmin && (
        <ButtonsEdit
          isEdit={isEdit}
          editCallback={switchEdit}
          saveCallback={save}
          allowSave={areAllInputValid()}
          allowDelete={!sensor.isnew}
          deleteCallback={deleteSensor}
        />
      )}
    </div>
  );
}

export default SensorDetail;
