import { getItems, patchItem as patchItemApi } from "../OccurrencesApi";
import { getFirstPointOfGeo, isGeojsonPoint } from "../geoHelper";
import { ItemCollection } from "./itemCollection";
import Item from "./item";

let items = null;

let cache = {};
const useCache = false;

function getQsFromFilters(filters) {
  function getDataTypeQsFromFilters(filters) {
    if (!filters || !filters.options) {
      return "";
    }

    let qs = "datatype=";
    if (filters.options.displayGbif) {
      qs += "gbif,";
    }

    if (filters.options.displayInpn) {
      qs += "inpn,";
    }

    if (filters.options.displayCamera) {
      qs += "camera,";
    }

    if (filters.options.displaySound) {
      qs += "sound,";
    }
    if (filters.options.displayUltrason) {
      qs += "ultrason,";
    }

    if (filters.options.displayParcellaire) {
      qs += "parcellaire,";
    }

    if (filters.options.displayPratique) {
      qs += "pratique,";
    }

    if (filters.options.displaySol) {
      qs += "sol,";
    }

    // remove last character
    qs = qs.slice(0, -1);
    return qs;
  }

  let qs = "";
  qs += getDataTypeQsFromFilters(filters);

  if (filters.geojsontype) {
    qs += "&geojsontype=";
    qs += filters.geojsontype;
  }

  //?bbox=<west_longitude>,<south_latitude>,<east_longitude>,<north_latitude>
  if (filters.coordBox && filters.coordBox.west) {
    qs +=
      "&bbox=" +
      filters.coordBox.west +
      "," +
      filters.coordBox.south +
      "," +
      filters.coordBox.east +
      "," +
      filters.coordBox.north;
  }

  if (filters.dateBox && filters.dateBox.start && filters.dateBox.end) {
    const startUTC = filters.dateBox.start.toISOString();
    const endUTC = filters.dateBox.end.toISOString();
    qs += "&startDate=" + startUTC + "&endDate=" + endUTC;
  }

  if (filters.datasets) {
    let dsstr = "&datasets=";
    filters.datasets.forEach((f) => (dsstr += f + ","));
    dsstr = dsstr.slice(0, -1);
    qs += dsstr;
  }

  if (filters.titlecontains) {
    qs += "&titlecontains=" + filters.titlecontains;
  }

  if (filters.id) {
    qs += "&id=" + filters.id;
  }

  if (filters.favorite) {
    qs += "&properties.focuslevel=3";
  }

  return qs;
}

const getItemsNewVersion = async function (filters) {
  let qs = getQsFromFilters(filters);
  qs += "&count=20";
  qs += "&skip=0";

  if (useCache && cache[qs]) {
    return cache[qs];
  }

  items = await getItems(qs);
  if (!items) {
    return [];
  }

  if (useCache) {
    cache[qs] = items;
  }

  items.qs = qs;
  return items;
};

const getItemCollection = async function (filters) {
  let items = await getItemsNewVersion(filters);
  const count = items.count;
  const qs = items.qs;
  items = items.items;

  const itemCol = new ItemCollection(
    items.map((item) => new Item(item)),
    count,
    qs,
  );

  return itemCol;
};

const getItemCollectionFetchMore = async function (
  itemCollection,
  howManyMore,
) {
  if (itemCollection.qs === "") {
    throw new Error("No query string provided");
  }

  const loadedCount = itemCollection.count;
  const totalCount = itemCollection.totalCount;

  if (loadedCount == totalCount) {
    return itemCollection; // Returning a new instance with the same items if no new items are fetched
  }

  const qs = itemCollection.qs;

  let params = new URLSearchParams(qs);
  params.set("skip", loadedCount);
  params.set("count", howManyMore);
  let newQs = params.toString();

  let response = await getItems(newQs);
  if (!response || response.items.length === 0) {
    return new ItemCollection(
      itemCollection.items,
      itemCollection.totalCount,
      itemCollection.qs,
    ); // Returning a new instance with the same items if no new items are fetched
  }
  if (useCache) {
    cache[qs] = response;
  }

  const newItems = response.items.map((item) => new Item(item));

  const newItemCollection = new ItemCollection(
    [...itemCollection.itemList],
    itemCollection.totalCount,
    itemCollection.qs,
  );
  newItemCollection.addItems(newItems);

  return newItemCollection;
};

const getMarkers = async function (filters) {
  const items = await getItemsNewVersion(filters);
  const markers = items.markers;

  let ret = [];

  markers.forEach((marker) => {
    const geojson = JSON.parse(marker.geojson);
    const coordinates = getFirstPointOfGeo(geojson);

    // If the coordinate is not already used, add a new marker
    let newMarker = {
      title: "",
      type: marker.datatype,
      ispoint: isGeojsonPoint(geojson),
      geojson: geojson,
    };
    newMarker.latitude = coordinates[0];
    newMarker.longitude = coordinates[1];
    ret.push(newMarker);
  });
  return ret;
};

const getItemFromId = async function (id: string): Promise<Item> {
  if (!id) {
    return null;
  }

  const filters = { id: id };
  const icol = await getItemCollection(filters);

  if (icol.count == 0) {
    return null;
  }
  const ret = icol.itemList[0];
  return ret;
};

const patchItem = async function (item: Item, patch: any) {
  /*
  {
    "datasetName": "exampleDataset",
    "patch": {
      "itemid": "123",
      "action": "update",
      "data": {
        "title": "sanglier",
        "properties.classification.checkstatus": "tocheck",
      }
    }
  }
*/

  const resp = await patchItemApi(item.id, {
    datasetName: item.dataset,
    patch: {
      itemid: item.id,
      action: "update",
      data: patch,
    },
  });
  return resp;
};

export {
  getItemCollection,
  getMarkers,
  getItemCollectionFetchMore,
  getItemFromId,
  patchItem,
};
