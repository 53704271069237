import React, { useState, useEffect, useRef } from "react";
import { getItemCollectionFetchMore } from "../dataProvider/itemProvider";
import Popup from "../components/Popup";

export default function Export({ itemCollection }) {
  const [isDownloading, setisDownloading] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  async function getFullItemCollection() {
    setisDownloading(true);
    try {
      const eitemcol = await getItemCollectionFetchMore(
        itemCollection,
        10000000000
      );
      //todo: handle api error and display message(like too many stuff !!)
      setisDownloading(false);
      return eitemcol;
    } catch (error) {
      alert(
        "Erreur lors de l'exportation des données, veuillez réduire le nombre d'éléments a l'aide des filtres et réessayer. "
      );
      setisDownloading(false);
      throw error;
    }
  }

  function launchDownload(data, filename: string) {
    let typeOutput: string;
    let strOutput: string;

    filename = filename.toLowerCase();
    if (filename.endsWith(".geojson") || filename.endsWith(".json")) {
      typeOutput = "application/json";
      strOutput = JSON.stringify(data, null, 2);
    }

    if (filename.endsWith(".csv")) {
      typeOutput = "text/csv";
      strOutput = data;
    }

    const blob = new Blob([strOutput], { type: typeOutput });

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  function launchDownloadJson(json) {
    launchDownload(json, "data.geojson");
  }

  async function exportJson() {
    const eitemcol = await getFullItemCollection();
    launchDownloadJson(eitemcol.toJSON());
  }

  async function exportE1cGeojson() {
    const eitemcol = await getFullItemCollection();
    launchDownloadJson(eitemcol.toE1cGeojson());
  }

  async function exportStrictGeojson() {
    const eitemcol = await getFullItemCollection();
    launchDownloadJson(eitemcol.toStrictGeojson());
  }

  async function exportStrictFlatGeojson() {
    const eitemcol = await getFullItemCollection();
    launchDownloadJson(eitemcol.toStrictFlatGeojson());
  }

  async function exportCSV() {
    const eitemcol = await getFullItemCollection();
    launchDownload(eitemcol.toCSV(), "data.csv");
  }

  if (!itemCollection || itemCollection.totalCount == 0) {
    return <></>;
  }

  return (
    <>
      <div>
        <a className="btn btn-primary" onClick={togglePopup}>
          Exporter {itemCollection.totalCount} éléments
        </a>
        {isPopupOpen && (
          <Popup title="Exporter au format :" onClose={togglePopup}>
            Format naif representant la structure de données:
            <br />
            <button onClick={exportJson} disabled={isDownloading}>
              JSON
            </button>
            <br /> <br />
            Format Every1Count, utile pour modifier un jeu de données en vue
            d'un nouvel import:
            <br />
            <button onClick={exportE1cGeojson} disabled={isDownloading}>
              E1c GeoJSON
            </button>
            <br /> <br />
            Format GeoJSON strict, utile pour utiliser les données dans un autre
            outil (geojson.io): <br />
            <button onClick={exportStrictGeojson} disabled={isDownloading}>
              Strict GeoJSON
            </button>
            <br /> <br />
            Format GeoJSON strict avec propriétés plates, utile pour utiliser
            les données dans un autre outil (QGIS, etc.)
            <br />
            <button onClick={exportStrictFlatGeojson} disabled={isDownloading}>
              Strict flat GeoJSON
            </button>
            <br /> <br />
            Format CSV:
            <br />
            <button onClick={exportCSV} disabled={isDownloading}>
              CSV
            </button>
            <br /> <br />
          </Popup>
        )}
      </div>
    </>
  );
}
