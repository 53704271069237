import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { EventType } from "../api";
import MediaPlayer from "./MediaPlayer.jsx";
import EventListEvent from "./EventListEvent.tsx";

const EventList = forwardRef(
  (
    {
      itemCollection,
      selectedItemsCollection,
      selectEventCallBack,
      loadMoreEvents,
    },
    ref
  ) => {
    const [isPlayerVisible, setIsPlayerVisible] = useState(false);
    const [eventToPlay, setEventToPlay] = useState(null);

    const popinRef = useRef(null);
    const itemSelectedRef = useRef(null);
    const sentinelNotSelectedRef = useRef(null);
    const sentinelSelectedRef = useRef(null);
    const loadMoreEventsRef = useRef(loadMoreEvents);

    const previousSelectedItemsCollectionRef = useRef();

    useEffect(() => {
      loadMoreEventsRef.current = loadMoreEvents;
    }, [loadMoreEvents, itemCollection, selectedItemsCollection]);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Determine which sentinel triggered the intersection
              const isNotSelectedSentinel =
                entry.target === sentinelNotSelectedRef.current;
              const isSelectedSentinel =
                entry.target === sentinelSelectedRef.current;

              if (isNotSelectedSentinel) {
                // Pass `false` if the not-selected sentinel is intersecting
                loadMoreEventsRef.current(false);
              } else if (isSelectedSentinel) {
                // Pass `true` if the selected sentinel is intersecting
                loadMoreEventsRef.current(true);
              }
            }
          });
        },
        { threshold: 1.0 } // Trigger when any sentinel is fully in view
      );

      // Start observing both sentinels
      if (sentinelNotSelectedRef.current) {
        observer.observe(sentinelNotSelectedRef.current);
      }
      if (sentinelSelectedRef.current) {
        observer.observe(sentinelSelectedRef.current);
      }

      // Cleanup: Stop observing both sentinels
      return () => {
        if (sentinelNotSelectedRef.current) {
          observer.unobserve(sentinelNotSelectedRef.current);
        }
        if (sentinelSelectedRef.current) {
          observer.unobserve(sentinelSelectedRef.current);
        }
      };
    }, [selectedItemsCollection]);

    useEffect(() => {
      if (
        selectedItemsCollection.totalCount > 1 &&
        itemSelectedRef.current &&
        previousSelectedItemsCollectionRef.current.totalCount !=
          selectedItemsCollection.totalCount
      ) {
        // Scrolls the element into view
        itemSelectedRef.current.scrollIntoView({ block: "start" });
      }

      previousSelectedItemsCollectionRef.current = selectedItemsCollection;
    }, [selectedItemsCollection]);

    useEffect(() => {
      function handleClickOutside(event) {
        if (popinRef.current && !popinRef.current.contains(event.target)) {
          closePlayer();
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [popinRef]);

    function isEventSelected(e) {
      return selectedItemsCollection?.doesContainsEvent(e);
    }

    const play = (e) => {
      setEventToPlay(e);
      setIsPlayerVisible(true);
    };

    useImperativeHandle(ref, () => ({
      play, // Expose the play function to be accessible by the parent component
    }));

    function closePlayer() {
      setIsPlayerVisible(false);
      setEventToPlay(null);
    }

    function unselectAll() {
      selectEventCallBack(null);
    }

    return (
      <>
        <div ref={itemSelectedRef}>
          <h2>{itemCollection.totalCount} éléments</h2>

          <div>
            {/* Events  selectionnés*/}
            {selectedItemsCollection.totalCount > 0 && (
              <>
                <div className="itemlist-selected">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {selectedItemsCollection.totalCount} sélectionnés
                    <button
                      type="button"
                      className="btnclose"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={unselectAll}
                    />
                  </div>

                  {selectedItemsCollection.datesDistinct.map((date) => (
                    <React.Fragment key={date}>
                      <span className="date">{date}</span>
                      <ul key={date} className="eventlist">
                        {selectedItemsCollection
                          .getItemsForDate(date)
                          .map((e, i) => (
                            <EventListEvent
                              key={i}
                              e={e}
                              play={play}
                              selectEventCallBack={selectEventCallBack}
                              isSelected={isEventSelected(e)}
                            />
                          ))}
                      </ul>
                    </React.Fragment>
                  ))}
                  {/* Sentinel element for infinite scrolling */}
                  <div
                    ref={sentinelSelectedRef}
                    style={{ height: "1px" }}
                  ></div>
                </div>
                <br />
              </>
            )}

            {/* Events non selectionnés*/}

            {itemCollection &&
              itemCollection.datesDistinct &&
              itemCollection.datesDistinct.map((date) => (
                <React.Fragment key={date}>
                  <span className="date">{date}</span>
                  <ul key={date} className="eventlist">
                    {itemCollection.getItemsForDate(date).map(
                      (
                        e,
                        i //
                      ) => (
                        <EventListEvent
                          key={i}
                          e={e}
                          play={play}
                          selectEventCallBack={selectEventCallBack}
                          isSelected={isEventSelected(e)}
                        />
                      )
                    )}
                  </ul>
                </React.Fragment>
              ))}
            {/* Sentinel element for infinite scrolling */}
            <div ref={sentinelNotSelectedRef} style={{ height: "1px" }}></div>
          </div>
        </div>
        {isPlayerVisible && (
          <div id="popin" ref={popinRef}>
            <MediaPlayer event={eventToPlay} closePlayer={closePlayer} />
          </div>
        )}
      </>
    );
  }
);

export default EventList;
