import React from 'react';

const FilePlayer = ({ url }) => {
  const pdfJsViewerUrl = `https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(url)}&navpanes=0&view=FitH`;

  const openInNewTab = () => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%', backgroundColor: '#eeeeee' }}>
      <div style={{ width: '100%', flex: 1 }}>
        <object
          data={pdfJsViewerUrl}
          type="application/pdf"
          width="100%"
          height="100%"
        >
          <p>Your browser does not support viewing PDFs.
            <a href={pdfJsViewerUrl} target="_blank" rel="noopener noreferrer">Click here to view the PDF.</a>
          </p>
        </object>
      </div>
      <div style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', gap: '10px' }}>
        <button onClick={openInNewTab}>Ouvrir dans un nouvel onglet</button>
      </div>
    </div>
  );
};

export default FilePlayer;
