import React, { useState, useEffect, useRef } from "react";
import L from "leaflet";
import { getPropertyCoordinates, getPropertyBoundary } from "../ConfigProvider";
import List from "./List";
import dataprovider from "./dataprovider";

async function displayPropertyBoundaries(map) {
  try {
    const propertyBoundaries = await getPropertyBoundary();
    let propertyBoundariesGeoJSON = JSON.parse(propertyBoundaries);

    const propertyBoundaryLayer = L.geoJSON(propertyBoundariesGeoJSON, {
      style: {
        color: "#A3B75E",
        fillColor: "lightblue",
        fillOpacity: 0,
        interactive: false,
        weight: 5,
      },
    });
    propertyBoundaryLayer.addTo(map);
  } catch (error) {
    console.error("Error fetching property boundaries: ", error);
  }
}

function initMap(htmlId: string) {
  const defaultZoom = 15; // Adjust the default zoom level if needed
  const map = L.map(htmlId, { maxZoom: 19 }).setView(
    [44.8163158285053, -0.3834068326026641],
    defaultZoom,
  );
  const ignLayer = L.tileLayer(
    "https://wxs.ign.fr/pratique/geoportail/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/jpeg&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
    {
      maxZoom: 19,
      attribution: "&copy; IGN contributors",
    },
  );
  ignLayer.addTo(map);

  getPropertyCoordinates().then((coords) => {
    map.setView([coords.latitude, coords.longitude], defaultZoom);
  });

  displayPropertyBoundaries(map);

  return map;
}

async function getItemList() {
  return dataprovider.getSampleData();
}

export default function Demodsepage() {
  const mapref = useRef(null);
  const [itemList, setItemlist] = useState([]);

  useEffect(() => {
    if (!mapref.current) {
      mapref.current = initMap("map");
    }

    getItemList().then((ilist) => setItemlist(ilist));
  }, []);

  //pure!
  function foreachItemInItemList(ilist, f) {
    const newItemList = [...ilist];
    newItemList.forEach((dateobj) => {
      dateobj.items.forEach((item) => {
        f(item);
      });
    });
    return newItemList;
  }

  //pure!
  function selectItemWithId(ilist, id) {
    const newItemList = [...ilist];
    foreachItemInItemList(ilist, (i) => {
      if (i.id == id) {
        i.selected = true;
      }
    });
    return newItemList;
  }

  //pure
  function unselectAll(ilist) {
    let newItemList = [...ilist];
    newItemList = foreachItemInItemList(
      newItemList,
      (i) => (i.selected = false),
    );
    return newItemList;
  }

  function displayItem(item, map) {
    var coords = {
      type: "Polygon",
      coordinates: [
        [
          [-0.355957, 44.734785],
          [-0.355957, 44.695271],
          [-0.411473, 44.695271],
          [-0.411473, 44.734785],
          [-0.355957, 44.734785],
        ],
      ],
    };

    // Extracting the coordinates
    var topLeft = L.latLng(
      coords.coordinates[0][0][1],
      coords.coordinates[0][0][0],
    );
    var bottomRight = L.latLng(
      coords.coordinates[0][2][1],
      coords.coordinates[0][2][0],
    );
    var imageBounds = L.latLngBounds(topLeft, bottomRight);

    // Use the image URL from the item
    const imageUrl = item.file;
    L.imageOverlay(imageUrl, imageBounds).addTo(map);
  }

  function selectedCallback(item) {
    let ilist = [...itemList];
    ilist = unselectAll(ilist);
    ilist = selectItemWithId(ilist, item.id);
    setItemlist(ilist);
    displayItem(item, mapref.current);
  }

  return (
    <div className="content">
      <div className="nav">
        <div>
          <List itemList={itemList} selectedCallback={selectedCallback}></List>
        </div>
      </div>
      <div className="main">
        <div className="filter"></div>
        <div
          className="mapcontainer"
          style={{ display: "flex", height: "100%" }}
        >
          <div id="map" style={{ flex: "1" }}></div>
        </div>
      </div>
    </div>
  );
}
