import { getFileUrlNewVersion, getFileUrl } from "../FilesApi";
import { getFirstPointOfGeo } from "../geoHelper";

class Item {
  #epoch = 0;
  #latitude = 0;
  #longitude = 0;
  #uuid = null;
  #text = "";
  #title = "";
  #datatype = "";
  #data = null;
  #geojson = null;
  #medias = null;
  #dataset = null;
  #properties = {};

  #startdate = null;
  #enddate = null;

  cleanObjectKeys(obj) {
    const cleanedObj = {};
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        // Remove unwanted characters from the key
        const cleanedKey = key.replace(/`/g, "");
        cleanedObj[cleanedKey] = obj[key];
      }
    }
    return cleanedObj;
  }

  constructor(obj) {
    this.#epoch = obj.epoch;
    this.#latitude = obj.latitude;
    this.#longitude = obj.longitude;
    this.#uuid = obj.uuid;
    this.#text = obj.text;
    this.#title = obj.title;
    this.#properties = this.cleanObjectKeys(obj.properties);

    if (this.isRien()) {
      this.#title = "(rien)";
    }

    this.#data = obj.data;
    this.#medias = obj.medias;
    if (!this.#medias) {
      this.#medias = [];
    }

    this.#dataset = obj.dataset;

    if (!obj.id) {
      throw new Error("Item doesn't have an id");
    }
    this.#uuid = obj.id;

    if (!obj.startdate || !obj.enddate) {
      throw new Error("Item doesn't have dates");
    }

    this.#epoch = Date.parse(obj.startdate) / 1000;
    this.#startdate = obj.startdate;
    this.#enddate = obj.enddate;

    if (obj.geojson) {
      this.#geojson = JSON.parse(obj.geojson);
      const latlng = getFirstPointOfGeo(this.#geojson);
      this.#latitude = parseFloat(latlng[0].toFixed(6));
      this.#longitude = parseFloat(latlng[1].toFixed(6));
    } else {
      throw new Error("Item doesn't have geojson");
    }

    if (obj.type) {
      this.#datatype = obj.type;
    } else if (obj.datatype) {
      this.#datatype = obj.datatype;
    } else {
      throw new Error("type or datatype is mandatory");
    }
  }

  get properties() {
    return this.#properties;
  }

  get epoch() {
    return this.#epoch;
  }

  get data() {
    return this.#data;
  }

  get datatype() {
    if (!this.#datatype) {
      return null;
    }

    if (this.#datatype == "image" || this.#datatype == "video") {
      return "camera";
    }

    return this.#datatype;
  }
  get type() {
    return this.datatype;
  }

  // Getter for latitude
  get latitude() {
    return this.#latitude;
  }
  get geojson() {
    return this.#geojson;
  }

  // Getter for longitude
  get longitude() {
    return this.#longitude;
  }

  // Getter for uuid
  get uuid() {
    return this.#uuid;
  }

  get id() {
    return this.#uuid;
  }
  // Getter for text
  get text() {
    return this.#text;
  }

  get title() {
    // Check if the title is not empty
    if (this.#title && this.#title.length > 0) {
      // Uppercase the first character and add the rest of the string
      return this.#title.charAt(0).toUpperCase() + this.#title.slice(1);
    } else {
      // Return the original title if it's empty or undefined
      return this.#title;
    }
  }

  set title(title) {
    this.#title = title;
  }

  get medias() {
    return this.#medias;
  }

  isRien(): boolean {
    if (!this.#title || this.#title.length == 0) {
      return true;
    }
    return false;
  }

  isPoint(): boolean {
    return this.#geojson.type == "Point";
  }

  hasProperty(propertykey: string) {
    return propertykey in this.#properties;
  }

  getProperty(propertykey: string) {
    if (this.#properties.hasOwnProperty(propertykey)) {
      return this.#properties[propertykey];
    } else {
      return null;
    }
  }

  isNight() {
    if (!this.hasProperty("isnight")) {
      return null;
    }
    return this.getProperty("isnight") == "true";
  }

  hasNightProperty() {
    return this.hasProperty("isnight");
  }

  get dataset() {
    return this.#dataset;
  }

  get classification() {
    const ret = this.getProperty("classification");
    return ret;
  }

  get taxrefClassifier() {
    return this.classification?.taxrefClassifier;
  }

  get taxrefManual() {
    return this.classification?.taxrefManual;
  }

  /**
   * Return the manual taxref if it exists, otherwise return the classifier taxref.
   * If neither exists, return null.
   *
   * @returns {string|null} The manual taxref, classifier taxref, or null.
   */
  get taxref() {
    const classification = this.classification;
    if (classification) {
      if (this.taxrefManual) {
        return this.taxrefManual;
      } else if (this.taxrefClassifier) {
        return this.taxrefClassifier;
      }
    }
    return null;
  }

  get focuslevel() {
    if (!this.hasFocuslevelProperty()) {
      return 0; // neutral level
    }
    return this.getProperty("focuslevel");
  }

  hasFocuslevelProperty() {
    return this.hasProperty("focuslevel");
  }

  get isFavorite() {
    return this.focuslevel > 0;
  }

  //this considers only the startdate
  getDate() {
    const date = new Date(this.#epoch * 1000);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  getTime() {
    const date = new Date(this.#epoch * 1000);
    let hours = date.getHours();
    hours = hours < 10 ? "0" + hours : hours;
    let minutes = date.getMinutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}h${minutes}`;
  }

  getDurationString() {
    if (!this.#startdate || !this.#enddate) {
      return "Les dates ne sont pas toutes deux définies.";
    }

    const startDate = new Date(this.#startdate);
    const endDate = new Date(this.#enddate);

    const diff = Math.abs(endDate - startDate);
    if (diff == 0) {
      return "";
    }

    const millisecondsInSecond = 1000;
    const millisecondsInMinute = millisecondsInSecond * 60;
    const millisecondsInHour = millisecondsInMinute * 60;
    const millisecondsInDay = millisecondsInHour * 24;

    const days = Math.floor(diff / millisecondsInDay);
    const hours = Math.floor((diff % millisecondsInDay) / millisecondsInHour);
    const minutes = Math.floor(
      (diff % millisecondsInHour) / millisecondsInMinute
    );
    const seconds = Math.floor(
      (diff % millisecondsInMinute) / millisecondsInSecond
    );

    let durationString = "";

    if (days > 0) {
      durationString += `${days} jour${days > 1 ? "s" : ""}, `;
    }
    if (hours > 0) {
      durationString += `${hours} heure${hours > 1 ? "s" : ""}, `;
    }
    if (minutes > 0) {
      durationString += `${minutes} minute${minutes > 1 ? "s" : ""}, `;
    }
    if (seconds > 0 || durationString === "") {
      durationString += `${seconds} seconde${seconds > 1 ? "s" : ""}`;
    }
    return durationString.trim().replace(/,\s*$/, "");
  }

  async getMediasWithUrls() {
    const getMediaTypeFromFileName = (fileName: string) => {
      const parts = fileName.split(".");
      const extension = parts[parts.length - 1].toLowerCase();
      switch (extension) {
        case "mp3":
        case "wav":
          return "sound";
        case "jpg":
        case "jpeg":
        case "png":
          return "image";
        case "mp4":
          return "video";
        case "pdf":
          return "file";
        default:
          return "unknown";
      }
    };

    const fetchFileUrl = async (dataset, file) => {
      return getFileUrlNewVersion(dataset, file);
    };

    if (this.#data) {
      // old style
      const fetchUrlFunction = async () => this.getFirstFilePresignedUrl();
      return [
        {
          label: "",
          fetchUrl: fetchUrlFunction, // Provide a function to fetch the URL
          mediatype: this.#datatype,
        },
      ];
    } else {
      // new style
      return this.#medias.map((media) => ({
        label: media.label,
        fetchUrl: () => fetchFileUrl(this.#dataset, media.file), // Provide a function to fetch the URL
        mediatype: getMediaTypeFromFileName(media.file),
      }));
    }
  }

  async getFirstFilePresignedUrl() {
    if (this.#data) {
      return await getFileUrl(this.#data);
    }
    if (this.#medias) {
      const ret = await getFileUrlNewVersion(
        this.#dataset,
        this.#medias[0].file
      );
      return ret;
    }
    return null;
  }

  strToJsonIfNeeded(input) {
    if (!input) {
      return null;
    }

    try {
      // Check if the input is a string
      if (typeof input === "string") {
        try {
          // Try to parse the string as JSON
          input = JSON.parse(input);
        } catch (e) {
          // If parsing fails, return the string as is
          return input;
        }
      }

      if (typeof input === "object" && input !== null) {
        for (let key in input) {
          if (typeof input[key] === "string") {
            try {
              // Try to parse the string as JSON and set it to the key
              input[key] = this.strToJsonIfNeeded(input[key]);
            } catch (error) {
              // If parsing fails, leave the value as a string
            }
          } else if (typeof input[key] === "object") {
            // Recursively process sub-objects
            input[key] = this.strToJsonIfNeeded(input[key]);
          }
        }
      }

      return input;
    } catch (error) {
      console.error("Error processing input:", error);
      return null;
    }
  }

  toJSON() {
    let ret = {};
    ret.title = this.title;
    ret.id = this.#uuid;
    ret.text = this.text;
    ret.dataset = this.dataset;
    ret.medias = this.medias;
    ret.datatype = this.datatype;
    ret.properties = this.strToJsonIfNeeded(this.properties);
    ret.startdate = this.#startdate;
    ret.enddate = this.#enddate;
    ret.geojson = this.strToJsonIfNeeded(this.geojson);
    return ret;
  }

  toE1cGeojson() {
    let ret = {};
    ret.type = "Feature";
    ret.title = this.title;
    ret.text = this.text;
    ret.id = this.#uuid;
    ret.medias = this.medias;
    ret.datatype = this.datatype;
    ret.properties = this.strToJsonIfNeeded(this.properties);
    ret.startdate = this.#startdate;
    ret.enddate = this.#enddate;
    ret.geometry = this.strToJsonIfNeeded(this.geojson);
    return ret;
  }

  toStrictGeojson() {
    let ret = {};
    ret.type = "Feature";
    ret.properties = this.strToJsonIfNeeded(this.properties);
    ret.properties.id = this.#uuid;
    ret.properties.title = this.title;
    ret.properties.text = this.text;
    ret.properties.datatype = this.datatype;
    ret.properties.startdate = this.#startdate;
    ret.properties.enddate = this.#enddate;
    ret.geometry = this.strToJsonIfNeeded(this.geojson);
    return ret;
  }

  #flattenObject(obj, parentKey = "", separator = ".") {
    return Object.keys(obj).reduce((acc, key) => {
      const newKey = parentKey ? `${parentKey}${separator}${key}` : key;
      if (Array.isArray(obj[key])) {
        obj[key].forEach((item, index) => {
          if (typeof item === "object" && item !== null) {
            Object.assign(
              acc,
              this.#flattenObject(item, `${newKey}[${index}]`, separator)
            );
          } else {
            acc[`${newKey}[${index}]`] = item;
          }
        });
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        Object.assign(acc, this.#flattenObject(obj[key], newKey, separator));
      } else {
        acc[newKey] = obj[key];
      }
      return acc;
    }, {});
  }

  toStrictFlatGeojson() {
    let ret = this.toStrictGeojson();

    // Flatten properties
    const flatProperties = this.#flattenObject(ret.properties);
    ret.properties = flatProperties;

    return ret;
  }

  toKvpJsonWithCentroid() {
    const ec1geojson = this.toE1cGeojson();
    delete ec1geojson.medias;
    let flat = this.#flattenObject(ec1geojson);
    flat.latitude = this.latitude;
    flat.longitude = this.longitude;
    delete flat["geometry.coordinates[1]"];
    delete flat["geometry.coordinates[0]"];
    delete flat.type;

    return flat;
  }
}

export default Item;
