import { fetchAuthSession } from "aws-amplify/auth";
import { getApiUrl } from "./ConfigProvider";

const getAuthToken = async () => {
  const session = await fetchAuthSession();
  return session.tokens?.idToken?.toString();
};

const getClientName = async () => {
  const session = await fetchAuthSession();
  const userGroup = session.tokens?.accessToken?.payload["cognito:groups"];
  return userGroup;
};

const patchItem = async (itemid, patch) => {
  try {
    const authToken = await getAuthToken();

    const response = await fetch(`${getApiUrl()}items/${itemid}`, {
      method: "PATCH",
      headers: { Authorization: authToken, "Content-Type": "application/json" },
      body: JSON.stringify(patch),
    });

    if (!response.ok) {
      throw new Error("items : Network response was not ok");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw new Error("error");
  } finally {
  }
};

const getItems = async (querystring) => {
  try {
    const authToken = await getAuthToken();

    const response = await fetch(`${getApiUrl()}items?${querystring}`, {
      headers: { Authorization: authToken },
    });

    if (!response.ok) {
      throw new Error("items : Network response was not ok");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw new Error("error");
  } finally {
  }
};

const getAllOccurrences = async (
  setData = () => {},
  setIsLoading = () => {},
  setError = () => {},
) => {
  try {
    const authToken = await getAuthToken();

    const response = await fetch(`${getApiUrl()}occurrences`, {
      headers: { Authorization: authToken },
    });

    if (!response.ok) {
      throw new Error("getAllOccurrences : Network response was not ok");
    }

    const data = await response.json();
    setData(data);
    return data;
  } catch (error) {
    setError(error.message);
  } finally {
    setIsLoading(false);
  }
};

const postOccurrence = async (
  updateData,
  setIsLoading = () => {},
  setError = () => {},
) => {
  try {
    const authToken = await getAuthToken();

    const response = await fetch(`${getApiUrl()}occurrences`, {
      method: "POST",
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updateData),
    });

    if (!response.ok) {
      throw new Error("postOccurrence : Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    setError(error.message);
    throw error;
  } finally {
    setIsLoading(false);
  }
};

export {
  getAllOccurrences,
  postOccurrence,
  getClientName,
  getItems,
  patchItem,
};
