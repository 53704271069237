import React, { useState, useEffect } from "react";
import IsAdminPlaceHolder from "../components/IsAdminPlaceHolder";
import Popup from "../components/Popup";
import ModifyTaxRef from "./ModifyTaxRef";
import { patchItem } from "../dataProvider/itemProvider";

export default function EditButton({ item, onsave }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  function itemChangedCallback(patch) {
    patchItem(item, patch);
    if (patch.title) {
      item.title = patch.title;
      onsave(item);
    }
    togglePopup();
  }

  function itemCanceledCallback() {
    togglePopup();
  }

  return (
    <>
      <IsAdminPlaceHolder>
        <br />
        <button onClick={togglePopup}>Editer</button>
        <br />
      </IsAdminPlaceHolder>

      {isPopupOpen && (
        <Popup title={`Modifier ${item.title}`} onClose={togglePopup}>
          <ModifyTaxRef
            item={item}
            savedCallback={itemChangedCallback}
            canceledCallback={itemCanceledCallback}
          />
        </Popup>
      )}
    </>
  );
}
