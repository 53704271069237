
import React, { useState, useEffect } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';

function Home() {
  const [events, setEvents] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    navigate('/map');
  }, [navigate]);

  return (
    <div>
      <img src='homeImage.jpg' width='100%' />
      <br />
      {events.map(event => <div key={event.uuid}>{event.uuid}</div>)}
    </div>
  );
}

export default Home;
