import React, { useState, useEffect } from "react";
import Checkbox from "../components/Checkbox";

export default function DataFilter({ titleChanged, favoriteChanged }) {
  const [title, setTitle] = useState("");
  const [favorite, setFavorite] = useState(false);

  // Delay for debouncing (in milliseconds)
  const debounceDelay = 300;

  useEffect(() => {
    // Set a timeout to delay titleChanged until user stops typing
    const handler = setTimeout(() => {
      titleChanged(title);
    }, debounceDelay);

    // Clear the timeout if the title changes again before debounceDelay
    return () => clearTimeout(handler);
  }, [title]); // Remove titleChanged from dependencies

  function onTitleChanged(e) {
    setTitle(e.target.value);
  }

  function onFavoriteChanged() {
    const newFavorite = !favorite;
    setFavorite(newFavorite);
    favoriteChanged(newFavorite);
  }

  return (
    <>
      <ul className="switch">
        <Checkbox
          label="Afficher uniquement les ⭐"
          checked={favorite}
          onChange={onFavoriteChanged}
          name="favorite"
        />
        <hr />
      </ul>
      Titre :&nbsp;
      <input type="text" value={title} onChange={onTitleChanged} />
    </>
  );
}
