import React, { useState, useEffect } from "react";
import FilePlayer from "../routes/FilePlayer";

export default function ItemPageMediaPlayer({ item }) {
  const [medias, setMedias] = useState([]);

  useEffect(() => {
    const fetchMedias = async () => {
      const mediasWithFetchUrls = await item.getMediasWithUrls();
      //call fetchUrl for each medias and store the result for each result in a new url field
      for (const media of mediasWithFetchUrls) {
        media.url = await media.fetchUrl();
      }
      setMedias(mediasWithFetchUrls);
    };
    fetchMedias();
  }, [item]);
  ////////////////////////////////

  function renderMedia(media) {
    const url = media.url;
    switch (media.mediatype) {
      case "sound":
      case "ultrason":
        return (
          <audio controls src={url}>
            Your browser does not support the audio element.
          </audio>
        );
      case "image":
        return (
          <img
            src={url}
            alt={media.label}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        );
      case "video":
        return (
          <video
            src={url}
            controls
            onError={(e) => console.error("Video error:", e)}
            style={{ objectFit: "contain", width: "100%", height: "100%" }}
          />
        );
      case "file":
        return (
          <div style={{ height: "600px" }}>
            <FilePlayer key={url} url={url} />
          </div>
        );
      default:
        return <p key={url}>Unsupported media type</p>;
    }
  }

  return (
    <>
      {medias.map((media, i) => (
        <React.Fragment key={i}>
          <div
            style={{
              maxWidth: "100%",
              textAlign: "center",
              paddingRight: "20px",
            }}
          >
            {media.label && <h2>{media.label}</h2>}
            {renderMedia(media)}
          </div>
          <br />
        </React.Fragment>
      ))}
    </>
  );
}
