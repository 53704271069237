import React, { useState, useEffect, useRef } from "react";
import "./dropdownlist.css";

const Dropdownlist = ({ title, children, onSelect }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const popindd = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popindd.current && !popindd.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  function handleSelect(key) {
    setDropdownVisible(false);
    if (onSelect) {
      onSelect(key);
    }
  }

  return (
    <div ref={popindd} className="dropdownlist">
      <a
        className="btn btn-primary"
        onClick={() => setDropdownVisible(!dropdownVisible)}
      >
        <span>{title}</span>
      </a>
      <div style={{ display: dropdownVisible ? "block" : "none" }}>
        <ul>
          {children.map((child, index) => (
            <li
              key={child.key || index}
              onClick={() => {
                handleSelect(child.key);
              }}
            >
              <span className="btn btn-icon btn-noborder mr-1">{child.value}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Dropdownlist;
