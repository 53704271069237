import React, { useEffect, useState, useRef } from "react";

const ImagePlayer = ({ url }) => {
  const imageRef = useRef(null);

  const handleFullScreen = () => {
    if (imageRef.current) {
      if (imageRef.current.requestFullscreen) {
        imageRef.current.requestFullscreen();
      } else if (imageRef.current.mozRequestFullScreen) {
        /* Firefox */
        imageRef.current.mozRequestFullScreen();
      } else if (imageRef.current.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        imageRef.current.webkitRequestFullscreen();
      } else if (imageRef.current.msRequestFullscreen) {
        /* IE/Edge */
      }
    }
  };

  if (!url) {
    return <div>Loading image...</div>;
  }

  return (
    <div className="media-wrap" style={{ position: "relative" }}>
      <img ref={imageRef} src={url} style={{ display: "block" }} />
      <span
        onClick={handleFullScreen}
        style={{
          alignSelf: "flex-end",
          color: "white",
          position: "absolute",
          right: "0",
          bottom: "0",
          padding: "10px",
          cursor: "pointer",
          fontSize: "14px",
          transition: "transform 0.3s ease", // Add transition for the transform property
        }}
        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.2)")}
        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
      >
        <i className="fas fa-expand"></i>
      </span>
    </div>
  );
};

export default ImagePlayer;
