import { getFileUrl } from "./FilesApi";

export const EventType = Object.freeze({
  IMAGE: "image",
  SOUND: "sound",
  ULTRASON: "ultrason",
  VIDEO: "video",
  GBIF: "gbif",
  INPN: "inpn",
  PRATIQUE: "pratique",
  FAUNA: "fauna",
  CAMERA: "camera",
  SOL: "sol",
});

export function getIconUrl(e) {
  if (e.type == EventType.SOL) return "adn.png";
  if (e.type == EventType.IMAGE) return "image.png";
  if (e.type == EventType.SOUND) return "sound.png";
  if (e.type == EventType.ULTRASON) return "ultrason.png";
  if (e.type == EventType.VIDEO) return "video.png";
  return "error";
}

export async function getPresignedUrl(fileName) {
  return await getFileUrl(fileName);
}
