import React, { useEffect, useState } from "react";
import { getClientList } from "../ClientUtils";
import { getSensorsAsAdmin } from "../../dataProvider/sensorProviders";
import Sensor from "../../sensorPage/Sensor";

const MissingSensorDetector = function({inventaireCapteursList}) {

    const choisir_string = "--choisir--";

    const [client, setClient] = useState(choisir_string);
    const [clientSensors, setClientSensors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    function filterSensorsGetOnlyPhysicals(sensors: Sensor[]) {
        return sensors.filter(s => s.isPhysical());
    }

    function getInventaireForClient(client: string) {
        return inventaireCapteursList.filter(s => s.client === client);
    }

    function getClientSensorsOnlyActives() {
        return clientSensors.filter(s => s.isActif());
    }

    function getClientSensorsOnlyArchived() {
        return clientSensors.filter(s => s.isArchived());
    }

    function getClientSensorsOnlyFutures() {
        return clientSensors.filter(s => s.isFuture());
    }

    function isSensorInInventaire(sensor: Sensor) {
        const clientInventaire = getInventaireForClient(client);
        return clientInventaire.find(s => s.sensorRef === sensor.ref);
    }

    function getSensorsOnlyActivesInSensorsAndInInventaire() {
        return getClientSensorsOnlyActives().filter(s => isSensorInInventaire(s));
    }
    function getSensorsOnlyActivesNotInInventaire() {
        return getClientSensorsOnlyActives().filter(s => !isSensorInInventaire(s));
    }

    function hasSensorsOnlyActivesInSensorsAndInInventaire() {
        return getSensorsOnlyActivesInSensorsAndInInventaire().length > 0;
    }

    function areAllSensorsActivesInInventaire() {
        return getSensorsOnlyActivesInSensorsAndInInventaire().length === getClientSensorsOnlyActives().length;
    }
    function hasSensorsOnlyActivesNotInInventaire() {
        return getSensorsOnlyActivesNotInInventaire().length > 0;
    }

    function isChoisirSelected() {
        return client === choisir_string;
    }

    function setClientSensorsOnlyPhysicals(sensors: Sensor[]) {
        setClientSensors(filterSensorsGetOnlyPhysicals(sensors));
    }

    useEffect(() => {
        if (client !== choisir_string) {
            setIsLoading(true);
            getSensorsAsAdmin(client).then(sensors => {
                if(sensors) {
                    setClientSensorsOnlyPhysicals(sensors);
                }
                else {
                    setClientSensorsOnlyPhysicals([]);
                }
                setIsLoading(false);
            });
        }
        else {
            setClientSensorsOnlyPhysicals([]);
        }
    }, [client]);

    return (<div>
        <h2>Capteurs manquants</h2>
        <div>
            Choisir un client :
            <select onChange={e => setClient(e.target.value)}>
                <option value={choisir_string}>{choisir_string}</option>
                {getClientList().map(client => <option key={client} value={client}>{client}</option>)}
            </select>
        </div>
        <br />
        {isLoading && <div>
            <i className="fa-solid fa-spinner fa-spin" ></i>
        </div>}
        {!isChoisirSelected() && <>
            <span>{getClientSensorsOnlyActives().length} actifs. {getClientSensorsOnlyArchived().length} archivés. {getClientSensorsOnlyFutures().length} futurs. </span>
            <br />
            <br />
            {!isLoading && hasSensorsOnlyActivesNotInInventaire() && <div>
                <span style={{color: "red",fontWeight: "bold"}}>{getSensorsOnlyActivesNotInInventaire().length} capteurs actifs (page capteurs) mais n'apparaissent pas dans l'inventaire (ici) :</span>
                <ul>
                    {getSensorsOnlyActivesNotInInventaire().map(sensor => <li key={sensor.ref}>- {sensor.ref}</li>)}
                </ul>
            </div>}
            {!isLoading && areAllSensorsActivesInInventaire() && <div>
                <span style={{color: "green",fontWeight: "bold"}}>Tous les capteurs actifs sont dans l'inventaire</span>
                <br />
            </div>}
        {!isLoading && <div>
            <br />
            <span style={{fontWeight: "bold"}}>{getSensorsOnlyActivesInSensorsAndInInventaire().length} capteurs actifs dans la page capteurs sont referencés dans l'inventaire :</span>
            <br />
            ({getSensorsOnlyActivesInSensorsAndInInventaire().map(s => s.ref).join(", ")})
        </div>}
        {!isLoading && <div>
            <br />
            <span style={{fontWeight: "bold"}}>{getClientSensorsOnlyArchived().length} capteurs archivés dans la page capteurs:</span>
            <br />
            ({getClientSensorsOnlyArchived().map(s => s.ref).join(", ")})
        </div>}
        {!isLoading && !isChoisirSelected() && <div>
            <br />
            <span style={{fontWeight: "bold"}}>{getClientSensorsOnlyFutures().length} capteurs futurs dans la page capteurs:</span>
            <br />
            ({getClientSensorsOnlyFutures().map(s => s.ref).join(", ")})
            </div>}
        </>}
        <br />
        <br />
        <br />
        <br />
    </div>);
}

export default MissingSensorDetector;