import React from "react";
import PolygonEditor from "./polygonEditor";

function SitePage() {
  return (
    <div className="mainpage">
      <h2>Polygon Editor</h2>
      <div style={{ width: "800px", height: "500px" }}>
        <PolygonEditor />
      </div>
    </div>
  );
}

export default SitePage;
