import { EventType } from "./api";

export function getRandomColorFromGeojson(geojson) {
  function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  const str = JSON.stringify(geojson); // Convert GeoJSON to string
  const hash = hashCode(str);
  const color = "#" + Math.abs(hash).toString(16).substr(0, 6);
  return color.padEnd(7, "0"); // Ensure the string has length 7
}

export function getTilelayerUrl(layertoUse="openstreetmap") {
  if (layertoUse == "esri") {
    return "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
  }
  if (layertoUse == "ign") {
    return "https://wxs.ign.fr/pratique/geoportail/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/jpeg&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}"
  }
  if (layertoUse == "openstreetmap") {
    return "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  }
  throw "unknown layer to use:" + layertoUse
}

export function getMapIconFromitemType(itemType) {
  if (
    itemType == EventType.FAUNA ||
    itemType == EventType.GBIF ||
    itemType == EventType.INPN
  ) {
    return "opendata.png";
  }

  if (itemType == EventType.CAMERA) {
    return "image.png";
  }

  if (itemType == EventType.SOL) {
    return "adn.png";
  }

  return itemType + ".png";
}
