import useAdmin from "../../hooks/useAdmin";
import React, { useEffect, useState } from "react";
import AddSensorPopup from "./AddSensorPopup";
import {
  getInventaireCapteurs,
  setInventaireCapteurs,
} from "../../ConfigProvider";
import MissingSensorDetector from "./MissingSensorDetector";
import { getClientList } from "../ClientUtils";

function computePrixTTC(prixHT, tva) {
  if (!prixHT || !tva) {
    return "";
  }
  return Number(prixHT) + Number(tva);
}

const SensorLine = function ({
  sensor,
  editSensorCallback,
  ddFournisseur,
  ddModele,
  ddProprietaire,
  filtersState,
}) {

  function getPrixTTCdisplayValue(prixHT, tva) {
    const prixTTC = computePrixTTC(prixHT, tva);
    if (!prixTTC) {
      return "";
    }
    return prixTTC + " €";
  }
  function getTvaDisplayValue(tva) {
    if (!tva) {
      return "";
    }
    return tva + " €";
  }
  function getPrixHTdisplayValue(prixHT) {
    if (!prixHT) {
      return "";
    }
    return prixHT + " €";
  }

  return (
    <tr key={sensor.sensorId}>
      <td style={{ border: "1px solid black", padding: "2px" }}>
        {sensor.sensorId}
      </td>
      <td style={{ border: "1px solid black", padding: "2px" }}>
        {sensor.status}
      </td>
      <td style={{ border: "1px solid black", padding: "2px" }}>
        {sensor.statusDetails}
      </td>
      <td style={{ border: "1px solid black", padding: "2px" }}>
        {sensor.client}
      </td>
      <td style={{ border: "1px solid black", padding: "2px" }}>
        {sensor.sensorRef}
      </td>

      {filtersState.donneesFinancieres && (
        <>
          <td style={{ border: "1px solid black", padding: "2px" }}>
            {ddFournisseur.find((f) => f.key === sensor.fournisseur)?.value}
          </td>
          <td style={{ border: "1px solid black", padding: "2px" }}>
            {ddModele.find((m) => m.key === sensor.modele)?.value}
          </td>
          <td style={{ border: "1px solid black", padding: "2px" }}>
            {ddProprietaire.find((p) => p.key === sensor.proprietaire)?.value}
          </td>
          <td style={{ border: "1px solid black", padding: "2px" }}>
            {sensor.dateAchat}
          </td>
          <td style={{ border: "1px solid black", padding: "2px" }}>
            {getPrixHTdisplayValue(sensor.prix)}
          </td>
          <td style={{ border: "1px solid black", padding: "2px" }}>
            {getTvaDisplayValue(sensor.tva)}
          </td>
          <td style={{ border: "1px solid black", padding: "2px" }}>
            {getPrixTTCdisplayValue(sensor.prix, sensor.tva)}
          </td>
        </>
      )}
      <td
        style={{
          border: "1px solid black",
          padding: "2px",
          fontWeight: "bold",
        }}
      >
        <i
          className="fa fa-edit"
          aria-hidden="true"
          style={{ cursor: "pointer" }}
          onClick={() => editSensorCallback(sensor)}
        ></i>
      </td>
    </tr>
  );
};

const SensorsTable = function ({
  sensors,
  editSensorCallback,
  ddFournisseur,
  ddModele,
  ddProprietaire,
  filtersState,
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingRight: "10px",
      }}
    >
      <table style={{ width: "100%", textAlign: "center" }}>
        <thead>
          <style>
            {`
                tr:hover {
                    background-color: #f0f0f0; 
                }
            `}
          </style>
          <tr>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              ID
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              Status
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              Status detail
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              Client
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              Ref
            </th>

            {filtersState.donneesFinancieres && (
              <>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Fournisseur
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Modele
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Proprietaire
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Date achat
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Prix HT
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  TVA
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Prix TTC
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {sensors.map((sensor) =>
            SensorLine({
              sensor,
              editSensorCallback,
              ddFournisseur,
              ddModele,
              ddProprietaire,
              filtersState,
            })
          )}
          {filtersState.donneesFinancieres && (
            <tr>
              <td
                colSpan={9}
                style={{
                  padding: "2px",
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >
                Sommes:
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "2px",
                  fontWeight: "bold",
                }}
              >
                {(sensors.reduce((acc, s) => {
                    return acc + Number(s.prix);
                  }, 0)
                ).toFixed(2)}
                €
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "2px",
                  fontWeight: "bold",
                }}
              >
                {(sensors.reduce((acc, s) => {
                    return acc + Number(s.tva);
                  }, 0)
                ).toFixed(2)}
                €
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "2px",
                  fontWeight: "bold",
                }}
              >
                {(sensors.reduce((acc, s) => {
                    return acc + Number(computePrixTTC(s.prix, s.tva));
                  }, 0)
                ).toFixed(2)}
                €
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
const Filters = function ({ filtersState, filtersChangeCallback }) {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          gap: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <span style={{ marginBottom: "3px", fontWeight: "bold" }}>
            Clients:
            <i
              className="fa-solid fa-arrow-right-arrow-left"
              style={{ cursor: "pointer", marginLeft: "10px" }}
              onClick={() => filtersChangeCallback("clients", "invert")}
            ></i>
          </span>

          {filtersState.clients.map((client) => (
            <div style={{ cursor: "pointer" }} key={client.key}>
              <input
                id={`client-${client.key}`}
                type="checkbox"
                checked={client.value}
                onChange={(e) =>
                  filtersChangeCallback("clients", client.key, e.target.checked)
                }
              />
              <label
                style={{ cursor: "pointer" }}
                htmlFor={`client-${client.key}`}
              >
                {client.key}
              </label>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <span style={{ marginBottom: "3px", fontWeight: "bold" }}>
            Status:
            <i
              className="fa-solid fa-arrow-right-arrow-left"
              style={{ cursor: "pointer", marginLeft: "10px" }}
              onClick={() => filtersChangeCallback("status", "invert")}
            ></i>
          </span>
          {filtersState.status.map((status) => (
            <div style={{ cursor: "pointer" }} key={status.key}>
              <input
                id={`status-${status.key}`}
                type="checkbox"
                checked={status.value}
                onChange={(e) =>
                  filtersChangeCallback("status", status.key, e.target.checked)
                }
              />
              <label
                style={{ cursor: "pointer" }}
                htmlFor={`status-${status.key}`}
              >
                {status.key}
              </label>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <label
            htmlFor="donneesFinancieres"
            style={{
              marginBottom: "3px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Données financières:
          </label>
          <input
            id="donneesFinancieres"
            type="checkbox"
            checked={filtersState.donneesFinancieres}
            onChange={(e) =>
              filtersChangeCallback("donneesFinancieres", e.target.checked)
            }
          />
        </div>
      </div>
    </>
  );
};
///////////////////////////////////////////////////////////////////
const InventaireCapteurs = () => {
  const { canBeAdmin } = useAdmin();
  const [showAddSensorPopup, setShowAddSensorPopup] = useState(false);
  const [sensorToEdit, setSensorToEdit] = useState(null);
  const [sensors, setSensors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filtersState, setFiltersState] = useState(getInitialFiltersState());

  function toto() {
    console.log("toto");
    const tab = [1, 2, 3];
  }

  function getInitialFiltersState() {
    let clients = getClientList().map((c) => ({ key: c, value: true }));
    clients.unshift({ key: "", value: true });
    return {
      clients: clients,
      status: [
        { key: "en_stock", value: true },
        { key: "installé", value: true },
        { key: "hors_service", value: true },
        { key: "perdu", value: true },
        { key: "autre", value: true },
      ],
      donneesFinancieres: true,
    };
  }

  function filtersChangeCallback(filterKey, newValue) {
    if (newValue === "invert") {
      newValue = filtersState[filterKey].map((c) => ({
        ...c,
        value: !c.value,
      }));
      setFiltersState({ ...filtersState, [filterKey]: newValue });
      return;
    }

    let newFiltersState = { ...filtersState };
    if (filterKey === "donneesFinancieres") {
      newFiltersState = { ...filtersState, donneesFinancieres: newValue };
    } else if (filterKey === "clients") {
      newFiltersState.clients.find((c) => c.key === newValue).value =
        !newFiltersState.clients.find((c) => c.key === newValue).value;
    } else if (filterKey === "status") {
      newFiltersState.status.find((s) => s.key === newValue).value =
        !newFiltersState.status.find((s) => s.key === newValue).value;
    }
    setFiltersState(newFiltersState);
  }

  async function fetchSensors() {
    setIsLoading(true);
    const sensors = await getInventaireCapteurs();
    if (!sensors) {
      setSensors([]);
    } else {
      const sensorsJson = JSON.parse(sensors);
      setSensors(sensorsJson);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchSensors();
  }, []);

  function getNewSensor() {
    return {
      isnew: true,
      sensorId: "",
      fournisseur: getDdFournisseur()[0].key,
      modele: getDdModele()[0].key,
      proprietaire: getDdProprietaire()[0].key,
      dateAchat: "",
      prix: "",
      tva: "",
      status: "en_stock",
    };
  }

  function newSensorClick() {
    setSensorToEdit(getNewSensor());
    setShowAddSensorPopup(true);
  }

  async function saveSensor(sensor) {
    let sensorsNew = sensors;
    if (sensor.isnew) {
      const doesIdAlreadyExist = sensorsNew.find(
        (s) => s.sensorId === sensor.sensorId
      );
      if (doesIdAlreadyExist) {
        alert(
          "Un capteur avec le sensorId " + sensor.sensorId + " existe déjà"
        );
        return;
      }
      delete sensor.isnew;
      sensorsNew.push(sensor);
    }
    //update the sensors array
    //find the index of the sensor to update
    const indexToUpdate = sensorsNew.findIndex(
      (s) => s.sensorId === sensor.sensorId
    );
    if (indexToUpdate >= 0) {
      sensorsNew[indexToUpdate] = sensor;
    }

    setShowAddSensorPopup(false);
    setIsLoading(true);
    await setInventaireCapteurs(sensorsNew);
    await fetchSensors();
    setIsLoading(false);
  }

  function editSensor(sensor) {
    console.log("editSensor", sensor);
    setSensorToEdit(sensor);

    setShowAddSensorPopup(true);
  }

  function getDdFournisseur() {
    return [
      { key: "ceyomur", value: "CEYOMUR" },
      { key: "labmaker", value: "LABMAKER" },
      { key: "wildlifeacoustics", value: "WILDLIFE ACOUSTICS" },
    ];
  }

  function getDdModele() {
    return [
      { key: "cy95", value: "CY95" },
      { key: "audiomoth", value: "AUDIOMOTH" },
      { key: "songmetermicro1", value: "SONGMETER MICRO1" },
      { key: "songmetermicro2", value: "SONGMETER MICRO2" },
    ];
  }

  function getDdProprietaire() {
    return [
      { key: "every1counts", value: "Every1Counts" },
      { key: "biophonia", value: "Biophonia" },
      { key: "louisedelavalliere", value: "Louise de la Vallière" },
    ];
  }

  function getFilteredSensors() {
    let filteredSensors = null;
    if (!sensors || !filtersState) {
      return sensors;
    }

    filteredSensors = sensors.filter((s) => {
      const clientFilter = filtersState.clients.find((c) => c.key === s.client);
      return clientFilter ? clientFilter.value : false;
    });

    filteredSensors = filteredSensors.filter((s) => {
      const statusFilter = filtersState.status.find(
        (st) => st.key === s.status
      );
      console.log(s.status, statusFilter);
      console.log(statusFilter);
      return statusFilter ? statusFilter.value : false;
    });

    return filteredSensors;
  }

  ////////////////////////////////////////////////////////////
  if (!canBeAdmin) {
    return (
      <div className="mainpage">
        <p>Vous n'êtes pas autorisé à accéder à cette page.</p>
      </div>
    );
  }

  return (
    <div className="mainpage">
      {isLoading && (
        <p>
          Chargement des capteurs...
          <i className="fa-solid fa-spinner fa-spin"></i>
        </p>
      )}
      {!isLoading && (
        <div style={{ textAlign: "center", padding: "10px" }}>
          <Filters
            filtersState={filtersState}
            filtersChangeCallback={filtersChangeCallback}
          />
          <h2>Inventaire des capteurs</h2>
          <span style={{ fontWeight: "bold" }}>
            {getFilteredSensors().length}
          </span>{" "}
          capteurs affichés:
          <br />
          <br />
          <SensorsTable
            sensors={getFilteredSensors()}
            editSensorCallback={editSensor}
            ddFournisseur={getDdFournisseur()}
            ddModele={getDdModele()}
            ddProprietaire={getDdProprietaire()}
            filtersState={filtersState}
          />
          <div>
            <button
              style={{
                padding: "5px 10px",
                fontSize: "16px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginTop: "30px",
              }}
              onClick={() => newSensorClick()}
            >
              Ajouter un capteur
            </button>
            {showAddSensorPopup && (
              <AddSensorPopup
                sensor={sensorToEdit}
                onCancel={() => setShowAddSensorPopup(false)}
                onSave={saveSensor}
                ddFournisseur={getDdFournisseur()}
                ddModele={getDdModele()}
                ddProprietaire={getDdProprietaire()}
              />
            )}
          </div>
        </div>
      )}
      <hr />
      <MissingSensorDetector inventaireCapteursList={sensors} />
    </div>
  );
};

export default InventaireCapteurs;
