import React, { useEffect } from "react";
import InputMask from "react-input-mask";
import CopyButton  from "../components/CopyButton";
import { useState } from "react";

function Editable(
  { value,
    isEdit,
    type,
    keyy,
    changedCallback,
    allowCopy = false,
    allowSpace = false,
    onlyNumbers = false,
    onlyAlphanumeric = false,
    forbidEmpty = false,
    dropdownData = []
  }) {
  const [error, seterror] = useState("");

  useEffect(() => {
    // at init some values can be invalid, for instance empty values when forbidEmpty is true
    displayErrorIfNeeded(value);
  }, [value]);

  function isDateType() {
    return type == "date";
  }

  function isDropdownType() {
    return type == "dropdown";
  }

  function isMultilineType() {
    return type == "multiline";
  }

  function getErrorString(val: string): string {

    if(forbidEmpty && (!val || val.length == 0)){
      return "Le champ ne doit pas être vide.";
    }
    if(!val){
      return null;
    }

    if(!allowSpace && typeof val === 'string'){
      if(val.includes(" ")){
        return "Le champ ne doit pas contenir d'espace";
      }
    }

    if(onlyNumbers){
      if(isNaN(Number(val))){
        return "Le champ ne doit contenir que des chiffres.\n Pour les decimaux, utiliser un point (.) comme séparateur.";
      }
    }


    if(onlyAlphanumeric && val.length > 0){
      if(!val.match(/^[a-zA-Z0-9]+$/)){
        return "Le champ ne doit contenir que des chiffres et des lettres.";
      }
    }

    if (!isDateType()) {
      return null;
    }

    if (val == "__/__/____" || val == "") {
      return null; // empty dates are accepted
    }

    const [day, month, year] = val.split("/").map(Number);
    const dateObject = new Date(year, month - 1, day);
    if (
      dateObject.getFullYear() !== year ||
      dateObject.getMonth() + 1 !== month ||
      dateObject.getDate() !== day
    ) {
      return "Date invalide, format attendu: jj/mm/aaaa";
    }
    return null;
  }

  function displayErrorIfNeeded(val: string) {
    const errStr: string = getErrorString(val);
    if (errStr) {
      seterror(errStr);
      return true;
    }
    seterror(null);
    return false;
  }

  function onchange(e) {
    const val: string = e.target.value;
    const hadErrors: boolean = displayErrorIfNeeded(val);
    const isInputValid = !hadErrors;
    changedCallback(keyy, val, isInputValid);
  }

  function returnJsx() {
    if (!isEdit) {
      return getLabeJsx();
    }
    if (isDateType()) {
      return getDateJsx();
    }
    if (isDropdownType()) {
      return getDropdownJsx();
    }
    if (isMultilineType()) {
      return getMultilineInputJsx();
    }
    return getInputTextJsx();
  }

function getCopyButtonJsx(val: string) {

  return (
  <CopyButton val={val} />
  );
}

  function getLabeJsx() {
    let val: string;

    if (isDropdownType()) {
      const found = getDropdownData().find((i) => i.key == value);
      if (found) {
        val = found.value;
      }
    } else {
      val = value;
    }

    if(allowCopy){
      return <span>{val} {getCopyButtonJsx(val)}</span>;
    }
    else{
      return <span>{val}</span>;
    }

  }

  function getInputTextJsx() {
    return(
    <div>
      <input type="text" value={value} onChange={onchange}></input>
      {error && <div style={{ color: "red",fontSize: "12px",width: "300px" }}>{error}</div>}
    </div>
  );
  }

  function getMultilineInputJsx() {
    return (
      <div>
        <textarea value={value} onChange={onchange}></textarea>
        {error && <div style={{ color: "red", fontSize: "12px", width: "300px" }}>{error}</div>}
      </div>
    );
  }

  function getDateJsx() {
    return (
      <div>
        <InputMask
          mask="99/99/9999"
          value={value}
          onChange={onchange}
          placeholder="jj/mm/aaaa"
        ></InputMask>
        {error && <div style={{ color: "red" }}>{error}</div>}
      </div>
    );
  }

  function getDropdownData() {
    if(dropdownData.length > 0){
      return dropdownData;
    }
    return [
      { key: "cam_gros_animaux", value: "Camera gros animaux" },
      { key: "cam_petits_animaux", value: "Camera petits animaux" },
      { key: "cam_insectes", value: "Camera insectes" },
      { key: "bioacoustique", value: "Bioacoustique" },
      { key: "bioacoustique_ultrason", value: "Bioacoustique ultrason" },
      { key: "analyse_sol", value: "Analyse de sol" },
      { key: "analyse_eau", value: "Analyse eau" },
    ];
  }

  function getDropdownJsx() {
    return (
      <select value={value} onChange={onchange}>
        {getDropdownData().map((d) => (
          <option key={d.key} value={d.key}>
            {d.value}
          </option>
        ))}
      </select>
    );
  }
  ///////////////////////////////////////////////////////////
  return returnJsx();
}

export default Editable;
