import { getThumbnailUrlFromTaxref, getThumbnailUrlFromTitle } from "../dataProvider/itemHelper";
import React, { useEffect, useState } from "react";

function ItemThumbnail({ item, isBig, isTiny = false }) {

  const [imgSrc, setImgSrc] = useState(getThumbnailUrlFromTaxref(item));

  useEffect(() => {
    let url = getThumbnailUrlFromTaxref(item);
    if (!url) {
      url = getThumbnailUrlFromTitle(item);
    }
    setImgSrc(url);
  }, [item]);

  let width = isBig ? "500px" : "200px";
  width = isTiny ? "4rem" : width;

  let height = "auto";
  height = isTiny ? "2rem" : height;


  const onError = (err) => {
    setImgSrc(getThumbnailUrlFromTitle(item));
  };


  return (
    <img
      src={imgSrc}
      alt={item.title}
      style={{ width, height, borderRadius: "20px" }}
      onError={onError}
    />
  );
}

function BigItemThumbnail({ item }) {
  return <ItemThumbnail item={item} isBig={true} />;
}

function SmallItemThumbnail({ item }) {
  return <ItemThumbnail item={item} isBig={false} />;
}

function TinyItemThumbnail({ item }) {
  return <ItemThumbnail item={item} isBig={false} isTiny={true} />;
}

export { BigItemThumbnail, SmallItemThumbnail, TinyItemThumbnail };
