import React, { useState, useEffect, useRef } from "react";
import Df from "../tablepage/DataFilter";

const DataFilter = ({ filterChangedCallBack }) => {
  const popinfilters = useRef(null);
  const [filtersVisible, setFiltersVisible] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popinfilters.current &&
        !popinfilters.current.contains(event.target)
      ) {
        setFiltersVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function titleChanged(title: string) {
    filterChangedCallBack({ title: title });
  }
  function favoriteChanged(fav: boolean) {
    filterChangedCallBack({ favorite: fav });
  }

  return (
    <div ref={popinfilters}>
      <a
        className="btn btn-icon btn-noborder mr-1"
        onClick={() => setFiltersVisible(!filtersVisible)}
      >
        <i className="ei ei-filter"></i>
        <span>Données</span>
      </a>
      <div
        id="popin-filters-datadata"
        className="card"
        style={{ display: filtersVisible ? "block" : "none" }}
      >
        <Df titleChanged={titleChanged} favoriteChanged={favoriteChanged} />
        <br />
      </div>
    </div>
  );
};

export default DataFilter;
