import React from "react";
import Checkbox from "./Checkbox";

export default function CheckboxList({ list, onChange }) {
  return (
    <>
      <ul className="switch">
        {list.map((i) => (
          <div key={i.key}>
            <Checkbox
              name={i.key}
              label={i.value}
              checked={i.selected}
              onChange={onChange}
            ></Checkbox>
          </div>
        ))}
      </ul>
    </>
  );
}
