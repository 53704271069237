import { Outlet } from "react-router-dom";
import "./root.css"; // old version : nico
import "./style.css"; // new version : ben

import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";

import { Amplify } from "aws-amplify";
import amplifyconfig from "../amplifyconfiguration.json";

import Header from "./Header";

Amplify.configure(amplifyconfig);

const Root = () => {
  return (
    <>
      <Header />
      {/* il faut donc deplacer la classe dans les pages concernees*/}
      <Outlet />
    </>
  );
};

export default Root;
