import React, { useState } from 'react';

const Soufflet = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="soufflet-container">
      <div className="soufflet-header" onClick={toggleOpen}>
        {title} {isOpen ? '↓' : '►'}
      </div>
      {isOpen && <div className="soufflet-content">{children}</div>}
    </div>
  );
};

export default Soufflet;
