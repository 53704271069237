import React from "react";

const AudioPlayer = ({ url }) => {
  return (
    <div className="media-wrap media-wrap-audio">
      <audio controls autoPlay src={url}>
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AudioPlayer;
