import React, { useState, useEffect, useRef } from "react";
import { getPropertyBoundary, getPropertyCoordinates } from "../ConfigProvider";
import {
  getSensors,
  saveSensors,
  newSensor,
} from "../dataProvider/sensorProviders";
import SensorDetail from "./SensorDetail";
import { cloneDeep } from "lodash-es";
import Sensor from "./Sensor";
import L from "leaflet";
import useAdmin from "../hooks/useAdmin";
import { getTilelayerUrl } from "../mapHelpers";

function SensorPage() {
  const [sensorList, setSensorList] = useState([]);
  const [sensorListFromDb, setSensorListFromDb] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [markers, setMarkers] = useState({});
  const selectedSensorRef = useRef(selectedSensor);
  const sensorRefsHtml = useRef({});
  const [checkedTypes, setCheckedTypes] = useState(() => {
    const savedCheckedTypes = localStorage.getItem('checkedTypes');
    return savedCheckedTypes ? JSON.parse(savedCheckedTypes) : {};
  });

  useEffect(() => {
    localStorage.setItem('checkedTypes', JSON.stringify(checkedTypes));
  }, [checkedTypes]);

  const mapRef = useRef(null);

  const popinRef = useRef(null);
  const [sensorToPopup, setSensorPopup] = useState(null);

  const [isSelectingCoords, setIsSelectingCoords] = useState(false);
  const isSelectingCoordsRef = useRef(false);
  const [selectedCoordsOnMap, setSelectedCoordsOnMap] = useState(null);

  const [checkedActifs, setCheckedActifs] = useState({
    actifs: true,
    archived: true,
    future: true,
  });

  const { isAdmin } = useAdmin();

  function downloadRefCoordsJson() {
    const toExport = sensorList.map((s) => {
      return {
        ref: s.ref,
        latitude: s.lat,
        longitude: s.lng,
      };
    });

    //some sensors have this format for refs: 9EGM/12EGM . so we create 2 items in this case
    let duplicatedRefsSensors = [];
    toExport.forEach((s) => {
      s.ref.split("/").forEach((r) => {
        duplicatedRefsSensors.push({
          ref: r,
          latitude: s.latitude,
          longitude: s.longitude,
        });
      });
    });

    const jsonStr = JSON.stringify(duplicatedRefsSensors, null, 2);

    const blob = new Blob([jsonStr], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "refsCoordinates.json"; 
    document.body.appendChild(a); 
    a.click(); 

    URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  function isNico() {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.has("nico");
  }

  function addSensor() {
    let newS = newSensor();
    setSelectedSensor(newS);
    setSensorPopup(true);
  }

  function changeCursorForAllMarkers(cursor: string) {
    Object.keys(markers).forEach((key) => {
      let m = markers[key];
      m._icon.style.cursor = cursor;
    });
  }

  useEffect(() => {
    isSelectingCoordsRef.current = isSelectingCoords;

    if (mapRef.current) {
      let mc = mapRef.current.getContainer();
      let cursor: string;
      if (isSelectingCoords) {
        cursor = "crosshair";
      } else {
        cursor = "";
      }
      mc.style.cursor = cursor;
      changeCursorForAllMarkers(cursor);
    }
  }, [isSelectingCoords]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popinRef.current && !popinRef.current.contains(event.target)) {
        if (!isSelectingCoordsRef.current) {
          setSensorPopup(false);
          setSelectedCoordsOnMap(null);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popinRef]);

  function selectOnMapCallback() {
    setIsSelectingCoords(true);
  }

  const deleteSensor = async function (s: Sensor) {
    console.log(s);
    if (!s.id || s.id == "") {
      throw "id not found, can't delete'";
    }

    let tosave = cloneDeep(sensorListFromDb);
    const i = tosave.findIndex((obj) => obj.id == s.id);
    if (i == -1) {
      throw "id not found, can't delete'";
    }
    tosave.splice(i, 1);

    await saveSensors(tosave);
    location.reload();
  };

  const saveSensor = async function (s: Sensor) {
    console.log(s);
    if (!s.id || s.id == "") {
      throw "id not found, can't save'";
    }

    let tosave = cloneDeep(sensorListFromDb);
    const i = tosave.findIndex((obj) => obj.id == s.id);
    // Check if another sensor has the same ref
    const existingSensorWithSameRef = tosave.find(sensor => sensor.id !== s.id && sensor.ref === s.ref);
    if (existingSensorWithSameRef) {
      alert("Un capteur avec la référence " + s.ref + " existe déjà. Merci de modifier la référence.");
      return false;
    }
    if (i == -1) {
      //it's a new sensor
      tosave.push(s);
    } else {
      //it's an existing sensor
      tosave[i] = s;
    }

    await saveSensors(tosave);
    location.reload();
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    var updatedCheckedTypes = checkedTypes;
    var updatedActifs = checkedActifs;

    if (name == "actifs" || name == "archived" || name == "future") {
      updatedActifs = { ...checkedActifs, [name]: checked };
      setCheckedActifs(updatedActifs);
    } else {
      updatedCheckedTypes = { ...checkedTypes, [name]: checked };
      setCheckedTypes(updatedCheckedTypes);
    }

    var newSensorList = getSensorList().filter(
      (sensor) => updatedCheckedTypes[sensor.type]
    );

    newSensorList = newSensorList.filter((s) => {
      if (s.isActif()) {
        return updatedActifs.actifs;
      } else if (s.isFuture()) {
        return updatedActifs.future;
      } else {
        return updatedActifs.archived;
      }
    });

    setSensorList(newSensorList);
    if (mapRef.current) {
      displaySensorsOnMap(newSensorList, mapRef.current);
    }
  };

  function toggleCheckAll() {
    const newCheckedTypes = {};
    for (const key in checkedTypes) {
      newCheckedTypes[key] = !checkedTypes[key];
    }
    setCheckedTypes(newCheckedTypes);
    const newSensorList = getSensorList().filter(
      (sensor) => newCheckedTypes[sensor.type]
    );

    setSensorList(newSensorList);
    if (mapRef.current) {
      displaySensorsOnMap(newSensorList, mapRef.current);
    }
  }

  useEffect(() => {
    selectedSensorRef.current = selectedSensor;
    if (selectedSensor && sensorRefsHtml.current[selectedSensor.ref]) {
      sensorRefsHtml.current[selectedSensor.ref].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedSensor]);

    const handleMapMove = () => {
      const newCenter = mapRef.current.getCenter();
      const newZoom = mapRef.current.getZoom();
      console.log('Position:', newCenter);
      console.log('Zoom:', newZoom);
      localStorage.setItem('mapPosition', JSON.stringify({ lat: newCenter.lat, lng: newCenter.lng, zoom: newZoom }));
    };


  useEffect(() => {
    if (mapRef.current) {
      return;
    }

    const defaultZoom = 16; // Adjust the default zoom level if needed
    const ignLayer = L.tileLayer(
      getTilelayerUrl("esri"),
      {
        maxZoom: 30,
        attribution: "&copy; IGN contributors",
      }
    );

    const savedMapPosition = localStorage.getItem('mapPosition');
    const initialPosition = savedMapPosition ? JSON.parse(savedMapPosition) : { lat: 44.7163158285053, lng: -0.3834068326026641, zoom: defaultZoom };

    const map = L.map("map", { maxZoom: 19 }).setView(
      [initialPosition.lat, initialPosition.lng],
      initialPosition.zoom
    );
    mapRef.current = map;
    map.on('moveend', handleMapMove);

    getPropertyCoordinates().then((coords) => {
      let zoom = coords.zoomLevel || initialPosition.zoom;
      if(!savedMapPosition){
        mapRef.current.setView([coords.latitude, coords.longitude], zoom);
      }
    });

    ignLayer.addTo(map);

    map.on("click", function (e) {
      const currentSensor = selectedSensorRef.current;
      if (!currentSensor) {
        return;
      }
      if (isSelectingCoordsRef.current) {
        console.log("selec coords");
        const selectedCoords = { lat: e.latlng.lat, lng: e.latlng.lng };
        setSelectedCoordsOnMap(selectedCoords);
        setIsSelectingCoords(false);
      }
    });

    getSensors().then((sensors: Sensor[]) => {
      function sortSensorList(slist: Sensor[]) {
        slist.sort((s1: Sensor, s2: Sensor): number => {
          var s1str = s1.type + s1.name;
          var s2str = s2.type + s2.name;
          return s1str.localeCompare(s2str);
        });
      }

      sortSensorList(sensors);
      setSensorListFromDb(sensors);
      setSensorList(sensors);
      displaySensorsOnMap(sensors, map);

      const newCheckedTypes = {};
      sensors.forEach((sensor) => {
        if(checkedTypes && checkedTypes.hasOwnProperty(sensor.type)){
          newCheckedTypes[sensor.type] = checkedTypes[sensor.type];
        } else {
          newCheckedTypes[sensor.type] = true;
        }
      });
      setCheckedTypes(newCheckedTypes);
    });
    displayPropertyBoundaries(map);
  }, []);

  function displaySensorsOnMap(sensors: Sensor[], map) {
    for (const key in markers) {
      map.removeLayer(markers[key]);
    }

    const newMarkers = {};
    sensors.forEach((sensor: Sensor) => {
      const customIcon = getMarkerIconForSensor(sensor);

      const marker = L.marker([sensor.lat, sensor.lng], {
        icon: customIcon,
      }).addTo(map);
      marker.on("click", (e) => {
        console.log("markerclick");
        if (!isSelectingCoordsRef.current) {
          console.log("openPopup");
          //marker.bindPopup(`<h3>${sensor.name}</h3>`).openPopup();
          selectSensor(sensor);
        } else {
          map.fire("click", {
            latlng: e.latlng,
            layerPoint: map.latLngToLayerPoint(e.latlng),
            containerPoint: map.latLngToContainerPoint(e.latlng),
            originalEvent: e.originalEvent,
          });
        }
      });

      newMarkers[sensor.id] = marker;
    });
    setMarkers(newMarkers);
    console.log(newMarkers);
  }

  function getImageForSensor(sensor: Sensor) {
    function getPrefix(sensor: Sensor) {
      const sensorType = sensor.type;
      switch (sensorType) {
        case "cam_gros_animaux":
          return "video";
        case "cam_insectes":
          return "camera_insectes";
        case "cam_petits_animaux":
          return "camera_petits";
        case "bioacoustique":
          return "sound";
        case "bioacoustique_ultrason":
          return "ultrason";
        case "biologie_moleculaire":
        case "analyse_sol":
          return "adn";
        case "analyse_eau":
          return "adn_eau";

        default:
          throw new Error("no image for sensor type:" + sensor.type);
      }
    }

    const prefix = getPrefix(sensor);
    const suffix = sensor.isArchived() ? "_archived" : "";
    const extension = ".png";

    return prefix + suffix + extension;
  }

  function getMarkerIconForSensor(sensor: Sensor) {
    const imageUrl = getImageForSensor(sensor);
    return L.icon({
      iconUrl: imageUrl,
      iconSize: [32, 32],
      iconAnchor: [12, 12],
      popupAnchor: [1, -12],
      shadowSize: [25, 25],
      className: sensor.type,
    });
  }

  function getSensorList(): Sensor[] {
    return sensorListFromDb;
  }

  useEffect(() => {
    const s = selectedSensor;
    if (!s) {
      return;
    }

    const id = s.id;
    console.log(markers);
    if (markers[id]) {
      const marker = markers[id];
      marker.bindPopup(`<h3>${s.name}</h3>`).openPopup();
    }
  }, [selectedSensor]);

  async function displayPropertyBoundaries(map) {
    try {
      const propertyBoundaries = await getPropertyBoundary();
      let propertyBoundariesGeoJSON = JSON.parse(propertyBoundaries);

      const propertyBoundaryLayer = L.geoJSON(propertyBoundariesGeoJSON, {
        style: {
          color: "#A3B75E",
          fillColor: "lightblue",
          fillOpacity: 0,
          interactive: false,
          weight: 5,
        },
      });
      propertyBoundaryLayer.addTo(map);
    } catch (error) {
      console.error("Error fetching property boundaries: ", error);
    }
  }

  function selectSensor(sensor: Sensor) {
    setSelectedSensor(sensor);
  }

  function isSensorSelected(sensor: Sensor) {
    return selectedSensor === sensor;
  }

  function getLabelForType(type: string) {
    switch (type) {
      case "cam_gros_animaux":
        return "Camera gros mammifères";
      case "cam_petits_animaux":
        return "Camera petits animaux";
      case "cam_insectes":
        return "Camera insectes";
      case "bioacoustique":
        return "Bioacoustique";
      case "bioacoustique_ultrason":
        return "Bioacoustique ultrasons";
      case "biologie_moleculaire":
        return "Biologie Moleculaire";
      case "analyse_eau":
        return "Analyse eau";
      case "analyse_sol":
        return "Analyse de sol";
      default:
        return type;
    }
  }

  function getDateText(s: Sensor) {
    if (!s.hasStartDate()) {
      return "(pas de dates)";
    }
    if (s.isArchived()) {
      return s.startdate + " -> " + s.enddate;
    }
    if (s.isFuture()) {
      return "A partir du " + s.startdate;
    }
    return "Depuis le " + s.startdate;
  }

  return (
    <div className="content">
      <div className="nav">
        <>
          <div>
            <div style={{ fontWeight: "bold" }}>
              <h2>Capteurs</h2>
              {isNico() && (
                <a href="#" onClick={downloadRefCoordsJson}>
                  downloadRefCoordsJson
                </a>
              )}
              <hr />
              <span style={{ cursor: "pointer", fontSize: "12px" }}>
                Filtrer:
              </span>
              <input
                type="checkbox"
                id="actifs"
                name="actifs"
                onChange={handleCheckboxChange}
                checked={checkedActifs.actifs}
                style={{ cursor: "pointer" }}
              />
              <label
                htmlFor="actifs"
                style={{ cursor: "pointer", fontSize: "12px" }}
              >
                Actifs
              </label>
              <input
                type="checkbox"
                id="archived"
                name="archived"
                onChange={handleCheckboxChange}
                checked={checkedActifs.archived}
                style={{ cursor: "pointer" }}
              />
              <label
                htmlFor="archived"
                style={{ cursor: "pointer", fontSize: "12px" }}
              >
                Archivés
              </label>

              <input
                type="checkbox"
                id="future"
                name="future"
                onChange={handleCheckboxChange}
                checked={checkedActifs.future}
                style={{ cursor: "pointer" }}
              />
              <label
                htmlFor="future"
                style={{ cursor: "pointer", fontSize: "12px" }}
              >
                Futurs
              </label>

              <hr />
              <span style={{ fontSize: "12px" }}>
                {sensorList.length} capteurs affichés:
              </span>
            </div>
            <div>
              <ul className="eventlist">
                {sensorList.map(
                  (
                    s: Sensor,
                    i //
                  ) => (
                    <li
                      key={i}
                      onClick={() => selectSensor(s)}
                      className={!isSensorSelected(s) ? "disabled" : ""}
                      ref={(el) => {
                        sensorRefsHtml.current[s.ref] = el;
                      }}
                    >
                      {/* Image cell */}

                      {s.isFuture() && <i className="fas fa-calendar-alt"></i>}
                      <img
                        src={getImageForSensor(s)}
                        style={{ width: "50px" }}
                      />

                      {/* Text cell */}
                      <div className="event-name">
                        <span style={{}}>{s.name}</span>
                        <>
                          <br />
                          <div
                            style={{
                              fontSize: "12px",
                              marginBottom: "5px",
                              marginTop: "5px",
                              fontStyle: "italic",
                            }}
                          >
                            {s.ref}
                          </div>
                          <div style={{ color: "grey", paddingBottom: "5px" }}>
                            {getDateText(s)}
                          </div>
                          <button
                            onClick={() => {
                              setSensorPopup(true);
                            }}
                          >
                            Details
                          </button>
                        </>
                      </div>
                    </li>
                  )
                )}
              </ul>
              {isAdmin && (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <button onClick={addSensor}>Ajouter un capteur</button>
                </div>
              )}
            </div>
          </div>
        </>
      </div>
      <div className="main">
        <div className="filter">
          <a className="btn btn-primary" onClick={toggleCheckAll}>
            {"Tout"}
          </a>
          {Object.keys(checkedTypes).map((type) => (
            <div key={type} style={{ marginLeft: "10px" }}>
              <input
                type="checkbox"
                id={`checkbox-${type}`}
                name={type}
                onChange={handleCheckboxChange}
                checked={checkedTypes[type]}
                style={{ cursor: "pointer" }}
              />
              <label
                htmlFor={`checkbox-${type}`}
                style={{ cursor: "pointer", fontSize: "12px" }}
              >
                {getLabelForType(type)}
              </label>
            </div>
          ))}
        </div>

        <div
          className="mapcontainer"
          style={{ display: "flex", height: "100%" }}
        >
          <div id="map" style={{ flex: "1" }}></div>
        </div>
      </div>
      {sensorToPopup && (
        <div
          id="popin"
          ref={popinRef}
          style={{ display: isSelectingCoords ? "none" : "block" }}
        >
          <SensorDetail
            sensor={selectedSensor}
            saveCallback={saveSensor}
            selectOnMapCallback={selectOnMapCallback}
            selectedCoordsOnMap={selectedCoordsOnMap}
            deleteCallback={deleteSensor}
          />
        </div>
      )}
    </div>
  );
}

export default SensorPage;
