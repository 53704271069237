import React, { useState, useEffect, useRef } from "react";
import Map3 from "./Map3.jsx";
import DataTypeFilter from "./DataTypeFilter";
import EventList from "./EventList";
import DateFilter from "./DateFilter";
import DataFilter from "./DataFilter";
import {
  getItemCollection,
  getMarkers,
  getItemCollectionFetchMore,
} from "../dataProvider/itemProvider";
import { ItemCollection } from "../dataProvider/itemCollection";

function MapPage() {
  const [itemCollection, setItemCollection] = useState(new ItemCollection());
  const [markers, setMarkers] = useState([]);
  const [selectedItemsCollection, setSelectedItemsCollection] = useState(
    new ItemCollection([]),
    0
  );
  const [coordBox, setCoordBox] = useState([]);
  const [dateBox, setDateBox] = useState({ start: new Date(), end: null });
  const [onlyFavorites, setOnlyFavorites] = useState(false);
  const [titleContains, setTitleContains] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const eventListRef = useRef();

  const [options, setOptions] = useState({
    displayImage: false,
    displayVideo: false,
    displayCamera: false,
    displaySound: false,
    displayUltrason: false,
    displaySol: false,
    displayPratique: false,
    displayGbif: false,
    displayInpn: false,
    displayFauna: false,
    displayVegetation: false,
    displayPropertyBoundary: false,
    displayGeology: false,
    displayParcellaire: false,
    displayNatureSols: false,
    mapMode: "roadmap",
  });

  function getFilters() {
    return {
      dateBox: { ...dateBox },
      coordBox: { ...coordBox },
      options: { ...options },
      favorite: onlyFavorites,
      titlecontains: titleContains,
    };
  }

  function optionsFilterChangedCallBack(newOpt) {
    emptySelectedItemCollection();
    setOptions(newOpt);
  }

  function dateFilterChangedCallBack(newDateBox) {
    emptySelectedItemCollection();
    setDateBox(newDateBox);
  }

  function dataFilterChangedCallBack(newValues) {
    emptySelectedItemCollection();
    if (newValues.hasOwnProperty("title")) {
      setTitleContains(newValues.title);
    }

    if (newValues.hasOwnProperty("favorite")) {
      setOnlyFavorites(newValues.favorite);
    }
  }

  function emptySelectedItemCollection() {
    setSelectedItemsCollection(new ItemCollection([]), 0);
  }

  function loadMoreEvents(onselected) {
    if (onselected) {
      getItemCollectionFetchMore(selectedItemsCollection, 20).then(
        (newitemCol) => {
          setSelectedItemsCollection(newitemCol);
        }
      );
    } else {
      getItemCollectionFetchMore(itemCollection, 20).then((newitemCol) => {
        setItemCollection(newitemCol);
      });
    }
  }

  useEffect(() => {
    setIsLoading(true);

    getItemCollection(getFilters(), 0, 100).then((itemCol) => {
      setItemCollection(itemCol);
    });

    // get markers for map
    getMarkers(getFilters()).then((markers) => {
      setMarkers(markers);
    });

    setIsLoading(false);
  }, [dateBox, options, coordBox, titleContains, onlyFavorites]);

  function coordBoxUpdated(newBox) {
    setCoordBox(newBox);
  }

  async function selectedMarkerInMap(e) {
    let { lat, lng } = e.latLng;
    lat = parseFloat(lat.toFixed(6));
    lng = parseFloat(lng.toFixed(6));
    let localFilters = getFilters();

    localFilters.coordBox.west = lng - 0.000001;
    localFilters.coordBox.south = lat - 0.000001;
    localFilters.coordBox.east = lng + 0.000001;
    localFilters.coordBox.north = lat + 0.000001;

    if (e.ispoint) {
      localFilters.geojsontype = "point";
    }

    const matchingItemsCollection = await getItemCollection(localFilters);
    setSelectedItemsCollection(matchingItemsCollection);
  }

  function unselectAll() {
    const itemCol = new ItemCollection([], 0);
    setSelectedItemsCollection(itemCol);
  }

  function selectedEventInEventList(e) {
    if (!e) {
      unselectAll();
    } else {
      const itemCol = new ItemCollection([e], 1);
      setSelectedItemsCollection(itemCol);
    }
  }

  function playCallBack() {
    if (!selectedItemsCollection || !selectedItemsCollection.itemList) {
      return;
    }

    const firstItem = selectedItemsCollection.itemList[0];

    if (!firstItem) {
      return;
    }

    eventListRef.current.play(firstItem);
  }

  return (
    <div className="content">
      <div className="nav">
        {isLoading ? (
          <div style={{ textAlign: "center" }}>Loading...</div>
        ) : (
          <EventList
            ref={eventListRef}
            itemCollection={itemCollection}
            selectedItemsCollection={selectedItemsCollection}
            selectEventCallBack={selectedEventInEventList}
            loadMoreEvents={loadMoreEvents}
          />
        )}
      </div>
      <div className="main">
        <div className="filter">
          <DataTypeFilter
            options={options}
            filterChangedCallBack={optionsFilterChangedCallBack}
          />
          <DateFilter
            filterChangedCallBack={dateFilterChangedCallBack}
            displayMode="popup"
          />
          <DataFilter filterChangedCallBack={dataFilterChangedCallBack} />
        </div>
        <Map3
          markers={markers}
          options={options}
          coordBoxUpdated={coordBoxUpdated}
          selectMarkerCallBack={selectedMarkerInMap}
          selectedItemsCollection={selectedItemsCollection}
          unselectCallback={unselectAll}
          playCallBack={playCallBack}
        />
      </div>
    </div>
  );
}

export default MapPage;
