import { useState, useEffect } from "react";
import { getUserName } from "../ConfigProvider";

const useSuperadmin = () => {
  const [isSuperadmin, setIsSuperadmin] = useState(false);

  useEffect(() => {
    getUserName().then((userName) => {
      setIsSuperadmin(userName.startsWith("e1c_nico_"));
    });
  }, []);

  return  isSuperadmin ;
};

export default useSuperadmin;
