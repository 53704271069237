import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useAdmin from "../hooks/useAdmin";
import { getClientName } from "../ConfigProvider";

const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
  padding: 3rem;
`;

const DashboardItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const DashboardImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const DashboardLabel = styled.h3`
  text-align: center;
  font-size: 1.2rem;
  margin: 0;
`;

interface DashboardInfo {
  id: string;
  label: string;
  imageUrl: string;
  link: string;
  isLapeyrucheOnly?: boolean;
  isLouisedelavalliereOnly?: boolean;
  isDetail?: boolean;
  isAdminOnly?: boolean;
}

const dashboards: DashboardInfo[] = [
  {
    id: "site",
    label: "Statistiques globales du site",
    imageUrl: "/dashboardImages/dashboardSite.jpg",
    link: "/siteDashboard",
  },
  {
    id: "allSites",
    label: "Statistiques globales, tous les sites",
    imageUrl: "/dashboardImages/dashboardAllSites.jpg",
    link: "/allSitesDashboard",
    isAdminOnly: true,
  },
  {
    id: "mammiferes",
    label: "Observations images/vidéos",
    imageUrl: "/dashboardImages/dashboardMammiferes.jpg",
    link: "https://dashboards.e1c.org/superset/dashboard/4/?native_filters_key=z7cm8ou5Bw6zC7m88QTuwhkPQ7542zFXZAokz0MinyP3M9N3ufzpopW4i1zURp3D",
    isLapeyrucheOnly: true,
    isDetail: true,
  },
  {
    id: "oiseaux",
    label: "Observations sons",
    imageUrl: "/dashboardImages/dashboardOiseaux.jpg",
    link: "https://dashboards.e1c.org/superset/dashboard/7/?native_filters_key=MIjmb73a3DQC8wq_fdUzF4w5ZDX5H1d7sfeZu-8LLE2InNOt0NGEbJRTUwDrnHS7",
    isLapeyrucheOnly: true,
    isDetail: true,
  },
  {
    id: "mammiferes_louisedelavalliere",
    label: "Observations images/vidéos",
    imageUrl: "/dashboardImages/dashboardMammiferes.jpg",
    link: "https://dashboards.e1c.org/superset/dashboard/17/?native_filters_key=OxbIWkwFrT1J_GfQ0A-SKv-h24DkMNt1w0EanEDYH8Eg1lgflYcd6wAKZOAPlTbu",
    isLouisedelavalliereOnly: true,
    isDetail: true,
  },
  {
    id: "oiseaux_louisedelavalliere",
    label: "Observations sons",
    imageUrl: "/dashboardImages/dashboardOiseaux.jpg",
    link: "https://dashboards.e1c.org/superset/dashboard/16/?native_filters_key=Uck1F_COii74MJ5sqHbDzNX-gA3P7T1aqkjm7MmuOhxkd0sba4sjEjsACoJdpGUZ",
    isLouisedelavalliereOnly: true,
    isDetail: true,
  },
  {
    id: "bacteries",
    label: "Observations ADN environnemental",
    imageUrl: "/dashboardImages/dashboardB.jpg",
    link: "https://dashboards.e1c.org/superset/dashboard/9/?native_filters_key=byGWCZrvxj-KfTuXSPHnnOW0IRtgDMVtNnyJ8Z1l1okimgZnoG42kdnq2_zU_oh6",
    isLapeyrucheOnly: true,
    isDetail: true,
  },
  {
    id: "chauvessouris",
    label: "Observations ultrasons",
    imageUrl: "/dashboardImages/dashboardChauveSouris.jpg",
    link: "https://dashboards.e1c.org/superset/dashboard/10/?native_filters_key=GEwEhd5mdQMyXmbhhjxoXha4AcLSotP21CUPey1SHxT_X0O7ssUUGLQ5J1kqX0J-",
    isLapeyrucheOnly: true,
    isDetail: true,
  },
];

function shouldDisplayDashboard(dashboard: DashboardInfo, canBeAdmin: boolean, clientName: string): boolean {
  if (dashboard.isAdminOnly && !canBeAdmin) {
    return false;
  }
  if (dashboard.isLapeyrucheOnly && clientName !== "lapeyruche") {
    return false;
  }
  if (dashboard.isLouisedelavalliereOnly && clientName !== "louisedelavalliere") {
    return false;
  }
  return true;
}

function getDetailDashboards(canBeAdmin: boolean, clientName: string): DashboardInfo[] {
  return dashboards.filter((dashboard) => dashboard.isDetail && shouldDisplayDashboard(dashboard, canBeAdmin, clientName));
}

function getGeneralDashboards(canBeAdmin: boolean, clientName: string): DashboardInfo[] {
  return dashboards.filter((dashboard) => !dashboard.isDetail && shouldDisplayDashboard(dashboard, canBeAdmin, clientName));
}

const DashboardHome: React.FC = () => {
  const [clientName, setClientName] = useState<string | null>(null);
  useEffect(() => {
    const fetchClientName = async () => {
      const name = await getClientName();
      setClientName(name);
    };
    fetchClientName();
  }, []);

  const { canBeAdmin } = useAdmin();
  return (
    <div className="mainpage">
      <h2 style={{ textAlign: "center", margin: "3m 0" }}>Dashboards:</h2>
      <DashboardGrid>
        {getGeneralDashboards(canBeAdmin, clientName).map((dashboard) => {
          return (
            <DashboardItem key={dashboard.id} to={dashboard.link}>
              <DashboardImage src={dashboard.imageUrl} alt={dashboard.label} />
              <DashboardLabel>{dashboard.label}</DashboardLabel>
            </DashboardItem>
          );
        })}
      </DashboardGrid>
      <hr style={{ width: "80%", margin: "2rem auto", border: "none", borderTop: "1px solid #ccc" }} />
      <DashboardGrid>
        {getDetailDashboards(canBeAdmin, clientName).map((dashboard) => {
          return (
            <DashboardItem key={dashboard.id} to={dashboard.link}>
              <DashboardImage src={dashboard.imageUrl} alt={dashboard.label} />
              <DashboardLabel>{dashboard.label}</DashboardLabel>
            </DashboardItem>
          );
        })}
      </DashboardGrid>
    </div>
  );
};

export default DashboardHome;
