import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { getStage, getClientName, getUserName } from "../ConfigProvider";
import { signOut } from "aws-amplify/auth";
import ToggleAdmin from "../components/ToggleAdmin";
import useAdmin from "../hooks/useAdmin";

function Header() {
  const [clientName, setClientName] = useState(null);
  const [currentUserName, setCurrentUserName] = useState(null);

  const { canBeAdmin } = useAdmin();

  useEffect(() => {
    let title =
      window.location.hostname === "localhost" ? "localhost" : "Every1Counts";
    document.title = `${title} (${getStage()})`;
    getClientName().then(setClientName);
    getUserName().then((user) => {
      setCurrentUserName(user);
    });
  }, []);

  return (
    <>
      <div className="header">
        <div className="d-flex flex-row">
          <Link to="/">
            <img className="logo" src="logo_E1C_blanc_full.png" />
          </Link>


          <Link to="map" className="btn btn-icon btn-noborder mr-1">
            <i className="ei ei-map-pin"></i>
            <span>Carte</span>
          </Link>

          <Link to="table" className="btn btn-icon btn-noborder mr-1">
            <i className="ei ei-menu "></i>
            <span>Données</span>
          </Link>

          <Link to="sensors" className="btn btn-icon btn-noborder mr-1">
            <i className="fa-solid fa-map-pin"></i>
            <span>Capteurs</span>
          </Link>
          <Link to="dashboardsHome" className="btn btn-icon btn-noborder mr-1">
            <i className="fa-solid fa-braille"/>
            <span>Dashboards</span>
          </Link>
        </div>

        <div>
          <ToggleAdmin />
        </div>

        {canBeAdmin && (
          <Link to="admin" className="btn btn-icon btn-noborder mr-1">
            <i className="fa-solid fa-gear"/>
            <span>Menu Admin</span>
          </Link>
        )}

        <div>
          <a
            className="btn btn-icon btn-noborder btn-logout"
            onClick={() => {
              signOut();
              localStorage.clear()
              window.location.reload();
            }}
          >
            <i className="ei ei-logout"></i>
            <span className="text-left">
              <strong>{currentUserName}</strong>
              <br />({clientName})
            </span>
          </a>
        </div>
      </div>
    </>
  );
}

export default Header;
