import React, { useEffect } from "react";
import Plotly from "plotly.js-dist";
import { AllDataDashboard } from "./AllDataType";

const BatsHeatmap = ({ allData }: { allData: AllDataDashboard }) => {
  const {
    AllDataDashboardPeriodeEspeceEtAccueil,
    ColorscaleCapteurs,
    ColorscaleInventaires,
  } = allData;

  function getAllSpeciesEntries() {
    const entries = Object.values(AllDataDashboardPeriodeEspeceEtAccueil[0]);
    return entries;
  }
  function getAllSpecies() {
    const species = Object.keys(AllDataDashboardPeriodeEspeceEtAccueil[0]);
    return species;
  }

  useEffect(() => {
    const createHeatMaps = () => {
      const calculateAveragePassages = (speciesEntry: any) => {
        if (
          !speciesEntry ||
          !speciesEntry.Capteur ||
          !speciesEntry.Capteur.Lieu
        ) {
          console.error("Invalid species entry");
        }
        return Object.keys(speciesEntry.Capteur.Lieu).map((location) => {
          const total = Object.values(
            speciesEntry.Capteur.Lieu[location]
          ).reduce((sum: number, value: number) => sum + value, 0);
          const compteurJours = Object.keys(
            speciesEntry.Capteur.Lieu[location]
          ).length;
          return Math.round(total / compteurJours);
        });
      };

      const zValuesC = getAllSpeciesEntries().map((speciesEntry) =>
        calculateAveragePassages(speciesEntry)
      );

      function getColorscale() {
        return [
          ["0.0", "#FFFFFF"],
          ["0.01", "#E2EEC7"],
          ["0.3", "#A5D15D"],
          ["0.5", "#426B69"],
          ["1.0", "#234C3D"],
        ];
      }

      const DataCapteur = [
        {
          z: zValuesC,
          x: Object.keys(getAllSpeciesEntries()[0].Capteur.Lieu),
          y: getAllSpecies(),
          type: "heatmap",
          hoverongaps: false,
          xgap: 3,
          ygap: 3,
          colorscale: getColorscale(),
        },
      ];
      console.log(ColorscaleCapteurs);

      const divDetecteursAccueilId = "DivDetecteursAccueil";
      const divDetecteursAccueil = document.getElementById(
        divDetecteursAccueilId
      );
      if (divDetecteursAccueil) {
        divDetecteursAccueil.innerHTML = "";
      }

      const layoutDetec = {
        yaxis: { title: { text: "<b>Espèce</b>", font: { size: 16 } }, automargin: true },
        xaxis: { title: { text: "<b>Lieu</b>", font: { size: 16 } } },
        margin: { t: 30 },
      };

      Plotly.newPlot(divDetecteursAccueilId, DataCapteur, layoutDetec, {
        displayModeBar: false,
      });
    }; // {{ edit_1 }} Closing brace for createHeatMaps function
    createHeatMaps();
  }, []);

  return (
    <>
      <div className="batsdashboards-card-title">
        Nombre de passages moyens par lieu (activité ultrasonore)
      </div>
      <div id="DivDetecteursAccueil"></div>
    </>
  );
};

export default BatsHeatmap;
