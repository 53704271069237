import React, { useState, useEffect, useRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import Checkbox from "../components/Checkbox";

registerLocale("fr", fr);

const DateFilter = ({ filterChangedCallBack, displayMode }) => {
  const [dateSelectionType, setDateSelectionType] = useState("single");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const [filtersVisible, setFiltersVisible] = useState(getInitialFilterVisible);
  const datefilters = useRef(null); //

  function isDisplayFlatMode() {
    return displayMode == "flat";
  }

  function isDisplayPopupMode() {
    return displayMode == "popup";
  }

  function getInitialFilterVisible() {
    if (isDisplayFlatMode()) {
      return true;
    }
    if (isDisplayPopupMode()) {
      return false;
    }
  }

  useEffect(() => {
    // Function to check if clicked outside of the dropdown
    const handleClickOutside = (event) => {
      if (!isDisplayPopupMode()) {
        return;
      }
      if (datefilters.current && !datefilters.current.contains(event.target)) {
        setFiltersVisible(false);
      }
    };

    // Add when the dropdown is open
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function getDateText() {
    if (filtersVisible) return "";
    if (dateSelectionType === "all") {
      return "(Tout)";
    }
    if (dateSelectionType === "lastDay") {
      return "(Aujourd'hui et hier)";
    }
    if (dateSelectionType === "lastWeek") {
      return "(Dernière semaine)";
    }
    if (dateSelectionType === "lastMonth") {
      return "(Dernier mois)";
    }
    if (dateSelectionType === "lastYear") {
      return "(Dernière année)";
    }

    if (!endDate) {
      return "(" + startDate.toLocaleDateString("fr-FR") + ")";
    }
    return (
      "(Du " +
      startDate.toLocaleDateString("fr-FR") +
      " au " +
      endDate.toLocaleDateString("fr-FR") +
      ")"
    );
  }

  function datesToDateForApi(dates) {
    // Create new Date instances to avoid mutating original dates
    const s = new Date(dates.start);
    s.setHours(0, 0, 0, 0); // Set start of day for start date

    const e = new Date(dates.end);
    e.setHours(23, 59, 59, 999); // Set end of day for end date

    return {
      start: s,
      end: e,
    };
  }

  function filterChanged(dates) {
    if (isDisplayPopupMode()) {
      setFiltersVisible(false);
    }
    const retdates = datesToDateForApi(dates);
    filterChangedCallBack(retdates);
  }

  const onDateChange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    if (!end) return;
    filterChanged({ start: start, end: end });
  };

  const onSingleDateChange = (date) => {
    setStartDate(date);
    setEndDate(null); // Reset end date for single date selection
    filterChanged({ start: date, end: date });
  };

  function onAllChange() {
    setDateSelectionType("all");
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);
    filterChanged({ start: new Date("1970-01-01"), end: endDate });
  }

  function onSelectLastDay() {
    setDateSelectionType("lastDay");
    //select today and yesterday
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    filterChanged({
      start: yesterday,
      end: today,
    });
  }

  function onSelectLastMonth() {
    setDateSelectionType("lastMonth");
    const today = new Date();
    const lastMonth = new Date(today);
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    filterChanged({
      start: lastMonth,
      end: today,
    });
  }

  function onSelectLastWeek() {
    setDateSelectionType("lastWeek");
    const today = new Date();
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);

    filterChanged({
      start: lastWeek,
      end: today,
    });
  }

  function onSelectLastYear() {
    setDateSelectionType("lastYear");
    const today = new Date();
    const lastYear = new Date(today);
    lastYear.setFullYear(lastYear.getFullYear() - 1);

    filterChanged({
      start: lastYear,
      end: today,
    });
  }

  useEffect(() => {
    onAllChange();
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div ref={datefilters}>
      {isDisplayPopupMode() && (
        <a
          className="btn btn-icon btn-noborder"
          onClick={() => setFiltersVisible(!filtersVisible)}
        >
          <i className="ei ei-calendar"></i>
          <span>Date {getDateText()}</span>
        </a>
      )}

      <div
        id={
          isDisplayPopupMode()
            ? "popin-filters-date"
            : "popin-filters-date-flat"
        }
        style={{ display: filtersVisible ? "block" : "none" }}
        className="card"
      >
        <div>
          <div>
            <div>
              <div>
                <label>
                  <input
                    type="radio"
                    name="dateSelectionType"
                    value="single"
                    checked={dateSelectionType === "lastDay"}
                    onChange={() => onSelectLastDay()}
                  />
                  Dernier jour
                </label>
                <label>
                  <input
                    type="radio"
                    name="dateSelectionType"
                    value="range"
                    checked={dateSelectionType === "lastWeek"}
                    onChange={() => onSelectLastWeek()}
                  />
                  Dernière semaine
                </label>
                <label>
                  <input
                    type="radio"
                    name="dateSelectionType"
                    value="all"
                    checked={dateSelectionType === "lastMonth"}
                    onChange={() => onSelectLastMonth()}
                  />
                  Dernier mois
                </label>
                <label>
                  <input
                    type="radio"
                    name="dateSelectionType"
                    value="all"
                    checked={dateSelectionType === "lastYear"}
                    onChange={() => onSelectLastYear()}
                  />
                  Dernière année
                </label>
                <hr />
                <label>
                  <input
                    type="radio"
                    name="dateSelectionType"
                    value="single"
                    checked={dateSelectionType === "single"}
                    onChange={() => setDateSelectionType("single")}
                  />
                  Jour
                </label>
                <label>
                  <input
                    type="radio"
                    name="dateSelectionType"
                    value="range"
                    checked={dateSelectionType === "range"}
                    onChange={() => setDateSelectionType("range")}
                  />
                  Période
                </label>
                <div>
                  {dateSelectionType === "single" ? (
                    <DatePicker
                      selected={startDate}
                      onChange={onSingleDateChange}
                      locale="fr"
                      inline
                    />
                  ) : dateSelectionType === "range" ? (
                    <DatePicker
                      selected={startDate}
                      onChange={onDateChange}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      locale="fr"
                      inline
                    />
                  ) : null}
                </div>
                <hr />
                <label>
                  <input
                    type="radio"
                    name="dateSelectionType"
                    value="all"
                    checked={dateSelectionType === "all"}
                    onChange={() => onAllChange()}
                  />
                  Tout
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateFilter;
