import React, { useState } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

const PolygonEditor = () => {
  const [geoJsonData, setGeoJsonData] = useState(null);

  const MapWithDrawControls = () => {
    const map = useMap();

    React.useEffect(() => {
      // Create a feature group to store editable layers
      const drawnItems = new L.FeatureGroup();
      map.addLayer(drawnItems);

      // Set up draw controls with options
      const drawControl = new L.Control.Draw({
        draw: {
          polyline: false,
          rectangle: false,
          circle: false,
          marker: false,
          polygon: true,
          circlemarker: false,
        },
        edit: {
          featureGroup: drawnItems,
        },
      });

      // Add draw control to the map
      map.addControl(drawControl);

      // Capture the GeoJSON data when a polygon is created
      map.on('draw:created', (event) => {
        const layer = event.layer;
        drawnItems.addLayer(layer);
        const geoJsonData = layer.toGeoJSON();
        console.log("geoJsonData", geoJsonData);
      });
      map.on('draw:edited', (event) => {
        console.log("draw:edited", event);
      });

      // Cleanup function
      return () => {
        map.removeControl(drawControl);
      };
    }, [map]);

    return (
      <>
        <div>skfljdsf</div>
        <button
          onClick={() => {
        map.trigger('draw:create');
          }}>Create Polygon</button>
      </>
    );
  };

  function Toto() {
    return <button>Toto</button>;
  }

  return (
    <div>
      <MapContainer
        center={[40.73061, -73.935242]} // Initial center coordinates
        zoom={12}
        style={{ width: '100px', height: '100px' }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <MapWithDrawControls />
        <Toto />
      </MapContainer>
      <div>
        <h3>GeoJSON Output:</h3>
        <pre>{JSON.stringify(geoJsonData, null, 2)}</pre>
      </div>
      asfdlkj
      <Toto />
    </div>
  );
};

export default PolygonEditor;
