import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useAdmin from "../hooks/useAdmin";
import useSuperadmin from "../hooks/useSuperadmin";

function AdminHome() {
  const { canBeAdmin } = useAdmin();
  const isSuperadmin = useSuperadmin();

  if (!canBeAdmin) {
    return (
      <div className="mainpage">
        <p>Vous n'êtes pas autorisé à accéder à cette page.</p>
      </div>
    );
  }

  return (
    <div className="mainpage">
      <h2 style={{ textAlign: "center", margin: "3m 0" }}>Dashboards:</h2>
      <br />

      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <Link to="/admin/campagnes" style={{ textDecoration: "none", color: "inherit" }}>
          <div style={{ 
            display: "flex", 
            flexDirection: "column", 
            alignItems: "center", 
            padding: "20px", 
            border: "1px solid #ccc", 
            borderRadius: "8px",
            width: "150px",
            height: "130px",
            textAlign: "center"
          }}>
            <i className="fa-solid fa-clipboard-list" style={{ fontSize: "2em", marginBottom: "10px" }}></i>
            <span>Suivi des campagnes</span>
          </div>
        </Link>
        <Link to="/admin/inventaireCapteurs" style={{ textDecoration: "none", color: "inherit" }}>
          <div style={{ 
            display: "flex", 
            flexDirection: "column", 
            alignItems: "center", 
            padding: "20px", 
            border: "1px solid #ccc", 
            borderRadius: "8px",
            width: "150px",
            height: "130px",
            textAlign: "center"
          }}>
            <i className="fa-solid fa-list" style={{ fontSize: "2em", marginBottom: "10px" }}></i>
            <span>Inventaire des capteurs</span>
          </div>
        </Link>
        {isSuperadmin && (
          <Link to="/datasets" style={{ textDecoration: "none", color: "inherit" }}>
            <div style={{ 
              display: "flex", 
              flexDirection: "column", 
              alignItems: "center", 
              padding: "20px", 
              border: "1px solid #ccc", 
              borderRadius: "8px",
              width: "150px",
              height: "130px",
              textAlign: "center"
            }}>
              <i className="fa-solid fa-database" style={{ fontSize: "2em", marginBottom: "10px" }}></i>
              <span>Datasets</span>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
}

export default AdminHome;
