import React, { useState, useEffect, useRef } from "react";
import Checkbox from "../components/Checkbox";

const DataTypeFilter = ({ options, filterChangedCallBack }) => {
  const [checkAll, setCheckAll] = useState(false);
  const popinfilters = useRef(null);
  const [filtersVisible, setFiltersVisible] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popinfilters.current &&
        !popinfilters.current.contains(event.target)
      ) {
        setFiltersVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleCheckAll = () => {
    const optionsCopy = { ...options };
    setCheckAll(!checkAll);
    Object.keys(optionsCopy).forEach((key) => {
      if (
        typeof optionsCopy[key] === "boolean" &&
        key !== "mapMode" &&
        key !== "displayPropertyBoundary"
      ) {
        optionsCopy[key] = !checkAll;
      }
    });

    filterChangedCallBack(optionsCopy);
  };

  const newCheckboxChanged = (name, newValue) => {
    const optionsCopy = { ...options };
    optionsCopy[name] = newValue;
    filterChangedCallBack(optionsCopy);
  };

  const checkboxSatelliteChanged = (name, newValue) => {
    const optionsCopy = { ...options };
    optionsCopy.mapMode = newValue ? "satellite" : "roadmap";
    filterChangedCallBack(optionsCopy);
  };

  return (
    <div ref={popinfilters}>
      <a
        className="btn btn-icon btn-noborder mr-1"
        onClick={() => setFiltersVisible(!filtersVisible)}
      >
        <i className="ei ei-menu"></i>
        <span>Type</span>
      </a>
      <div
        id="popin-filters-data"
        className="card"
        style={{ display: filtersVisible ? "block" : "none" }}
      >
        <ul className="switch">
          <Checkbox
            name="displayCamera"
            label="Camera"
            checked={options.displayCamera}
            onChange={newCheckboxChanged}
          />

          <Checkbox
            name="displaySound"
            label="Sons"
            checked={options.displaySound}
            onChange={newCheckboxChanged}
          />
          <Checkbox
            name="displaySol"
            label="Analyses de sol"
            checked={options.displaySol}
            onChange={newCheckboxChanged}
          />
          <Checkbox
            name="displayUltrason"
            label="Ultrasons"
            checked={options.displayUltrason}
            onChange={newCheckboxChanged}
          />
          <Checkbox
            name="displayPratique"
            label="Pratiques"
            checked={options.displayPratique}
            onChange={newCheckboxChanged}
          />

          <Checkbox
            name="displayPropertyBoundary"
            label="Cadastre"
            checked={options.displayPropertyBoundary}
            onChange={newCheckboxChanged}
          />
          <Checkbox
            name="satellite"
            label="Satellite"
            checked={options.mapMode == "satellite"}
            onChange={checkboxSatelliteChanged}
          />
          <Checkbox
            name="displayGbif"
            label="GBIF"
            checked={options.displayGbif}
            onChange={newCheckboxChanged}
          />
          <Checkbox
            name="displayInpn"
            label="INPN"
            checked={options.displayInpn}
            onChange={newCheckboxChanged}
          />
          <Checkbox
            name="displayParcellaire"
            label="Parcellaire"
            checked={options.displayParcellaire}
            onChange={newCheckboxChanged}
          />

          <Checkbox
            name="displayGeology"
            label="Geologie"
            checked={options.displayGeology}
            onChange={newCheckboxChanged}
          />
          <Checkbox
            name="displayNatureSols"
            label="Nature des sols"
            checked={options.displayNatureSols}
            onChange={newCheckboxChanged}
          />
        </ul>
        <br />
        <a className="btn btn-primary" onClick={toggleCheckAll}>
          {checkAll ? "Tout décocher" : "Tout cocher"}
        </a>
      </div>
    </div>
  );
};

export default DataTypeFilter;
