import React, { useState } from "react";
import Popup from "../../components/Popup";
import ButtonsEdit from "../../components/ButtonsEdit";
import Editable from "../../sensorPage/Editable";
import StatusSelector from "./statusSelector";

const AddSensorPopup = ({
  sensor,
  onCancel,
  onSave,
  ddFournisseur,
  ddModele,
  ddProprietaire,
}) => {
  const [sensorToEdit, setSensorToEdit] = useState(sensor);
  const [areInputValid, setareInputValid] = useState({});

  function editableChanged(key, value, isInputValid) {
    let areInputValidNew = areInputValid;
    areInputValidNew[key] = isInputValid;
    setareInputValid(areInputValidNew);

    const updatedSensor = { ...sensorToEdit, [key]: value };
    setSensorToEdit(updatedSensor);
  }

  function allowSave() {
    if (!sensorToEdit.sensorId) {
      return false;
    }
    return areAllInputValid();
  }

  function areAllInputValid() {
    const hasFalse = Object.values(areInputValid).some((v) => v === false);
    const allAreValid = !hasFalse;
    return allAreValid;
  }

  function onSaveClick() {
    let sensorToSave = sensorToEdit;
    if (sensorToSave.status !== "installé") {
      sensorToSave.client = "";
      sensorToSave.sensorRef = "";
    }
    onSave(sensorToSave);
  }

  function getDdStatus() {
    return [
      { key: "en_stock", value: "En stock" },
      { key: "installé", value: "Installé" },
      { key: "hors_service", value: "Hors service" },
      { key: "perdu", value: "Perdu" },
      { key: "autre", value: "Autre" },
    ];
  }

  function statusDetailsChanged(value) {
    const { statusDetails, client, sensorRef } = value;
    const updatedSensor = {
      ...sensorToEdit,
      statusDetails: statusDetails,
      client: client,
      sensorRef: sensorRef,
    };
    setSensorToEdit(updatedSensor);
  }

  return (
    <Popup title={sensorToEdit.sensorId} onClose={onCancel}>
      <table className="sensorDetailTable">
        <tbody>
          <tr>
            <td style={{ fontWeight: "bold" }}>ID:</td>
            <td>
              <Editable
                value={sensorToEdit.sensorId}
                isEdit={!sensor.sensorId}
                type="text"
                keyy="sensorId"
                changedCallback={editableChanged}
                allowSpace={false}
                allowCopy={true}
                forbidEmpty={true}
              />
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td
              style={{
                border: "1px solid black",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <Editable
                value={sensorToEdit.status}
                isEdit={true}
                type="dropdown"
                keyy="status"
                changedCallback={editableChanged}
                dropdownData={getDdStatus()}
              />

              <StatusSelector
                statusKey={sensorToEdit.status}
                client={sensorToEdit.client}
                sensorRef={sensorToEdit.sensorRef}
                detailValue={sensorToEdit.statusDetails}
                changedCallback={statusDetailsChanged}
              />
            </td>
          </tr>

          <tr>
            <td>Fournisseur</td>
            <td>
              <Editable
                value={sensorToEdit.fournisseur}
                isEdit={true}
                type="dropdown"
                keyy="fournisseur"
                changedCallback={editableChanged}
                dropdownData={ddFournisseur}
              />
            </td>
          </tr>

          <tr>
            <td>Modele</td>
            <td>
              <Editable
                value={sensorToEdit.modele}
                isEdit={true}
                type="dropdown"
                keyy="modele"
                changedCallback={editableChanged}
                dropdownData={ddModele}
              />
            </td>
          </tr>

          <tr>
            <td>Date achat</td>
            <td>
              <Editable
                value={sensorToEdit.dateAchat}
                isEdit={true}
                type="date"
                keyy="dateAchat"
                changedCallback={editableChanged}
              />
            </td>
          </tr>

          <tr>
            <td>Propriétaire</td>
            <td>
              <Editable
                value={sensorToEdit.proprietaire}
                isEdit={true}
                type="dropdown"
                keyy="proprietaire"
                changedCallback={editableChanged}
                dropdownData={ddProprietaire}
              />
            </td>
          </tr>

          <tr>
            <td>Prix HT (€)</td>
            <td>
              <Editable
                value={sensorToEdit.prix}
                isEdit={true}
                type="text"
                keyy="prix"
                changedCallback={editableChanged}
                onlyNumbers={true}
              />
            </td>
          </tr>

          <tr>
            <td>TVA (€)</td>
            <td>
              <Editable
                value={sensorToEdit.tva}
                isEdit={true}
                type="text"
                keyy="tva"
                changedCallback={editableChanged}
                onlyNumbers={true}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <br />

      <ButtonsEdit
        isEdit={true}
        saveCallback={onSaveClick}
        editCallback={onCancel}
        allowSave={allowSave()}
        allowDelete={false}
        deleteCallback={() => {}}
      />
    </Popup>
  );
};

export default AddSensorPopup;
